import {
	AppBar,
	Button,
	Dialog,
	IconButton,
	Paper,
	Toolbar,
	Typography,
	Box,
	TextField,
} from "@material-ui/core";
import React from "react";
import PageHeader from "../common/PageHeader";
import { apiBatchRequest, apiPost, apiPut, apiRequest, getSpsAPiRequest, clearCacheData, apiRequestNoLoadder, apiDelete } from "../../utils/api_service";
import BackspaceIcon from "@material-ui/icons/BackspaceRounded";
import { UpdateFieldsWithConfiguration, isInputValid, IsSectionVisible } from "../../utils/helper";
import FormGenerator from "../common/FormGenerator";
import { API_URL, getCurrentEnvironment } from "../../constants/api";
import { controlType } from "../../constants/common";
import camelcaseKeys from "camelcase-keys";
import AutoSearch from "../common/AutoSearch/AutoSearch";
import SimpleSelect from "../common/AutoSearch/SimpleSelect";
import { ShowToast, toastType } from "../../utils/toast-service";
import commonLabels from "../../constants/messageConstants";
import ConfirmDialog from "../common/Dailogbox/confirmDailog";
import { IoMdCloseCircleOutline } from "react-icons/io";
import moment from "moment";
import ViewHistoryData from "../common/ViewHistoryData/ViewHistoryData";
import Autocomplete from "@material-ui/lab/Autocomplete";
import EditBuildings from "../Buildings/EditBuildings";
import EditBuildingOwners from "../BuildingOwners/EditBuildingOwners";
import { SECTIONS } from "../../constants/appConstants";
import { getSharePointUrl } from "../../constants/sharepointApi";
import { getSPSTokenData, setSPSToken } from "../../constants/constants";

export default class EditSalesComp extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isIconDisabled: false,
			selectedMarketId: this.props.globalMarketId,
			getSalesCompDetails: [],
			getSalesCompDetailsResponse: [],
			buildingMasterDetails: props.buildingMasterDetails || [],
			salesCompFormData: [],
			lastModifiedValue: "",
			isEditEnabled: this.props.isInAddMode,
			openDialog: true,
			getAllMSalesStatus: props.getAllMSalesStatus || [],
			getAllBuyerDetails: props.getAllBuyerDetails || [],
			getAllSellerDetails: [],//props.getAllSellerDetails || [],
			brokerMasterDetails: [],//props.brokerMasterDetails || [],
			getBrokerMaster: [],
			getAllCompType: [],
			salesCompsSummaryData: props.salesCompsSummaryData || [],
			isDataSaved: false,
			isSearchedClicked: false,
			showConfirmDialog: false,
			compId: this.props.selectedCompanyId,
			getHistoryDetailByField: [],
			openHistoryform: false,
			isHistoryByField: false,
			getHistory: [],
			detailView: false,
			selectedEditRowId: -1,
			ViewTitle: "Add",
			actionType: "Add",
			isRedirectedFromTenant: false,
			selectedBuildingOwner: undefined,
			reloadCompanyPopup: false,
			selectedBuilding: undefined,
			reloadCompData: false,
			downloadURL: null,
			spListkey: props.spListkey || null,
			getAllRepresentationType: [],
			//getAllTenancy:[],	
			getAllNegotationType: [],
			getAllInvestmentType: [],
			getAllCompSource: [],
			clearText: 'Clear All',
			undoText: 'Undo',
			buyerSelected: null,
			sellerSelected: null,
			isAddedFromPopup: false,
			isDeleteClicked: false,
			buildingNameSelected: null,
			selectedBuildingDetails: props.getBuildingDetails,
		};
	}
	onSearch = (event, value, e) => {
		const { isDataSaved } = this.state;
		let compId = this.state.salesCompsSummaryData.find(
			(m) => m.buildingName == value
		).compId;
		this.setState({
			compId: compId,
			isSearchedClicked: true,
		});

		if (isDataSaved == false) {
			this.setState({ getHistory: [] });
			this.getData(compId);
		}
		//console.log(event);
	};
	onSearchLeaseComp = () => {
		this.setState({
			isSearchedClicked: false,
			isDataSaved: false,
		});
		this.setState({ getHistory: [] });
		this.getData(this.state.compId);
	};
	onClickHandel(e) {
		if (e == true) {
			this.onSearchLeaseComp();
			this.setState({
				isSearchedClicked: false,
			});
		} else {
			this.setState({
				isSearchedClicked: false,
			});
		}
	}

	onDeleteClickHandel(e) {
		if (e == true) {
			apiDelete({
				url: `${API_URL.Master.softDeleteComps}`,

				params: { compId: this.state.compId, businessLineId: 1 },
			})
				.then(({ data: response }) => {
					if (response.success === true) {
						ShowToast(
							"Sales Comp deleted successfully",
							toastType.SUCCESS,
							5000,
							500
						);
						this.setState({
							isDataSaved: false,
						});
						clearCacheData(API_URL.SalesComps.getSalesCompDetails + '{\"compId\":' + this.state.compId + '}');
						//clearCacheData(API_URL.LeaseComps.leaseCompsSummaryData+'{"marketId":"'+this.state.selectedMarketId+'"}');
						this.props.reloadData();
						//this.getData();
					} else {
						ShowToast(
							"Server Error! Sales Comp not deleted",
							toastType.ERROR,
							5000,
							500
						);
					}
				})
				.catch(() => {
					ShowToast(commonLabels.somethingWrong, toastType.error, 5000, 500);
				});
			this.setState({
				isDeleteClicked: false,
			});
		} else {
			this.setState({
				isDeleteClicked: false,
			});
		}
	}

	onDeleteClick = () => {
		this.setState({ isDeleteClicked: true });
	}

	handleChange = (event) => {
		setState({ lastModifiedValue: event.target.value });
	};
	getData = (compId) => {
		this._asyncRequest = apiBatchRequest([
			{
				url: API_URL.SalesComps.getSalesCompDetails,
				key: "getSalesCompDetails",
				params: { compId: compId },
			},
		])
			.then((getSalesCompDetails) => {
				this._asyncRequest = null;
				if (getSalesCompDetails.getSalesCompDetails.success == true) {
					let companyName = [];

					companyName = getSalesCompDetails.getSalesCompDetails.data.find(
						(x) => x.dbColumn == "Buyer"
					).attributeValue;
					const params = {
						businessLineId: 1,
						searchText: companyName
					};
					apiRequest(
						{
							url: API_URL.Master.getALLBrokerFirm,
							params
						}
					).then(({ data: brokerMasterDetails }) => {
						this.setState({
							brokerMasterDetails:
								camelcaseKeys(brokerMasterDetails) || [],
							reloadCompData: !this.state.reloadCompData,
						}, () => { this.forceUpdate() });
					})
						.catch((reason) => {
							this._asyncRequest = null;
							ShowToast(commonLabels.somethingWrong, toastType.ERROR, 5000, 500, 'Server error');
						});

					let companySellerName = [];

					companySellerName = getSalesCompDetails.getSalesCompDetails.data.find(
						(x) => x.dbColumn == "Seller"
					).attributeValue;
					const params1 = {
						businessLineId: 1,
						searchText: companySellerName,
						compId: this.state.compId
					};
					apiRequest(
						{
							url: API_URL.Master.getALLSellerFirm,
							params: params1
						}
					).then(({ data: getAllSellerDetails }) => {
						this.setState({
							getAllSellerDetails:
								camelcaseKeys(getAllSellerDetails) || [],
						}, () => { this.forceUpdate() });
					})
						.catch((reason) => {
							this._asyncRequest = null;
							ShowToast(commonLabels.somethingWrong, toastType.ERROR, 5000, 500, 'Server error');
						});

					let buildingName = [];

					buildingName = getSalesCompDetails.getSalesCompDetails.data.find(
						(x) => x.dbColumn == "BuildingName"
					).attributeValue;
					const buildingParams = {
						businessLineId: 1,
						searchText: buildingName,
						marketId: this.state.selectedMarketId
					};
					apiRequest(
						{
							url: API_URL.Master.getBuildingNameAddressCostarMaster,
							params: buildingParams
						}
					).then(({ data: getBuildingMaster }) => {
						this.setState({
							buildingMasterDetails:
								camelcaseKeys(getBuildingMaster) || [],
							reloadCompData: !this.state.reloadCompData,
						}, () => { this.forceUpdate() });
					})
						.catch((reason) => {
							this._asyncRequest = null;
							ShowToast(commonLabels.somethingWrong, toastType.ERROR, 5000, 500, 'Server error');
						});
					this.setState({
						getSalesCompDetails:
							camelcaseKeys(getSalesCompDetails.getSalesCompDetails.data) || [],
						getHistory:
							camelcaseKeys(
								getSalesCompDetails.getSalesCompDetails.configuration
							) || [],

					});
					this.forceUpdate();
				} else {
					ShowToast(
						"Something went wrong",
						toastType.ERROR,
						5000,
						500,
						"Server error"
					);
				}
			})
			.catch(() => {
				this._asyncRequest = null;
			});
	};

	getRequiredData() {
		this._asyncRequest = apiBatchRequest([
			{
				url: API_URL.Master.getAllCompType,
				key: "getAllCompType",
				fromCache: true,
				duration: 60,
			},
		])
			.then((getAllCompType) => {
				this._asyncRequest = null;
				if (getAllCompType.getAllCompType.success == true) {
					this.setState({
						getAllCompType:
							camelcaseKeys(getAllCompType.getAllCompType.data).filter((m) => {
								if (m.compType === 'Sales') return m;
							}) || [],
						reloadCompData: !this.state.reloadCompData,
					});
					this.forceUpdate();
				} else {
					ShowToast(
						"Something went wrong",
						toastType.ERROR,
						5000,
						500,
						"Server error"
					);
				}
			})
			.catch(() => {
				this._asyncRequest = null;
			});
	}
	getSalesCompData() {
		this._asyncRequest = apiBatchRequest([
			// {
			// 	url: API_URL.SalesComps.getSalesCompDetails,
			// 	key: "getSalesCompDetails",
			// 	params: { compId: this.props.selectedCompanyId },
			// 	fromCache: true,
			// 	duration: 60,
			// },
			{
				url: API_URL.SalesComps.salesCompsSummaryData,
				key: "salesCompsSummaryData",
				params: { marketId: this.state.selectedMarketId },
				//fromCache: true,
				//duration: 60,
			},
			// {
			// 	url: API_URL.Master.getBuildingMaster,
			// 	key: "buildingMasterDetails",
			// 	params: { businessLineId: 1, marketId: this.state.selectedMarketId },
			// 	fromCache: true,
			// 	duration: 60,
			// },
			{
				url: API_URL.Master.getAllMSalesStatus,
				key: "getAllMSalesStatus",
				fromCache: true,
				duration: 60,
			},
			{
				url: API_URL.Master.getAllCompType,
				key: "getAllCompType",
				fromCache: true,
				duration: 60,
			},
		])
			.then((getSalesCompDetails) => {
				this._asyncRequest = null;
				if (getSalesCompDetails.getAllCompType.success == true) {


					this.setState({
						salesCompsSummaryData:
							camelcaseKeys(getSalesCompDetails.salesCompsSummaryData.data) ||
							[],
						// buildingMasterDetails:
						// 	camelcaseKeys(getSalesCompDetails.buildingMasterDetails.data) ||
						// 	[],
						getAllMSalesStatus:
							camelcaseKeys(getSalesCompDetails.getAllMSalesStatus.data) || [],
						getAllCompType:
							camelcaseKeys(getSalesCompDetails.getAllCompType.data).filter((m) => {
								if (m.compType === 'Sales') return m;
							}) || [],
					});
					this.forceUpdate();
				} else {
					ShowToast(
						"Something went wrong",
						toastType.ERROR,
						5000,
						500,
						"Server error"
					);
				}
			})
			.catch(() => {
				this._asyncRequest = null;
			});
	}
	componentWillReceiveProps(nextProps) {
		if (nextProps.globalMarketId !== this.state.selectedMarketId) {
			this.setState({ selectedMarketId: nextProps.globalMarketId });
		}
	}
	componentDidMount() {
		//console.log("this.props.isInAddMode ", this.props.isInAddMode)
		this._asyncRequest = apiBatchRequest([
			{
				url: API_URL.SalesComps.getSalesCompDetails,
				key: "getSalesCompDetails",
				params: { compId: this.props.selectedCompanyId },
				// fromCache: true,
				// duration: 60,
			},

			{
				url: API_URL.Master.getAllNegotation,
				key: "getAllNegotationType",
				params: { businessLineId: 1 },
				fromCache: true,
				duration: 60,
			},
			{
				url: API_URL.Master.GetAllInvestmentType,
				key: "getAllInvestmentType",
				params: { businessLineId: 1 },
				fromCache: true,
				duration: 60,
			},


			{
				url: API_URL.Master.getBuildingNameAddressCostarMaster,
				key: "buildingMasterDetails",
				params: { businessLineId: 1, marketId: this.state.selectedMarketId },
			},

			{
				url: API_URL.Master.getAllCompType,
				key: "getAllCompType",
				fromCache: true,
				duration: 60,
			},

			{
				url: API_URL.Master.getCompSourceAndRepresentationTypeMasters,
				key: "getCompSourceAndRepresentationTypeMasters",
				params: { businessLineId: 1 },
				//fromCache: true,
				//duration: 60,
			},
		])
			.then((getSalesCompDetails) => {
				this._asyncRequest = null;
				if (getSalesCompDetails.getSalesCompDetails.success == true) {
					let companyName = [];

					let getCompType = getSalesCompDetails.getAllCompType.data.filter((m) => {
						if (m.compType === "Sales") return m;
					});


					getSalesCompDetails.getSalesCompDetails.data.find(
						(x) => x.dbColumn == "CompTypeId"
					).attributeValue = getCompType[0].compTypeID
					getSalesCompDetails.getSalesCompDetails.data.find(
						(x) => x.dbColumn == "CompType"
					).attributeValue = getCompType[0].compType


					companyName = getSalesCompDetails.getSalesCompDetails.data.find(
						(x) => x.dbColumn == "Buyer"
					).attributeValue;
					const params = {
						businessLineId: 1,
						searchText: companyName,
						compId: this.state.compId
					};
					apiRequest(
						{
							url: API_URL.Master.getALLBrokerFirm,
							params
						}
					).then(({ data: brokerMasterDetails }) => {
						this.setState({
							brokerMasterDetails:
								camelcaseKeys(brokerMasterDetails) || [],
							reloadCompData: !this.state.reloadCompData,
						}, () => { this.forceUpdate() });
					})
						.catch((reason) => {
							this._asyncRequest = null;
							ShowToast(commonLabels.somethingWrong, toastType.ERROR, 5000, 500, 'Server error');
						});

					let companySellerName = [];

					companySellerName = getSalesCompDetails.getSalesCompDetails.data.find(
						(x) => x.dbColumn == "Seller"
					).attributeValue;
					const params1 = {
						businessLineId: 1,
						searchText: companySellerName,
						compId: this.state.compId
					};
					apiRequest(
						{
							url: API_URL.Master.getALLSellerFirm,
							params: params1
						}
					).then(({ data: getAllSellerDetails }) => {
						this.setState({
							getAllSellerDetails:
								camelcaseKeys(getAllSellerDetails) || [],
							//reloadCompData:!this.state.reloadCompData,
						}, () => { this.forceUpdate() });
					})
						.catch((reason) => {
							this._asyncRequest = null;
							ShowToast(commonLabels.somethingWrong, toastType.ERROR, 5000, 500, 'Server error');
						});

					if (this.state.selectedBuildingDetails
						&& this.state.selectedBuildingDetails.length > 0
						&& this.props.isInAddMode === true) {

						var tempXid = this.state.selectedBuildingDetails.find(
							(x) => x.dbColumn == "BuildingId"
						).attributeValue;

						// var tempXname = this.state.selectedBuildingDetails.find(
						// 	(x) => x.dbColumn == "BuildingName"
						// ).attributeValue;

						var tempXname = getSalesCompDetails.buildingMasterDetails.data.find(
							(x) => x.buildingId == tempXid
						).buildingName;

						getSalesCompDetails.getSalesCompDetails.data.find(
							(x) => x.dbColumn == "BuildingId"
						).attributeValue = tempXid;

						getSalesCompDetails.getSalesCompDetails.data.find(
							(x) => x.dbColumn == "BuildingName"
						).attributeValue = tempXname;
					}


					this.setState({
						getSalesCompDetails:
							camelcaseKeys(getSalesCompDetails.getSalesCompDetails.data) || [],
						getHistory:
							camelcaseKeys(
								getSalesCompDetails.getSalesCompDetails.configuration
							) || [],
						//getAllTenancy:
						//camelcaseKeys(getSalesCompDetails.getAllTenancy.data) || [],
						getAllNegotationType: camelcaseKeys(getSalesCompDetails.getAllNegotationType.data) || [],
						getAllInvestmentType: camelcaseKeys(getSalesCompDetails.getAllInvestmentType.data) || [],

						getAllCompSource:
							camelcaseKeys(getSalesCompDetails.getCompSourceAndRepresentationTypeMasters.compSource.data) || [],
						// salesCompsSummaryData:
						// 	camelcaseKeys(getSalesCompDetails.salesCompsSummaryData.data) ||
						// 	[],
						getSalesCompDetailsResponse:
							camelcaseKeys(getSalesCompDetails.getSalesCompDetails.data) || [],
						getAllRepresentationType:
							camelcaseKeys(getSalesCompDetails.getCompSourceAndRepresentationTypeMasters.representationType.data) ||
							[],
						buildingMasterDetails:
							camelcaseKeys(getSalesCompDetails.buildingMasterDetails.data) ||
							[],

						getAllCompType:
							camelcaseKeys(getSalesCompDetails.getAllCompType.data) || [],
						reloadCompData: !this.state.reloadCompData,
					});
					this.getSpsData();
					this.forceUpdate();
				} else {
					ShowToast(
						"Something went wrong",
						toastType.ERROR,
						5000,
						500,
						"Server error"
					);
				}
			})
			.catch(() => {
				this._asyncRequest = null;
			});
		if (this.state.salesCompsSummaryData.length == 0 || this.state.getAllSellerDetails.length == 0 || this.state.getAllCompType.length == 0) {
			this.getSalesCompData();
		}
		else if (this.props.isInAddMode == true) {
			this.getRequiredData();
		}
	}

	getSpsData = () => {
		let env = getCurrentEnvironment().sharePointLibrary;
		if (getSPSTokenData()) {
			this.setState({ spsData: {} });
			getSpsAPiRequest(getSharePointUrl('streamrealty', 'getFolder')).then((res) => {
				const folderName = env;
				if (res.value !== null && res.value.length > 0 &&
					(res.value.find(x => x.displayName === folderName || x.name === folderName) !== undefined)) {
					const parentFolder = res.value.find(x => x.displayName === folderName || x.name === folderName);
					const driveId = parentFolder.parentReference.driveId;
					const folderKey = parentFolder.id;
					this.setState({ spListkey: folderKey });
					getSpsAPiRequest(
						getSharePointUrl(
							'streamrealty',
							'getSps',
							folderKey
						)
					).then((spsDataRes) => {
						const subFolder = "SalesComps"
						if (spsDataRes.value !== null && spsDataRes.value.length > 0 &&
							(spsDataRes.value.find(x => x.name === subFolder || x.displayName === subFolder) !== undefined)) {
							const folder = spsDataRes.value.find(x => x.name === subFolder || x.displayName === subFolder);
							const driveId = folder.parentReference.driveId;
							const key = folder.id;
							let fileName = "";
							if (this.state.getSalesCompDetails.length > 0) {
								fileName = this.state.getSalesCompDetails.find((x) => x.dbColumn == "ImportFileName"
								).attributeValue;
							}
							if (fileName !== "")
								this.getList(fileName);
						}
					});
				}
			});
		}
		else {
			apiRequest({ url: API_URL.powerBi.AcquireSPToken }).then(({ data }) => {
				if (data) {
					setSPSToken(data);
					this.getSpsData();
				}
			});
		}
	}

	enableEdit = () => {
		this.setState({
			isEditEnabled: true,
		});
		this.forceUpdate();
		this.getRequiredData();
	};

	getCompChanges = (formFields) => {
		let buyerValue = formFields.find(
			(x) => x.dbColumn == "Buyer"
		).attributeValue;
		let buildingNameValue = formFields.find(
			(x) => x.dbColumn == "BuildingName"
		).attributeValue;
		let buyerData = buyerValue;
		if (buyerValue == '' || buyerValue == "" || buyerValue == undefined) {
			buyerData = undefined;
		}
		let buildingData = buildingNameValue;
		if (buildingNameValue == '' || buildingNameValue == "" || buildingNameValue == undefined) {
			buildingData = undefined;
		}
		this.setState(
			{
				getSalesCompDetails: formFields,
				isDataSaved: true,
				isSearchedClicked: false,
				selectedBuildingOwner: buyerData,
				selectedBuilding: buildingData,
				reloadCompData: !this.state.reloadCompData,
				buyerSelected: null,
				sellerSelected: null,
				buildingNameSelected: null,
			},
			() => {
				this.forceUpdate();
			}
		);
	};

	validateForm = () => {
		const formData = this.state.getSalesCompDetails;
		let isValid = true;
		let breakCondition = false;
		if (formData.length == 0) {
			const msg = "No change detected. Please make necessary changes and try again.";
			isValid = false;
			breakCondition = true;
			ShowToast(msg, toastType.ERROR, 5000, 500);
		}
		formData.forEach((x) => {
			if (
				x.isRequired == true &&
				(x.attributeValue == undefined || x.attributeValue == null || x.attributeValue.trim() == "") &&
				breakCondition != true
			) {
				const msg = "Please fill all the mandatory fields";
				isValid = false;
				breakCondition = true;
				ShowToast(msg, toastType.ERROR, 5000, 500);
			}
		});
		return isValid;
	};
	validateRequiredFields = (formElementFields, stateToBeUpdate = {}) => {
		//console.log(formElementFields[0].attributeValue);
		let IsValid = true;
		formElementFields.forEach((ele) => {
			isInputValid(ele.attributeValue, ele, this.state.getSalesCompDetails);
			if (ele.isInErrorState === true) {
				IsValid = false;
			}
		});
		//console.log(formElementFields);
		stateToBeUpdate = {
			...stateToBeUpdate,
			getSalesCompDetails: formElementFields,
			reloadCompData: !this.state.reloadCompData,
		};
		this.setState(stateToBeUpdate);

		return IsValid;
	};
	saveCompDetails = () => {
		//let companyId = undefined;
		//let company = [];
		let buildingId = undefined;
		let building = [];
		const BuyerId = this.state.getSalesCompDetails.length > 0 ? this.state.getSalesCompDetails.find(
			(x) => _.camelCase(x.dbColumn) === _.camelCase("BuyerId")
		).attributeValue : null;
		const SellerId = this.state.getSalesCompDetails.length > 0 ? this.state.getSalesCompDetails.find(
			(x) => _.camelCase(x.dbColumn) === _.camelCase("SellerId")
		).attributeValue : null;

		if (this.state.buildingMasterDetails.length > 0) {
			building = this.state.buildingMasterDetails.filter(
				(x) => x.buildingName == this.state.selectedBuilding);
		}
		if (building.length > 0 && this.state.selectedBuilding !== undefined) {
			buildingId = building[0].buildingId
		}
		this.state.getSalesCompDetails.filter((x) => {

			if (x.dbColumn == "BuildingId" && buildingId != undefined)
				x.attributeValue = buildingId;
			if (x.dbColumn == "BuildingName" && building.length > 0)

				x.attributeValue = building[0].buildingName;
		})
		const salesCompData = this.state.getSalesCompDetails;
		let isValid = this.validateForm();
		let isInputValid = this.validateRequiredFields(salesCompData);
		if (isValid == false && isInputValid == false
			|| isValid == true && isInputValid == false
			|| isValid == false && isInputValid == true
			|| (this.state.buyerSelected != undefined || this.state.buyerSelected != null)
			|| (this.state.sellerSelected != undefined || this.state.sellerSelected != null)
			|| (this.state.buildingNameSelected != undefined || this.state.buildingNameSelected != null)
		) {
			isInputValid == false ? ShowToast('There is validation error on page.', toastType.ERROR, 5000, 500) :
				ShowToast('Please select Buyer/Seller/Property from the list given.', toastType.ERROR, 5000, 500);
			;
			return false;
		} else {
			salesCompData.forEach((x) => {
				x.attributeValue = x.attributeValue != null ? x.attributeValue.toString().trim() : x.attributeValue;

				if (
					_.camelCase(x.dbColumn) === _.camelCase("BuildingId") ||
					_.camelCase(x.dbColumn) === _.camelCase("BuyerId") ||
					_.camelCase(x.dbColumn) === _.camelCase("SellerId") ||
					_.camelCase(x.dbColumn) === _.camelCase("SalesStatusId") ||
					_.camelCase(x.dbColumn) === _.camelCase("TenantRepBrokerId") ||
					_.camelCase(x.dbColumn) === _.camelCase("RepresentationTypeId") ||
					_.camelCase(x.dbColumn) === _.camelCase("TenancyTypeId") ||
					_.camelCase(x.dbColumn) === _.camelCase("CompTypeId") ||
					_.camelCase(x.dbColumn) === _.camelCase("CompSourceId") ||
					_.camelCase(x.dbColumn) === _.camelCase("NegotiationTypeId")

				) {
					if (x.attributeValue != null)
						x.attributeValue = x.attributeValue.toString();
				}
			});

			if (this.props.actionType === "Edit") {
				const compId = salesCompData.filter(
					(x) => _.camelCase(x.dbColumn) === _.camelCase("buildingName")
				);

				const compIdForClearCache = salesCompData.filter(
					(x) => _.camelCase(x.dbColumn) === _.camelCase("CompId")
				);

				apiPut(API_URL.SalesComps.updateSalesCompDetail, salesCompData, {
					CompanyId: compId[0].attributeValue,
				})
					.then(({ data: response }) => {
						if (response.success === true) {
							ShowToast(
								"Sales Comp updated successfully",
								toastType.SUCCESS,
								5000,
								500
							);
							this.setState({
								isDataSaved: false,
							});
							clearCacheData(API_URL.SalesComps.getSalesCompDetails + '{\"compId\":' + compIdForClearCache[0].attributeValue + '}');
							//clearCacheData(API_URL.SalesComps.salesCompsSummaryData+'{"marketId":"'+this.state.selectedMarketId+'"}');
							if (this.props.isRedirectedFromProperty == true)
								this.props.onBackClick();
							else
								this.props.reloadData();
							//this.getData();
						} else {
							ShowToast("Server Error! Sales Comp not updated successfully", toastType.ERROR, 5000, 500);
						}
					})
					.catch(() => {
						ShowToast(commonLabels.somethingWrong, toastType.error, 5000, 500);
					});
			} else {
				//salesCompData[0].attributeValue=-1;

				apiPost({
					url: API_URL.SalesComps.addSalesCompDetail,
					params: {},
					postBody: salesCompData,
				})
					.then(({ data: response }) => {
						if (response.success === true) {
							ShowToast(
								"Sales Comp added successfully",
								toastType.SUCCESS,
								5000,
								500
							);
							//clearCacheData(API_URL.SalesComps.salesCompsSummaryData+'{"marketId":"'+this.state.selectedMarketId+'"}');
							if (this.props.isRedirectedFromProperty == true)
								this.props.onBackClick();
							else
								this.props.reloadData();
						} else {
							ShowToast('Server Error! Sales Comp not added successfully', toastType.error, 5000, 500);
						}
					})
					.catch(() => {
						ShowToast(commonLabels.somethingWrong, toastType.error, 5000, 500);
					});
			}
		}
	};

	onBackClick = () => {
		this.props.onBackClick();
		if (this.state.isAddedFromPopup)
			this.props.onCancelClickAfterPopupAdd();
	};

	getHistoryDataByField = (val) => {
		const params = {
			entityPrimaryKey: this.state.getHistory[0].entityPrimaryKey,
			sectionDetailId: val.sectionDetailId,
			sectionId: val.sectionId,
			dbTable: val.dbTable,
			dbColumn: val.dbColumn,
			businessLineId: 1,
		};

		this._asyncRequest = apiBatchRequest([
			{
				url: API_URL.Building.getHistoryDetailByField,
				key: "getHistoryDetailByField",
				params: params,
				//{ entityPrimarykey :val.entityPrimarykey,ModifiedDate :val.modifiedDate, },
			},
		])
			.then((getHistoryDetailByField) => {
				this._asyncRequest = null;
				if (getHistoryDetailByField.getHistoryDetailByField.success == true) {
					this.setState({
						getHistoryDetailByField:
							camelcaseKeys(
								getHistoryDetailByField.getHistoryDetailByField.data
							) || [],
						openHistoryform: true,
						isHistoryByField: true,
					});
					this.forceUpdate();
				} else {
					ShowToast(
						"Something went wrong",
						toastType.ERROR,
						5000,
						500,
						"Server error"
					);
				}
			})
			.catch(() => {
				this._asyncRequest = null;
			});
	};

	handleClose = (e, val) => {
		if (val == "blur") return;
		this.setState({ openHistoryform: true, });
	}

	handleChange = (e, val) => {
		const params = {
			entityPrimaryKey: val.entityPrimaryKey,
			modifiedDate: moment(moment(val.createdDate).toDate())
				.format("MM-DD-YYYY HH:mm:ss")
				.toString(),
			sectionId: val.sectionId,
			dbTable: val.dbTable,
			businessLineId: 1,
		};
		//console.log("e", e, "val", val);
		this._asyncRequest = apiBatchRequest([
			{
				url: API_URL.Building.getHistoryDataByUser,
				key: "getHistoryDataByUser",
				params: params,
				//{ entityPrimarykey :val.entityPrimarykey,ModifiedDate :val.modifiedDate, },
			},
		])
			.then((getHistoryDataByUser) => {
				this._asyncRequest = null;
				if (getHistoryDataByUser.getHistoryDataByUser.success == true) {
					this.setState({
						getHistoryDataByUser:
							camelcaseKeys(getHistoryDataByUser.getHistoryDataByUser.data) ||
							[],
						openHistoryform: true,
					});
					this.forceUpdate();
				} else {
					ShowToast(
						"Something went wrong",
						toastType.ERROR,
						5000,
						500,
						"Server error"
					);
				}
			})
			.catch(() => {
				this._asyncRequest = null;
			});
	};

	goToAddBuilding = (source, option, updateoption = true) => {
		this.setState({
			openaddform: true,
			addformtitle: source.AddNewOptiontitle,
			addNewformComponent: EditBuildings,
			addnewClientSource: source,
		});
	};

	onPopupBackClick = () => {
		this.setState({ detailView: false, selectedEditRowId: -1 });
		if (this.state.openaddform == true) {
			this.setState({ openaddform: false }, () => {
				this.forceUpdate();
			});
		}
	};

	reloadPropertyData = (buildingData) => {
		this._asyncRequest = apiBatchRequest([
			{
				url: API_URL.Master.getBuildingNameAddressCostarMaster,
				key: "buildingMasterDetails",
				params: { businessLineId: 1, marketId: this.state.selectedMarketId },
				fromCache: true,
				duration: 60,
				clearCache: true
			},
		])
			.then((buildingMasterDetails) => {
				this._asyncRequest = null;
				if (buildingMasterDetails.buildingMasterDetails.success == true) {
					this.setState(
						{
							buildingMasterDetails:
								camelcaseKeys(buildingMasterDetails.buildingMasterDetails.data) || [],
							openaddform: false,
							addNewformComponent: undefined,
							reloadCompData: !this.state.reloadCompData,
							isAddedFromPopup: true,
						},
						() => {
							this.forceUpdate();
						}
					);
					this.setBuilding(buildingData);
				} else {
					ShowToast(
						"Something went wrong",
						toastType.ERROR,
						5000,
						500,
						"Server error"
					);
				}
			})
			.catch(() => {
				this._asyncRequest = null;
			});
	};

	buildingChanges = () => { };

	goToAddBuildingOwner = (source, option, updateoption = true) => {
		this.setState({
			openPropertyOwnerAddForm: true,
			addformtitle: source.AddNewOptiontitle,
			addNewformComponent: EditBuildingOwners,
			addnewClientSource: source,
		});
	};

	onPropertyOwnerPopupBackClick = () => {
		this.setState({ detailView: false, selectedEditRowId: -1 });
		if (this.state.openPropertyOwnerAddForm == true) {
			this.setState({ openPropertyOwnerAddForm: false }, () => {
				this.forceUpdate();
			});
		} else {
			this.props.onBackClick();
		}
	};

	reloadPropertyOwnerData = (companyData, bit = 0) => {
		let companyName = null;
		let cId = [];
		if (companyData != undefined && companyData.length > 0) {
			companyName = companyData.find(
				(x) => x.dbColumn == "CompanyName"
			).attributeValue;

		}

		let bData = this.state.getSalesCompDetails;
		const params = {
			businessLineId: 1,
			searchText: companyName != undefined ? companyName : null,
			compId: this.state.compId
		};

		apiRequest(
			{
				url: API_URL.Master.getALLBrokerFirm,
				params
			}
		).then(({ data: brokerMasterDetails }) => {
			let breakCondition = false;
			cId = brokerMasterDetails.find(
				(x) => {
					if (x.buyer === companyName
						&& breakCondition == false) {
						breakCondition = true;
						return x;
					}

				});
			bData.find(
				(x) => x.dbColumn == "Buyer"
			).attributeValue = companyName;
			bData.find(
				(x) => x.dbColumn == "BuyerId"
			).attributeValue = cId.buyerId;
			this.setState({
				brokerMasterDetails:
					camelcaseKeys(brokerMasterDetails) || [],
				getSalesCompDetails: bit == 1 ? this.state.getSalesCompDetails : bData,
				reloadCompData: !this.state.reloadCompData,
			}, () => { this.forceUpdate() });
			this.setBuildingOwner(companyData);
		})
			.catch((reason) => {
				this._asyncRequest = null;
				ShowToast(commonLabels.somethingWrong, toastType.ERROR, 5000, 500, 'Server error');
			});

	};

	buildingOwnerChanges = () => { };
	onInputChange = (value) => {
		//	let isExecuted=false;
		this.setState({ buyerSelected: value });
		if (value.length > 2) {
			const params = {
				businessLineId: 1,
				searchText: value,
				compId: this.state.compId
			};
			apiRequestNoLoadder(
				{
					url: API_URL.Master.getALLBrokerFirm,
					params
				}
			).then(({ data: brokerMasterDetails }) => {
				this.setState({
					brokerMasterDetails:
						camelcaseKeys(brokerMasterDetails) || [],
					reloadCompData: !this.state.reloadCompData,
				}, () => { this.forceUpdate() });
				//isExecuted=true;		
			})
				.catch((reason) => {
					this._asyncRequest = null;
					ShowToast(commonLabels.somethingWrong, toastType.ERROR, 5000, 500, 'Server error');
				});
			//return isExecuted;
		}

	}

	onSellerInputChange = (value) => {
		this.setState({ sellerSelected: value });
		if (value.length > 2) {
			const params = {
				businessLineId: 1,
				searchText: value,
				compId: this.state.compId
			};
			apiRequestNoLoadder(
				{
					url: API_URL.Master.getALLSellerFirm,
					params
				}
			).then(({ data: getAllSellerDetails }) => {
				this.setState({
					getAllSellerDetails:
						camelcaseKeys(getAllSellerDetails) || [],
					reloadCompData: !this.state.reloadCompData,
				}, () => { this.forceUpdate() });
			})
				.catch((reason) => {
					this._asyncRequest = null;
					ShowToast(commonLabels.somethingWrong, toastType.ERROR, 5000, 500, 'Server error');
				});
		}

	}

	onBuildingInputChange = (value) => {
		this.setState({ buildingNameSelected: value });
		if (value.length > 2) {
			const params = {
				businessLineId: 1,
				searchText: value,
				marketId: this.state.selectedMarketId
				//compId:this.state.compId
			};
			apiRequestNoLoadder(
				{
					url: API_URL.Master.getBuildingNameAddressCostarMaster,
					params
				}
			).then(({ data: getBuildingMaster }) => {
				this.setState({
					buildingMasterDetails:
						camelcaseKeys(getBuildingMaster) || [],
					reloadCompData: !this.state.reloadCompData,
				}, () => { this.forceUpdate() });
			})
				.catch((reason) => {
					this._asyncRequest = null;
					ShowToast(commonLabels.somethingWrong, toastType.ERROR, 5000, 500, 'Server error');
				});
		}

	}

	setBuildingOwner = (value) => {
		let companyName = "";
		if (value.length > 0) {
			companyName = value.find(
				(x) => x.dbColumn == "CompanyName"
			).attributeValue;
		}
		this.state.getSalesCompDetails.filter((x) => {
			if (x.dbColumn == "Buyer")
				x.attributeValue = companyName;
		});
		this.setState({ selectedBuildingOwner: companyName, reloadCompData: !this.state.reloadCompData, });
		this.forceUpdate();
	};

	setBuilding = (value) => {
		let buildingName = "";
		if (value.length > 0) {
			buildingName = value.find(
				(x) => x.dbColumn == "BuildingName"
			).attributeValue;
		}
		this.setState({ selectedBuilding: buildingName, reloadCompData: !this.state.reloadCompData, });
		this.state.getSalesCompDetails.filter((x) => {
			if (x.dbColumn == "BuildingName")
				x.attributeValue = buildingName;
		})
		this.forceUpdate();
	};

	getList = (fileName) => {
		getSpsAPiRequest(
			getSharePointUrl("streamrealty", "getInnerFolder", this.state.spListkey, 'Sales')
		).then((spsDataRes) => {
			if (spsDataRes) {
				const { value } = spsDataRes;
				for (let i = 0; i < value.length; i++) {
					if (fileName === value[i].name) {
						this.setState({ downloadURL: value[i]["@microsoft.graph.downloadUrl"] })
						break;
					}
				}
			}
		});
	};

	clearDates = () => {
		let soldDate = undefined;
		let compCreatedDate = undefined;
		let closeDate = undefined;

		if (this.state.getSalesCompDetailsResponse.length > 0) {
			soldDate = this.state.getSalesCompDetailsResponse.find(
				(x) => x.dbColumn == "SoldDate"
			).attributeValue;
			compCreatedDate = this.state.getSalesCompDetailsResponse.find(
				(x) => x.dbColumn == "CompCreatedDate"
			).attributeValue;
			closeDate = this.state.getSalesCompDetailsResponse.find(
				(x) => x.dbColumn == "Closedate"
			).attributeValue;
		}

		let salesData = this.state.getSalesCompDetails;
		if (this.state.getSalesCompDetails.length > 0) {
			salesData.filter((x) => {
				if (x.dbColumn == "SoldDate") {
					if (x.attributeValue != soldDate)
						x.attributeValue = soldDate;
				}
				if (x.dbColumn == "CompCreatedDate") {
					if (x.attributeValue != compCreatedDate)
						x.attributeValue = compCreatedDate;
				}
				if (x.dbColumn == "Closedate") {
					if (x.attributeValue != closeDate)
						x.attributeValue = closeDate;
				}
			})
		}

		this.setState({ getSalesCompDetails: salesData, reloadCompData: !this.state.reloadCompData });
	}


	render() {
		const {
			//getAllTenancy,
			getAllCompSource,
			getAllNegotationType,
			getAllInvestmentType,
			isEditEnabled,
			getSalesCompDetails,
			buildingMasterDetails,
			getAllMSalesStatus,
			getAllBuyerDetails,
			getAllSellerDetails,
			brokerMasterDetails,
			getBrokerMaster,
			getAllCompType,
			salesCompsSummaryData,
			getHistory,
			getHistoryDataByUser,
			getHistoryDetailByField,
			isHistoryByField,
			companyMasterDetails,
			getAllRepresentationType,

		} = this.state;
		var ownerName = "";
		let getRepresentionType = getAllRepresentationType.filter((m) => {
			if (m.representationType === "Buyer" || m.representationType === 'Seller') return m;
		});
		let compFilteredDataData = salesCompsSummaryData.filter((m) => {
			if (m.buildingName != null) return m;
		});
		let getCompType = getAllCompType.filter((m) => {
			if (m.compType === 'Sales') return m;
		});
		const filterConfig = [
			{
				type: controlType.DropDown,
				minTag: 1,
				source: compFilteredDataData,
				key: "buildingName",
				placeholder: "",
				title: "Tenant",
			},
		];
		if (getSalesCompDetails.length > 0) {
			ownerName = getSalesCompDetails.find(
				(x) => x.dbColumn == "BuildingName"
			).attributeValue;
		}
		const addnewOption = true;

		//const ServiceTypeMasterData = this.props.serviceTypeMasterData;
		const companySeparatorConfig = [
			{
				separatorBeforeKey: "InternalDeal",
				order: 1,
				row: 1,
				sectionColumn: 12,
				label: "Key Transaction Details",
			},
			{
				separatorBeforeKey: "Buyer",
				order: 1,
				row: 1,
				sectionColumn: 12,
				label: "Deal Party",
			},
			{
				separatorBeforeKey: "CapRate",
				order: 1,
				row: 1,
				sectionColumn: 12,
				label: "Additional Notes & Details",
			},
			{
				separatorBeforeKey: "CompVerified",
				order: 1,
				row: 1,
				sectionColumn: 12,
				label: "Sourcing",
			},
			// {
			// 	separatorBeforeKey: "Notes",
			// 	order: 1,
			// 	row: 1,
			// 	sectionColumn: 12,
			// 	label: "Other Details",
			// },
		];

		const dropDownSource = [
			{
				key: "compSource",
				option: "compSource",
				idField: "compSourceId",
				source: getAllCompSource,
			},
			{
				key: "negotiationType",
				option: "negotiationType",
				idField: "negotiationTypeId",
				source: getAllNegotationType,
			},
			{
				key: "investmentType",
				option: "investmentType",
				idField: "investmentTypeId",
				source: getAllInvestmentType,
			},

			//{
			//	key: "tenancyType",
			//	option: "tenancyType",
			//	idField: "TenancyTypeId",
			//	source: getAllTenancy,
			//},
			{
				key: "representationType",
				option: "representationType",
				idField: "representationTypeId",
				source: getRepresentionType,
			},
			{
				key: "buildingName",
				option: "buildingName",
				idField: "buildingId",
				source: buildingMasterDetails,
				AddNewOption: IsSectionVisible(SECTIONS.Property, SECTIONS.Property_Details, SECTIONS.ADD_EDIT),
				AddNewOptiontitle: "New Property",
				AddOptionType: "Button",
				onAddClick: this.goToAddBuilding,
				onChange: this.buildingChanges,
				onInputChange: this.onBuildingInputChange,
				value: this.state.selectedBuilding,
			},
			{
				key: "buyer",
				option: "buyer",
				idField: "buyerId",
				source: brokerMasterDetails,
				AddNewOption: IsSectionVisible(SECTIONS.Company, SECTIONS.Company_Details, SECTIONS.ADD_EDIT),
				AddNewOptiontitle: "New Company",
				AddOptionType: "Button",
				onAddClick: this.goToAddBuildingOwner,
				onChange: this.buildingOwnerChanges,
				value: this.state.selectedBuildingOwner,
				onInputChange: this.onInputChange,
			},
			{
				key: "compType",
				option: "compType",
				idField: "compTypeId",
				source: getAllCompType,
			},
			{
				key: "seller",
				option: "seller",
				idField: "sellerId",
				source: getAllSellerDetails,
				onInputChange: this.onSellerInputChange
			},
			{
				key: "salesStatus",
				option: "salesStatus",
				idField: "salesStatusId",
				source: getAllMSalesStatus,
			},
			{
				key: "brokerName",
				option: "brokerName",
				idField: "tenantRepBrokerId",
				source: getBrokerMaster,
			},
		];

		const DetailsFormConfig = [
			{
				key: "buildingName",
				type: controlType.DropDown,
				multiple: true,
			},
			{
				key: "buyer",
				type: controlType.Autofill,
				multiple: false,
			},
			{
				key: "seller",
				type: controlType.Autofill,
				multiple: false,
			},
			{
				key: "compType",
				type: controlType.DropDown,
				multiple: false,
			},
			{
				key: "broker",
				type: controlType.TextBox,
				multiple: false,
			},
			{
				key: "salesStatus",
				type: controlType.DropDown,
				multiple: false,
			},
			{
				key: "soldDate",
				type: controlType.DatePicker,
				multiple: false,
			},
			{
				key: "lxDte",
				type: controlType.DatePicker,
				multiple: false,
			},
			{
				key: "salesPSF",
				type: controlType.TextBox,
				multiple: false,
			},
			{
				key: "dr",
				type: controlType.Switch,
				multiple: false,
			},
			{
				key: "notes",
				type: controlType.TextArea,
				multiple: false,
			},
			{ key: "importFileName", type: controlType.Link, multiple: false },
			{
				key: "representationType",
				type: controlType.DropDown,
				multiple: false,
			},
			{
				key: "tenancyType",
				type: controlType.DropDown,
				multiple: false,
			},
			{
				key: "compSource",
				type: controlType.DropDown,
				multiple: false,
			},
			{
				key: "negotiationType",
				type: controlType.DropDown,
				multiple: false,
			},
			{
				key: "investmentType",
				type: controlType.DropDown,
				multiple: false,
			}

		];
		const ownerNam = ownerName != undefined ? ownerName : this.props.ViewTitle;
		return (
			<>
				{this.state.openHistoryform && (
					<Dialog open={this.state.openHistoryform} maxWidth="lg">
						<AppBar
							style={{ position: "relative", backgroundColor: "#064473" }}
						>
							<Toolbar className="custom-toolbar">
								<Typography
									variant="subtitle1"
									className="app-header-white"
									style={{ marginTop: "15px", flex: "1", color: "#FFFFF" }}
								>
									Update History
								</Typography>
								<IconButton
									edge="start"
									color="inherit"
									onClick={() => {
										this.setState({
											openHistoryform: false,
											isHistoryByField: false,
										});
									}}
									aria-label="close"
									style={{ padding: "0px" }}
								>
									<IoMdCloseCircleOutline />
								</IconButton>
							</Toolbar>
						</AppBar>
						<div>
							<ViewHistoryData
								sourceData={getHistoryDataByUser}
								getHistoryDetailByField={getHistoryDetailByField}
								dropDownSource={dropDownSource}
								isHistoryByField={isHistoryByField}
							></ViewHistoryData>
						</div>
					</Dialog>
				)}
				{this.state.isDataSaved && this.state.isSearchedClicked && (
					<ConfirmDialog
						IsOpen={this.state.isSearchedClicked}
						Message={
							"There are unsaved changes.Do you still want to search another Company"
						}
						OnHandelClick={(isValid) => {
							if (isValid) {
								this.onClickHandel(isValid);
							} else {
								this.onClickHandel(isValid);
							}
						}}
						AgreeText="Yes"
						DisAgreeText="No"
					></ConfirmDialog>
				)}

				{this.state.isDeleteClicked && (
					<ConfirmDialog
						IsOpen={this.state.isDeleteClicked}
						Message={
							"Are you sure you want to delete this record?"
						}
						OnHandelClick={(isValid) => {
							if (isValid) {
								this.onDeleteClickHandel(isValid);
							} else {
								this.onDeleteClickHandel(isValid);
							}
						}}
						AgreeText="Yes"
						DisAgreeText="No"
					></ConfirmDialog>
				)}

				{this.state.openaddform && (
					<Dialog open={this.state.openaddform} maxWidth="lg">
						<AppBar
							style={{ position: "relative", backgroundColor: "#064473" }}
						>
							<Toolbar className="custom-toolbar">
								<Typography
									variant="subtitle1"
									className="app-header-white"
									style={{ marginTop: "15px", flex: "1", color: "#FFFFF" }}
								>
									{this.state.addformtitle}
								</Typography>
								<IconButton
									edge="start"
									color="inherit"
									onClick={() => {
										this.reloadPropertyData();
									}}
									aria-label="close"
									style={{ padding: "0px" }}
								>
									<IoMdCloseCircleOutline />
								</IconButton>
							</Toolbar>
						</AppBar>
						<div>
							<EditBuildings
								globalMarketId={this.props.globalMarketId}
								selectedBuildingId={this.state.selectedEditRowId}
								onBackClick={this.onPopupBackClick}
								ViewTitle={this.state.ViewTitle}
								actionType={this.state.actionType}
								isInAddMode={true}
								reloadData={this.reloadData}
								isRedirectedFromTenant={true}
								reloadPropertyData={this.reloadPropertyData}

								companyMasterDetails={companyMasterDetails}
								getBrokerMaster={getBrokerMaster}
								setBuilding={this.setBuilding}
							// isPropertyOfInterest={true}
							/>
						</div>
					</Dialog>
				)}

				{this.state.openPropertyOwnerAddForm && (
					<Dialog open={this.state.openPropertyOwnerAddForm} maxWidth="lg">
						<AppBar
							style={{ position: "relative", backgroundColor: "#064473" }}
						>
							<Toolbar className="custom-toolbar">
								<Typography
									variant="subtitle1"
									className="app-header-white"
									style={{ marginTop: "15px", flex: "1", color: "#FFFFF" }}
								>
									{this.state.addformtitle}
								</Typography>
								<IconButton
									edge="start"
									color="inherit"
									onClick={() => {
										this.setState({ openPropertyOwnerAddForm: false });
										//this.reloadPropertyOwnerData();
									}}
									aria-label="close"
									style={{ padding: "0px" }}
								>
									<IoMdCloseCircleOutline />
								</IconButton>
							</Toolbar>
						</AppBar>
						<div>
							<EditBuildingOwners
								globalMarketId={this.props.globalMarketId}
								selectedBuildingId={this.state.selectedEditRowId}
								onBackClick={this.onPropertyOwnerPopupBackClick}
								ViewTitle={this.state.ViewTitle}
								actionType={this.state.actionType}
								isInAddMode={true}
								reloadData={this.reloadData}
								isRedirectedFromTenant={true}
								reloadPropertyOwnerData={this.reloadPropertyOwnerData}
								// companyMasterDetails={companyMasterDetails}
								setBuildingOwner={this.setBuildingOwner}
							/>
						</div>
					</Dialog>
				)}

				{isEditEnabled || this.props.isInAddMode ? (
					<div className="row header-container">
						<div className="col-md-4 col-lg-4">
							<div className="row">
								<PageHeader
									btnText={"Sales Comps"}
									onBackClick={this.onBackClick}
									title={
										this.props.isInAddMode
											? "/ " + this.props.ViewTitle
											: "/ " + ownerNam
									}
									style={{ color: "#00B0B9" }}
								></PageHeader>
							</div>
						</div>

						<div className="col-md-4 col-lg-4">
							{salesCompsSummaryData.length > 0 && (
								<AutoSearch
									placeholder="Search another Property"
									isEditEnabled={this.state.isEditEnabled}
									sourceData={compFilteredDataData}
									filterConfig={filterConfig}
									onSearch={this.onSearch}
								></AutoSearch>
							)}
							{/* <SearchBar></SearchBar> */}
						</div>
						<div className="col-md-4 col-lg-4 end-sm">
							<Button
								size="small"
								style={{ marginTop: "5px" }}
								className={this.props.isInAddMode ? "btn-clear" : "btn-clear btn-small"}
								onClick={() => {
									this.setState({
										getSalesCompDetails: JSON.parse(JSON.stringify([...this.state.getSalesCompDetailsResponse])),
										reloadCompData: !this.state.reloadCompData,
									});
									//this.clearDates();
								}}
							>
								<span className="tool-icon-text">{this.props.isInAddMode == true ? 'Clear All' : 'Undo'}</span>
							</Button>
							{/* { !this.props.isInAddMode && */}
							{!this.props.isInAddMode && IsSectionVisible(SECTIONS.Sales_Comps, SECTIONS.Sales_Comp_Details, SECTIONS.ADD_EDIT) && (
								<Button
									size="small"
									className="notop-action-button btn-small"
									onClick={() => {
										this.onDeleteClick();
									}}
									style={{ backgroundColor: "red !important" }}
								>
									<span className="tool-icon-text">Delete</span>
								</Button>
							)}
							<Button
								size="small"
								className={this.props.isInAddMode ? "notop-action-button" : "notop-action-button btn-small"}
								onClick={() => {
									this.onBackClick();
								}}
							>
								<span className="tool-icon-text">Cancel</span>
							</Button>
							{IsSectionVisible(SECTIONS.Sales_Comps, SECTIONS.Sales_Comp_Details, SECTIONS.ADD_EDIT) &&
								<Button
									size="small"
									className={this.props.isInAddMode ? "notop-action-button" : "notop-action-button btn-small"}
									onClick={() => {
										this.saveCompDetails();
									}}
								>
									<span className="tool-icon-text">Save</span>
								</Button>
							}
						</div>
					</div>
				) : (
					<div className="row header-container">
						<div className="col-md-4 col-lg-4">
							<PageHeader
								btnText={"Sales Comps"}
								onBackClick={this.onBackClick}
								title={"/ " + ownerNam}
								style={{ color: "#00B0B9" }}
							></PageHeader>
						</div>
						<div className="col-md-4 col-lg-4">
							{salesCompsSummaryData.length > 0 && (
								<AutoSearch
									placeholder="Search another Property"
									isEditEnabled={this.state.isEditEnabled}
									sourceData={compFilteredDataData}
									filterConfig={filterConfig}
									onSearch={this.onSearch}
								></AutoSearch>
							)}
							{/* <SearchBar></SearchBar> */}
						</div>
						<div className="col-md-4 col-lg-4 end-sm">
							{IsSectionVisible(SECTIONS.Sales_Comps, SECTIONS.Sales_Comp_Details, SECTIONS.ADD_EDIT) && (
								<Button
									size="small"
									className="notop-action-button btn-small"
									onClick={() => {
										this.onDeleteClick();
									}}
									style={{ backgroundColor: "red !important" }}
								>
									<span className="tool-icon-text">Delete</span>
								</Button>
							)}
							<Button
								size="small"
								className="notop-action-button btn-small"
								onClick={() => {
									this.props.onBackClick();
								}}
							>
								<span className="tool-icon-text">Cancel</span>
							</Button>
							{IsSectionVisible(SECTIONS.Sales_Comps, SECTIONS.Sales_Comp_Details, SECTIONS.ADD_EDIT) &&
								<Button
									size="small"
									className="notop-action-button btn-small"
									onClick={() => {
										this.enableEdit();
									}}
								>
									<span className="tool-icon-text">Edit</span>
								</Button>
							}
						</div>
					</div>
				)}
				{getHistory.length > 0 && isEditEnabled && (
					<div className="row">
						<div className="col-md-7 col-lg-7 header-container"></div>
						<div
							className="col-md-2 col-lg-2 end-sm"
							style={{ padding: "8px" }}
						>
							<span>Last Modified:</span>
						</div>
						<div className="col-md-3 col-lg-3 end-sm">
							<Autocomplete
								key={`lastModifiedSales:${getHistory[0].createdBy}`}
								// style={{ width: "374px" }}
								size="small"
								underlineStyle={{ display: "none" }}
								//value={}
								// defaultValue={"Select Record" }
								defaultValue={{
									createdBy: getHistory[0].createdBy
									, createdDate:
										moment(getHistory[0].createdDate)
											.format("MM-DD-YYYY HH:mm")
								}}
								onClose={(e, val) => this.handleClose(e, val)}
								onChange={(e, val) => this.handleChange(e, val)}
								id="tags-standard"
								options={getHistory}
								getOptionLabel={(option) =>
									option.createdBy +
									"," +
									moment(moment(option.createdDate).toDate())
										.format("MM-DD-YYYY HH:mm:ss")
										.toString()
								}
								renderInput={(params) => (
									// <Box
									//   sx={{ display: "flex", alignItems: "center" }}
									//   className="filter-label"
									// >
									//   <label htmlFor="filled-hidden-label-small">
									//
									//   </label>
									<TextField
										key={`lastModifiedSalesComp:${getHistory[0].createdBy}`}
										{...params}
										variant="outlined"
										margin="normal"
										defaultValue={{
											createdBy: getHistory[0].createdBy
											, createdDate:
												moment(getHistory[0].createdDate)
													.format("MM-DD-YYYY HH:mm")
										}}
										hiddenLabel
									/>
									// </Box>
								)}
							/>
							{/* <SimpleSelect></SimpleSelect> */}
						</div>
					</div>
				)}
				{/* <div className="row">
          <div className="col-sm-7 col-lg-7"></div>
          <div
            className="col-sm-2 col-lg-2 end-sm"
            style={{ marginTop: "1px" }}
          >
            <span>Last Modified:</span>
          </div>
          <div
            className="col-sm-2 col-lg-2 end-sm"
           // style={{ marginTop: "9px", marginLeft: "8px" }}
          >
            <SimpleSelect></SimpleSelect>
          </div>
        </div> */}

				{/* <div style={{float: 'right'}}>
          <Button
                    variant="contained"
                    color="primary"
                   className="addedit-buttons"
                    size="small"
                    onClick={() => {
                     
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                   className="addedit-buttons"
                    onClick={() => {
                     
                    }}
                  >
                    Save & Exit
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                   className="addedit-buttons"
                    onClick={() => {
                      this.props.onBackClick()
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className="addedit-buttons"
                    onClick={() => {
                      
                    }}
                  >
                    Reset
                  </Button>
          </div> */}
				{/* <div className="row row col-xs-9" style={{marginTop:'49px',marginLeft:'3.8%'}}>
         <div  className={'col-xs-2 filter-label'} style={{marginTop: 20,textAlign:'left'}}>Search</div>
          <div  style={{marginLeft:'-1%',width: '30.8%'}}  > 
         <SearchBar></SearchBar>
          </div>
           </div> */}
				{/* <div className="div-searchBar">
            <SearchBar></SearchBar>
          </div> */}
				<div>
					{getSalesCompDetails && (
						<FormGenerator
							gridColumn={4}
							isIconDisabled={this.state.isIconDisabled}
							ReloadForm={this.state.reloadCompData}
							getHistoryDataByField={this.getHistoryDataByField}
							formFields={UpdateFieldsWithConfiguration(
								getSalesCompDetails,
								DetailsFormConfig
							)}
							Editable={this.state.isEditEnabled}
							dropDownSource={dropDownSource}
							saveChanges={this.getCompChanges}
							partialForm={true}
							validationMessages={[]}
							formType={"Section"}
							separatorConfig={companySeparatorConfig}
							sectionRowConfig={[{ row: 1, column: 12 }]}
							// sectionRowConfig={[{row:1,column:5},{row:2,column:4,labelAlign:'alignText-Right'}]}
							viewPDF={[]}
							downloadURL={this.state.downloadURL}
						></FormGenerator>
					)}
				</div>
			</>
		);
	}
}

