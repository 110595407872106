import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
  TextField,
  Tooltip,
  Box,
} from "@material-ui/core";
import React from "react";
import camelcaseKeys from "camelcase-keys";
import { IoMdCloseCircleOutline } from "react-icons/io";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import EditIndLeaseComps from "../LeaseComps/EditIndLeaseComps";
import { API_URL,getCurrentEnvironment } from "../../../constants/api";
import PageHeader from "../../common/PageHeader";
import AutoSearch from "../../common/AutoSearch/AutoSearch";
import {
  apiBatchRequest,
  apiPost,
  apiPostNoLoader,
  apiPut,
  apiRequest,
  apiRequestNoLoadder,
  clearCacheData,
  getSpsAPiRequest,
  spsApiPost,
  uploadSpsAPiRequest,
} from "../../../utils/api_service";
import { SECTIONS, DateFormatImport } from "../../../constants/appConstants";
import EditIndBuildingOwners from "../BuildingOwner/EditIndBuildingOwners";
import { GridTable } from "../../common/Table/Table";
import {
  HistoricalOwnerColumns,
  indMarketAbsorptionReportColumns,
  tenantColumnForDetailScreen,
  compSetColumns,
  MajorTenantMoveInsReportColumns,
  MajorTenantMoveOutsReportColumns,
  marketAbsorptionReportColumns,
  propertyStatsColumnGrouping
} from "../../../constants/MockData";
import ViewHistoryData from "../../common/ViewHistoryData/ViewHistoryData";
import { ShowToast, toastType } from "../../../utils/toast-service";
import { controlType } from "../../../constants/common";
import FormGenerator from "../../common/FormGenerator";
import ConfirmDialog from "../../common/Dailogbox/confirmDailog";
import commonLabels from "../../../constants/messageConstants";
import {
  checkFileValidation,
  downloadExcelFile,
  fileDateAppender,
  renameKey,
  isGridFormValid,
  isInputValid,
  IsSectionVisible,
  UpdateColumnWithConfiguration,
  UpdateFieldsWithConfiguration,
} from "../../../utils/helper";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import PropertyOwnerObject from "./PropertyOwnerObject";
import DownloadTemplate from "../../common/ExportImport/DownloadTemplate";
import { downloadImportFileData, generateString, setDownloadingStatus } from "../../../utils/generic";
import * as XLSX from "xlsx";
import { getSPSTokenData, setSPSToken } from "../../../constants/constants";
import { getSharePointUrl } from "../../../constants/sharepointApi";
import EditIndSalesComp from "../SalesComps/EditIndSalesComp";
export default class EditBuilding extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedMarketId: this.props.globalMarketId,
      getBuildingDetails: [],
      isEditEnabled: this.props.isInAddMode,
      companyMasterDetails:
        props.companyMasterDetails != undefined
          ? props.companyMasterDetails
          : [],
      getMasterZip: [],
      getSubMarketMaster:
        props.getSubMarketMaster != undefined ? props.getSubMarketMaster : [],
      getMarketMaster:
        props.getMarketMaster != undefined ? props.getMarketMaster : [],
      getSubmarketClusterMaster:
        props.getSubmarketClusterMaster != undefined ? props.getSubmarketClusterMaster : [],
      getProductTypeMaster: [],
      buildingMasterData:
        props.buildingMasterData != undefined ? props.buildingMasterData : [],
      // getBrokerMaster:
      //   props.getBrokerMaster != undefined ? props.getBrokerMaster : [],
      getBuildingDetailsResponse: [],
      buildingSummaryData:
        props.buildingSummaryData != undefined ? props.buildingSummaryData : [],
      isDataSaved: false,
      isSearchedClicked: false,
      showConfirmDialog: false,
      buildingId: -1,
      getSprinklerData:
        props.getSprinklerData != undefined ? props.getSprinklerData : [],
      //  getDistributionClassMaster: [],
      //  getBuildingParkMaster: [],
      //  getAgentMaster: [],
      getMaterialTypeMaster: [],
      //  getAllSourceSystem: [],
      getConstructionStatus: [],
      getHistoryDetailByField: [],
      openHistoryform: false,
      isHistoryByField: false,
      getHistory: [],
      tenantSummaryData: [],
      historicalSummaryData: [],
      historicalConfiguration: [],
      configuration: [],
      reloadTable: false,
      showTenant: false,
      showHistoricalOwnership: false,
      showEdit: true,
      detailView: false,
      selectedEditRowId: -1,
      selectedTenantRowId: -1,
      selectedSaleRowId:-1,
      selectedBuildingId: -1,
      selectedId: this.props.selectedBuildingId,
      ViewLeaseTitle: "Edit",
      ViewSaleTitle:"Edit",
      actionLeaseType: "Edit",
      actionSaleType:"Edit",
      isLeaseInAddMode: false,
      ViewTitle: "Add",
      actionType: "Add",
      isRedirectedFromTenant: false,
      reloadBuilding: false,
      openaddform: false,
      openCompAddform: false,
      selectedBuildingOwner: undefined,
      reloadCompanyPopup: false,
      getAllTenancy: [],
      coStarData: [],
      companyNameSelected: null,
      isAddedFromPopup: false,
      marketAbsorptionVacancyReport: [],
      majorTenantMoveInReport: [],
      majorTenantMoveOutReport: [],
      marketAbsorptionAndVacancyReportConfiguration: [],
      showMarketAbsorptionReport: false,
      vaccancyData: [],
      showVacancyReport: false,
      isTenantsChanged: false,
      isTenantsApiCalled: false,
      isHistoricalOwnershipChanged: false,
      isHistoricalOwnershipApiCalled: false,
      isMarketAbsorptionChanged: false,
      isMarketAbsorptionApiCalled: false,
      updateMasterData: [],
      isEditable: false,
      getBuildingSubtype: [],
      getPropertyOwnerUserOptions: [],
      getState: [],
      getBuildingTier: [],
      propertyOwnerObjectDetails: [],
      compSetData: [],
      openDialog:false,
      isSaleInAddMode: false,
      openSalesCompAddform: false,
      getAllDockConfiguration:[],
    };
  }

  enableEdit = () => {
    this.setState({
      isEditEnabled: true,
    });
    this.forceUpdate();
    this.getRequiredData();
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.globalMarketId !== this.state.selectedMarketId) {
      this.getMarketSubMarket(nextProps.globalMarketId);
      this.setState({ selectedMarketId: nextProps.globalMarketId });
    }
  }

  onBackClick = () => {
    this.props.onBackClick();
    if (this.state.isAddedFromPopup) this.props.onCancelClickAfterPopupAdd();
  };

  onTenantBackClick = () => {
    this.getTenantData(this.state.selectedId);
    this.setState({
      openCompAddform: false,
      selectedTenantRowId: undefined,
      reloadTable: !this.state.reloadTable,
    });
  };
  onSalesBackClick = () => {
    this.getHistoricalData(this.state.selectedId);
    this.setState({
      openSalesCompAddform: false,
      selectedSaleRowId: undefined,
      reloadTable: !this.state.reloadTable,
    });
  };
  getBuildingChanges = (formFields) => {
    let buyerValue = formFields.find(
      (x) => x.dbColumn == "CompanyName"
    ).attributeValue;
    let buyerData = buyerValue;
    if (buyerValue == "" || buyerValue == "" || buyerValue == undefined) {
      buyerData = undefined;
    }
    this.setState(
      {
        getBuildingDetails: formFields,
        isDataSaved: true,
        isSearchedClicked: false,
        selectedBuildingOwner: buyerData,
        reloadBuilding: !this.state.reloadBuilding,
        companyNameSelected: null,
      },
      () => {
        this.forceUpdate();
      }
    );

    if (
      this.props.isRedirectedFromReport != undefined &&
      this.props.isRedirectedFromReport == true
    ) {
      const CostarId = formFields.filter(
        (x) => _.camelCase(x.dbColumn) === _.camelCase("CoStarPropID")
      );
      let x = this.getCostarDetail(CostarId[0].attributeValue);
    }
  };

  validateForm = () => {
    const buildingData = this.state.getBuildingDetails;
    let isValid = true;
    let breakCondition = false;
    if (buildingData.length == 0) {
      const msg =
        "No change detected. Please make necessary changes and try again.";
      isValid = false;
      breakCondition = true;
      ShowToast(msg, toastType.ERROR, 5000, 500);
    }
    buildingData.forEach((x) => {
      if (
        x.isRequired == true &&
        (x.attributeValue == null ||
          x.attributeValue == undefined ||
          x.attributeValue.trim() == "") &&
        breakCondition != true
      ) {
        const msg = "Please fill all the mandatory fields";
        isValid = false;
        breakCondition = true;
        ShowToast(msg, toastType.ERROR, 5000, 500);
      }
    });
    return isValid;
  };
  isValidForm = () => {
    let IsValid = true;
    const { getBuildingDetails } = this.state;
    getBuildingDetails.forEach((ele) => {
      isGridFormValid(ele.attributeValue, ele, getBuildingDetails);
      if (ele.isInErrorState === true) {
        IsValid = false;
      }
    });
    this.setState({ getBuildingDetails }, this.forceUpdate());
    return IsValid;
  };
  validateRequiredFields = (formElementFields, stateToBeUpdate = {}) => {
    //console.log(formElementFields[0].attributeValue);
    let IsValid = true;
    formElementFields.forEach((ele) => {
      isInputValid(ele.attributeValue, ele, this.state.getBuildingDetails);
      if (ele.isInErrorState === true) {
        IsValid = false;
      }
    });
    //console.log(formElementFields);
    stateToBeUpdate = {
      ...stateToBeUpdate,
      getBuildingDetails: formElementFields,
      reloadBuilding: !this.state.reloadBuilding,
    };
    this.setState(stateToBeUpdate);

    return IsValid;
  };

  getCostarDetail = (CostarId) => {
    const params = {
      costarId: CostarId,
      businessLineId: 2,
    };
    apiRequest({
      url: API_URL.IndustrialBuilding.industrialcheckIfCoStarExist,
      params,
    })
      .then(({ data: coStarData }) => {
        this.setState({
          coStarData: camelcaseKeys(coStarData) || [],
          reloadBuilding: !this.state.reloadBuilding,
        });
      })
      .catch((reason) => {
        this._asyncRequest = null;
        ShowToast(
          commonLabels.somethingWrong,
          toastType.ERROR,
          5000,
          500,
          "Server error"
        );
      });

    return null;
  };
  saveBuildingDetails = () => {
    const buildingData = this.state.getBuildingDetails;
    const CostarId = buildingData.filter(
      (x) => _.camelCase(x.dbColumn) === _.camelCase("CoStarPropID")
    );
    const CompanyId =
      buildingData.length > 0
        ? buildingData.find(
          (x) => _.camelCase(x.dbColumn) === _.camelCase("CompanyId")
        ).attributeValue
        : null;
    const CompanyName =
      buildingData.length > 0
        ? buildingData.find(
          (x) => _.camelCase(x.dbColumn) === _.camelCase("CompanyName")
        ).attributeValue
        : null;
    if (this.props.isRedirectedFromReport == true) {
      if (this.state.coStarData.length > 0) {
        this.setState({
          openCoStarConfirmDialog: true,
        });
      } else {
        const params = {
          OldBuildingId: -1,
          NewBuildingId: this.props.selectedBuildingId,
          CoStarPropId: parseInt(CostarId[0].attributeValue),
        };
        this.saveBuildingDataForReport(params);
      }
    } else {
      let isValid = this.validateForm();
      let isInputValid = this.validateRequiredFields(buildingData);
      if (
        (isValid == false && isInputValid == false) ||
        (isValid == true && isInputValid == false) ||
        (isValid == false && isInputValid == true) ||
        ((CompanyId == null || CompanyId == undefined) &&
          this.state.companyNameSelected != null)
      ) {
        isInputValid == false
          ? ShowToast(
            "There is validation error on page.",
            toastType.ERROR,
            5000,
            500
          )
          : ShowToast(
            "Please select owner from the list given.",
            toastType.ERROR,
            5000,
            500
          );
        return false;
      } else {
        buildingData.forEach((x) => {
          x.attributeValue =
            x.attributeValue != null
              ? x.attributeValue.toString().trim()
              : x.attributeValue;

          if (
            _.camelCase(x.dbColumn) === _.camelCase("ZipId") ||
            _.camelCase(x.dbColumn) === _.camelCase("MarketId") ||
            _.camelCase(x.dbColumn) === _.camelCase("SubMarketId") ||
            _.camelCase(x.dbColumn) === _.camelCase("CompanyId") ||
            _.camelCase(x.dbColumn) === _.camelCase("ProductTypeId") ||
            _.camelCase(x.dbColumn) === _.camelCase("BuildingClassId") ||
            _.camelCase(x.dbColumn) === _.camelCase("ExistingUcId") ||
            // _.camelCase(x.dbColumn) === _.camelCase("DelegateIdOne") ||
            // _.camelCase(x.dbColumn) === _.camelCase("DelegateIdTwo") ||
            _.camelCase(x.dbColumn) === _.camelCase("TenancyTypeId") ||
            _.camelCase(x.dbColumn) === _.camelCase("SourceSystemId") ||
            _.camelCase(x.dbColumn) === _.camelCase("SprinklerTypeId") ||
            _.camelCase(x.dbColumn) === _.camelCase("MaterialTypeId")
          ) {
            if (x.attributeValue != null)
              x.attributeValue = x.attributeValue.toString();

            if (
              _.camelCase(x.dbColumn) === _.camelCase("CompanyId") &&
              (CompanyName == undefined || CompanyName == null)
            ) {
              x.attributeValue = null;
            }
          }
        });

        if (this.props.actionType === "Edit") {
          const buildingId = buildingData.filter(
            (x) => _.camelCase(x.dbColumn) === _.camelCase("BuildingId")
          );

          apiPut(
            API_URL.IndustrialBuilding.industrialBuildingUpdate,
            buildingData,
            {
              BuildingId: buildingId[0].attributeValue,
            }
          )
            .then(({ data: response }) => {
              if (response.success === true) {
                ShowToast(
                  "Property updated successfully",
                  toastType.SUCCESS,
                  5000,
                  500
                );
                this.setState({
                  isDataSaved: false,
                });
                clearCacheData(
                  API_URL.Master.industrialBuildingMasterData +
                  '{"businessLineId":"2","marketId":"' +
                  this.state.selectedMarketId +
                  '"}'
                );
                clearCacheData(
                  API_URL.IndustrialBuilding.industrialBuildingSummaryData +
                  '{"companyId":"-1","marketId":"' +
                  this.state.selectedMarketId +
                  '"}'
                );
                this.props.reloadData();
              } else {
                ShowToast("Error", toastType.ERROR, 5000, 500);
              }
            })
            .catch(() => {
              ShowToast(
                commonLabels.somethingWrong,
                toastType.error,
                5000,
                500
              );
            });
        } else {
          apiPost({
            url: API_URL.IndustrialBuilding.industrialBuildingAdd,
            params: {},
            postBody: buildingData,
          })
            .then(({ data: response }) => {
              if (response.success === true) {
                ShowToast(
                  "Property inserted successfully",
                  toastType.SUCCESS,
                  5000,
                  500
                );
                clearCacheData(
                  API_URL.Master.industrialBuildingMasterData +
                  '{"businessLineId":"2","marketId":"' +
                  this.state.selectedMarketId +
                  '"}'
                );
                clearCacheData(
                  API_URL.IndustrialBuilding.industrialBuildingSummaryData +
                  '{"companyId":"-1","marketId":"' +
                  this.state.selectedMarketId +
                  '"}'
                );
                if (this.props.redirectionRequire == true) {
                  this.props.onBackClick();
                  this.props.reloadBuildingData();
                } else if (this.props.isRedirectedFromTenant == true) {
                  this.props.onBackClick();
                  //this.props.setBuilding(buildingData);
                  this.props.reloadPropertyData(buildingData);
                } else {
                  this.props.reloadData();
                }
              } else {
                ShowToast(response.message, toastType.error, 5000, 500);
              }
            })
            .catch(() => {
              ShowToast(
                commonLabels.somethingWrong,
                toastType.error,
                5000,
                500
              );
            });
        }
      }
    }
  };

  saveBuildingDataForReport = (params) => {
    apiPut(API_URL.IndustrialBuilding.industrialUpdateCoStarId, params)
      .then(({ data: response }) => {
        if (response.success === true) {
          ShowToast(
            "Property updated successfully",
            toastType.SUCCESS,
            5000,
            500
          );
          this.setState({
            isDataSaved: false,
          });
          this.props.onBackClick();
        } else {
          ShowToast("Error", toastType.ERROR, 5000, 500);
        }
      })
      .catch(() => {
        ShowToast(commonLabels.somethingWrong, toastType.error, 5000, 500);
      });
  };
  getData = (buildingId) => {
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.IndustrialBuilding.industrialBuildingDetail,
        key: "getBuildingDetails",
        params: { buildingID: buildingId },
      },
    ])
      .then((getBuildingDetails) => {
        this._asyncRequest = null;
        if (getBuildingDetails.getBuildingDetails.success == true) {
          let companyName = getBuildingDetails.getBuildingDetails.data.find(
            (x) => x.dbColumn == "CompanyName"
          ).attributeValue;
          const params = {
            businessLineId: 2,
            searchText: companyName,
            buildingId: this.state.selectedId,
            //compId:this.state.compId
          };
          apiRequestNoLoadder({
            url: API_URL.Master.buildingOwnerMasterDetails,
            params,
          })
            .then(({ data: companyMasterDetails }) => {
              this.setState(
                {
                  companyMasterDetails:
                    camelcaseKeys(companyMasterDetails) || [],
                  propertyOwnerObjectDetails: camelcaseKeys(companyMasterDetails) || [],
                  reloadBuilding: !this.state.reloadBuilding,
                },
                () => {
                  this.forceUpdate();
                }
              );
            })
            .catch((reason) => {
              this._asyncRequest = null;
              ShowToast(
                commonLabels.somethingWrong,
                toastType.ERROR,
                5000,
                500,
                "Server error"
              );
            });

          this.setState({
            getBuildingDetails:
              camelcaseKeys(getBuildingDetails.getBuildingDetails.data) || [],
            getHistory:
              camelcaseKeys(
                getBuildingDetails.getBuildingDetails.configuration
              ) || [],
          });
          this.forceUpdate();
        } else {
          ShowToast(
            "Something went wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  componentDidMount() {
    this.getPropertyDetails();
    if (this.props.isInAddMode == true) {
      this.getRequiredData();
    }
  }

  getMarketSubMarket(selectedMarketId) {
    this._asyncRequest = apiBatchRequest([
      //  {
      //    url: API_URL.Master.getSubMarketMaster,
      //    key: "getSubMarketMaster",
      //    params: { businessLineId: 2, marketId: selectedMarketId },
      //    fromCache: true,
      //    duration: 60,
      //  },
      //  {
      //    url: API_URL.Master.getMarketMaster,
      //    key: "getMarketMaster",
      //    params: { businessLineId: 2, marketId: selectedMarketId },
      //    fromCache: true,
      //    duration: 60,
      //  },
      {
        url: API_URL.Master.getMarketSubmarket,
        key: "getMarketSubmarket",
        params: { businessLineId: 2, marketId: selectedMarketId },
        //fromCache: true,
        //duration: 60,
      }
    ])
      .then((getMarketSubmarket) => {
        this._asyncRequest = null;
        if (getMarketSubmarket.getMarketSubmarket.market.success == true) {
          this.setState({
            getSubMarketMaster:
              camelcaseKeys(getMarketSubmarket.getMarketSubmarket.submarket.data) || [],
            getMarketMaster:
              camelcaseKeys(getMarketSubmarket.getMarketSubmarket.market.data) || [],
            reloadBuilding: !this.state.reloadBuilding,
          });
          this.forceUpdate();
        } else {
          ShowToast(
            "Something went wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  }
  // getBrokerData() {
  //   this._asyncRequest = apiBatchRequest([
  //     {
  //       url: API_URL.Master.getBrokerMaster,
  //       key: "getBrokerMaster",
  //     },
  //   ])
  //     .then((getBrokerMaster) => {
  //       this._asyncRequest = null;
  //       if (getBrokerMaster.getBrokerMaster.success == true) {
  //         this.setState({
  //           getBrokerMaster:
  //             camelcaseKeys(getBrokerMaster.getBrokerMaster.data) || [],
  //           reloadBuilding: !this.state.reloadBuilding,
  //         });
  //         this.forceUpdate();
  //       } else {
  //         ShowToast(
  //           "Something went wrong",
  //           toastType.ERROR,
  //           5000,
  //           500,
  //           "Server error"
  //         );
  //       }
  //     })
  //     .catch(() => {
  //       this._asyncRequest = null;
  //     });
  // }
  getBuildingSummaryMasterData() {
    this._asyncRequest = apiBatchRequest([
      //  {
      //    url: API_URL.IndustrialBuilding.industrialBuildingSummaryData,
      //    key: "buildingSummaryData",
      //    params: { companyId: "-1", marketId: this.state.selectedMarketId },
      //    fromCache: true,
      //    duration: 60,
      //  },
      {
        url: API_URL.Master.buildingMasterData,
        key: "buildingMasterData",
        params: { businessLineId: 2 },
        fromCache: true,
        duration: 60,
      },
    ])
      .then((buildingMasterData) => {
        this._asyncRequest = null;
        if (buildingMasterData.buildingMasterData.success == true) {
          this.setState({
            //buildingSummaryData:
            //  camelcaseKeys(buildingMasterData.buildingSummaryData.data) || [],
            buildingMasterData:
              camelcaseKeys(buildingMasterData.buildingMasterData.data) || [],
            reloadBuilding: !this.state.reloadBuilding,
          });
          this.forceUpdate();
        } else {
          ShowToast(
            "Something went wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  }
  getRequiredData = () => {
    this._asyncRequest = apiBatchRequest([
      //  {
      //    url: API_URL.Master.getProductTypeMaster,
      //    key: "getProductTypeMaster",
      //    fromCache: true,
      //    duration: 60,
      //  },
      //  {
      //    url: API_URL.Master.getAllSourceSystem,
      //    key: "getAllSourceSystem",
      //    params: { businessLineId: 2 },
      //    fromCache: true,
      //    duration: 60,
      //  },
      //  {
      //    url: API_URL.Master.getConstructionStatus,
      //    key: "getConstructionStatus",
      //    params: { businessLineId: 2 },
      //    fromCache: true,
      //    duration: 60,
      //  },
      //  {
      //    url: API_URL.Master.getAllTenancy,
      //    key: "getAllTenancy",
      //    params: { businessLineId: 2 },
      //    fromCache: true,
      //    duration: 60,
      //  },
      //  {
      //    url: API_URL.Master.industrialBuildingMasterDistClassData,
      //    key: "getDistributionClassMaster",
      //  },
      //  {
      //    url: API_URL.Master.industrialBuildingMasterBuildingParkData,
      //    key: "getBuildingParkMaster",
      //  },
      //  {
      //    url: API_URL.Master.industrialBuildingMasterAgentData,
      //    key: "getAgentMaster",
      //  },
      {
        url: API_URL.Master.getBuildingMasters,
        key: "getBuildingMasters",
        params: { businessLineId: 2 },
        //fromCache: true,
        //duration: 60,
      },
      {
        url: API_URL.Master.industrialBuildingMasterMaterialTypeData,
        key: "getMaterialTypeMaster",
      },
     
    ])
      .then((getBuildingMasters) => {
        this._asyncRequest = null;
        if (getBuildingMasters.getBuildingMasters.product.length > 0) {
          this.setState({
            //getAllSourceSystem:
            //  camelcaseKeys(getProductTypeMaster.getAllSourceSystem.data) || [],
            getProductTypeMaster:
              camelcaseKeys(getBuildingMasters.getBuildingMasters.product) ||
              [],
            getConstructionStatus:
              camelcaseKeys(getBuildingMasters.getBuildingMasters.constructionStatus) ||
              [],
            getAllTenancy:
              camelcaseKeys(getBuildingMasters.getBuildingMasters.tenancy) || [],
            //getDistributionClassMaster:
            //  camelcaseKeys(
            //    getProductTypeMaster.getDistributionClassMaster.data
            //  ) || [],
            //getBuildingParkMaster:
            //  camelcaseKeys(getProductTypeMaster.getBuildingParkMaster.data) ||
            //  [],
            //getAgentMaster:
            //  camelcaseKeys(getProductTypeMaster.getAgentMaster.data) || [],
            getMaterialTypeMaster:
              camelcaseKeys(getBuildingMasters.getMaterialTypeMaster.data) ||
              [],
            
            getPropertyOwnerUserOptions:
              camelcaseKeys(getBuildingMasters.getBuildingMasters.propetyOwnerUserOptions) || [],

            getBuildingSubtype:
              camelcaseKeys(getBuildingMasters.getBuildingMasters.buildingSubtype) || [],

            getState:
              camelcaseKeys(getBuildingMasters.getBuildingMasters.state) || [],

            getBuildingTier:
              camelcaseKeys(getBuildingMasters.getBuildingMasters.buildingTier) || [],
            reloadBuilding: !this.state.reloadBuilding,
          });
          //this.forceUpdate();
        } else {
          ShowToast(
            "Something went wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
    if (this.state.getSubMarketMaster.length == 0) {
      this.getMarketSubMarket(this.state.selectedMarketId);
    }
    // if (
    //   this.state.buildingSummaryData == null ||
    //   this.state.buildingSummaryData == undefined ||
    //   this.state.buildingSummaryData.length == 0
    // ) {
    //   this.getBuildingSummaryMasterData();
    // }
    // if (this.state.getBrokerMaster.length == 0) {
    //   this.getBrokerData();
    // }
    if (this.state.companyMasterDetails.length == 0) {
      this.reloadPropertyOwnerData(this.state.getBuildingDetails, 1);
    }
    if (this.state.buildingMasterData.length == 0) {
      this.getBuildingMasterData();
    }
    if (this.state.getSprinklerData.length == 0) {
      this.getSprinklerData();
    }
  };
  getAllData = () => {
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.IndustrialBuilding.industrialBuildingDetail,
        key: "getBuildingDetails",
        params: { buildingID: this.props.selectedBuildingId },
      },
      //  {
      //    url: API_URL.IndustrialBuilding.industrialBuildingSummaryData,
      //    key: "buildingSummaryData",
      //    params: { companyId: "-1", marketId: this.state.selectedMarketId },
      //  },
      //  {
      //    url: API_URL.Master.getSubMarketMaster,
      //    key: "getSubMarketMaster",
      //    params: { businessLineId: 2 },
      //  },
      //  {
      //    url: API_URL.Master.getMarketMaster,
      //    key: "getMarketMaster",
      //    params: { businessLineId: 2 },
      //  },
      {
        url: API_URL.Master.getMarketSubmarket,
        key: "getMarketSubmarket",
        params: { businessLineId: 2, marketId: selectedMarketId },
        //fromCache: true,
        //duration: 60,
      },

      //  {
      //    url: API_URL.Master.getProductTypeMaster,
      //    key: "getProductTypeMaster",
      //  },
      {
        url: API_URL.Master.buildingMasterData,
        key: "buildingMasterData",
        params: { businessLineId: 2 },
      },
      // {
      //   url: API_URL.Master.getBrokerMaster,
      //   key: "getBrokerMaster",
      // },
      {
        url: API_URL.Master.getConstructionStatus,
        key: "getConstructionStatus",
        params: { businessLineId: 2 },
      },
      {
        url: API_URL.Master.ownerTypeData,
        key: "ownerTypeData",
      },
    ])
      .then((getBuildingDetails) => {
        this._asyncRequest = null;
        if (getBuildingDetails.getBuildingDetails.success == true) {
          this.setState({
            getBuildingDetails:
              camelcaseKeys(getBuildingDetails.getBuildingDetails.data) || [],
            getHistory:
              camelcaseKeys(
                getBuildingDetails.getBuildingDetails.configuration
              ) || [],
            getBuildingDetailsResponse:
              camelcaseKeys(getBuildingDetails.getBuildingDetails.data) || [],
            //buildingSummaryData:
            //  camelcaseKeys(getBuildingDetails.buildingSummaryData.data) || [],
            getSubMarketMaster:
              camelcaseKeys(getBuildingDetails.getMarketSubmarket.submarket.data) || [],
            getMarketMaster:
              camelcaseKeys(getBuildingDetails.getMarketSubmarket.market.data) || [],
            getProductTypeMaster:
              camelcaseKeys(getBuildingDetails.getBuildingMasters.product) || [],
            buildingMasterData:
              camelcaseKeys(getBuildingDetails.buildingMasterData.data) || [],
            // getBrokerMaster:
            //   camelcaseKeys(getBuildingDetails.getBrokerMaster.data) || [],
            getConstructionStatus:
              camelcaseKeys(getBuildingDetails.getBuildingMasters.constructionStatus) ||
              [],
            ownerTypeData: getCompaniesDetails.ownerTypeData.data || [],
            reloadBuilding: !this.state.reloadBuilding,
          });
          this.forceUpdate();
        } else {
          ShowToast(
            "Something went wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };
  getPropertyDetails = () => {
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.IndustrialBuilding.industrialBuildingDetail,
        key: "getBuildingDetails",
        params: { buildingID: this.props.selectedBuildingId },
      },
      {
				url: API_URL.Master.GetAllDockConfiguration,
				key: "getAllDockConfiguration",
				params: { businessLineId: 2 },
				fromCache: true,
				duration: 60,
			},
    ])
      .then((getBuildingDetails) => {
        this._asyncRequest = null;
        if (getBuildingDetails.getBuildingDetails.success == true) {
          this.setState({
            getBuildingDetails:
              camelcaseKeys(getBuildingDetails.getBuildingDetails.data) || [],
              getAllDockConfiguration: camelcaseKeys(getBuildingDetails.getAllDockConfiguration.data) || [],
            getHistory:
              camelcaseKeys(
                getBuildingDetails.getBuildingDetails.configuration
              ) || [],
            getBuildingDetailsResponse:
              camelcaseKeys(getBuildingDetails.getBuildingDetails.data) || [],
            reloadBuilding: !this.state.reloadBuilding,
          });
          if (
            this.state.buildingSummaryData == null ||
            this.state.buildingSummaryData == undefined ||
            this.state.buildingSummaryData.length == 0
          ) {
            this.getBuildingSummaryMasterData();
          }
          this.forceUpdate();
          //this.getRequiredData();
        } else {
          ShowToast(
            "Something went wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };
  onSearch = (event, value, e) => {
    const { isDataSaved } = this.state;
    let buildingId = this.state.buildingSummaryData.find(
      (m) => m.buildingName == value
    ).buildingId;
    this.setState({
      buildingId: buildingId,
      isSearchedClicked: true,
      isTenantsChanged: true,
      isTenantsApiCalled: false,
      isHistoricalOwnershipChanged: true,
      isHistoricalOwnershipApiCalled: false,
      isMarketAbsorptionChanged: true,
      isMarketAbsorptionApiCalled: false,
    });

    if (isDataSaved == false) {
      this.setState({ getHistory: [] });
      this.getData(buildingId);
      this.setState({
        selectedId: buildingId,
      });
    }
    //console.log(event);
  };
  onSearchBuilding = () => {
    this.setState({
      isSearchedClicked: false,
      isDataSaved: false,
    });
    this.setState({ getHistory: [] });
    this.getData(this.state.buildingId);
  };
  onClickHandel(e) {
    if (e == true) {
      this.onSearchBuilding();
      this.setState({
        isSearchedClicked: false,
      });
    } else {
      this.setState({
        isSearchedClicked: false,
      });
    }
  }

  onClickHandelCostar(e) {
    const params = {
      OldBuildingId: this.state.coStarData[0].buildingId,
      NewBuildingId: this.props.selectedBuildingId,
      CoStarPropId: this.state.coStarData[0].costarPropId,
    };
    if (e == true) {
      this.saveBuildingDataForReport(params);
      this.setState({
        openCoStarConfirmDialog: false,
      });
    } else {
      this.setState({
        openCoStarConfirmDialog: false,
      });
    }
  }
  getHistoryDataByField = (val) => {
    const params = {
      entityPrimaryKey: this.state.getHistory[0].entityPrimaryKey,
      sectionDetailId: val.sectionDetailId,
      sectionId: val.sectionId,
      dbTable: val.dbTable,
      dbColumn: val.dbColumn,
      businessLineId: 2,
    };

    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.IndustrialBuilding.industrialGetHistoryDetailByField,
        key: "getHistoryDetailByField",
        params: params,
        //{ entityPrimarykey :val.entityPrimarykey,ModifiedDate :val.modifiedDate, },
      },
    ])
      .then((getHistoryDetailByField) => {
        this._asyncRequest = null;
        if (getHistoryDetailByField.getHistoryDetailByField.success == true) {
          this.setState({
            getHistoryDetailByField:
              camelcaseKeys(
                getHistoryDetailByField.getHistoryDetailByField.data
              ) || [],
            openHistoryform: true,
            isHistoryByField: true,
          });
          this.forceUpdate();
        } else {
          ShowToast(
            "Something went wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  handleClose = (e, val) => {
    if (val == "blur") return;
    this.setState({ openHistoryform: true });
  };

  handleChange = (e, val) => {
    const params = {
      entityPrimaryKey: val.entityPrimaryKey,
      modifiedDate: moment(val.createdDate).format("MM-DD-YYYY HH:mm:ss"),
      sectionId: val.sectionId,
      dbTable: val.dbTable,
      businessLineId: 2,
    };
    //console.log("e", e, "val", val);
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.IndustrialBuilding.industrialGetHistoryDataByUser,
        key: "getHistoryDataByUser",
        params: params,
        //{ entityPrimarykey :val.entityPrimarykey,ModifiedDate :val.modifiedDate, },
      },
    ])
      .then((getHistoryDataByUser) => {
        this._asyncRequest = null;
        if (getHistoryDataByUser.getHistoryDataByUser.success == true) {
          this.setState({
            getHistoryDataByUser:
              camelcaseKeys(getHistoryDataByUser.getHistoryDataByUser.data) ||
              [],
            openHistoryform: true,
          });
          this.forceUpdate();
        } else {
          ShowToast(
            "Something went wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  getHistoricalData = (buildingId) => {
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.IndustrialBuilding.industrialGetHistoricalOwnership,
        key: "historicalSummaryData",
        params: { buildingId: buildingId },
      },
    ])
      .then((historicalSummaryData) => {
        this._asyncRequest = null;
        if (historicalSummaryData.historicalSummaryData.success == true) {
          this.setState({
            historicalSummaryData:
              camelcaseKeys(historicalSummaryData.historicalSummaryData.data) ||
              [],
            historicalConfiguration:
              camelcaseKeys(
                historicalSummaryData.historicalSummaryData.configuration
              ) || [],
            reloadTable: !this.state.reloadTable,
            detailView: false,
            selectedEditRowId: undefined,
            isHistoricalOwnershipChanged: false,
            isHistoricalOwnershipApiCalled: true,
          });
          this.forceUpdate();
        } else {
          ShowToast(
            "Something went wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  getCompSet = (buildingId) => {
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.Building.getBuildingCompSet,
        key: "compSetData",
        params: { buildingId: buildingId },
      },
    ])
      .then((compSetData) => {
        this._asyncRequest = null;
        if (compSetData.compSetData.success == true) {
          this.setState({
            compSetData:
              camelcaseKeys(compSetData.compSetData.data) ||
              [],
              compSetConfiguration:
              camelcaseKeys(
                compSetData.compSetData.configuration
              ) || [],
            reloadTable: !this.state.reloadTable,
            detailView: false,
            selectedEditRowId: undefined,
            isCompSetChanged: false,
            isCompSetApiCalled: true,
          });
          this.forceUpdate();
        } else {
          ShowToast(
            "Something went wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };


  getMarketAbsorptionReport = () => {
    this.setState({ marketAbsorptionVacancyReport: [] });
    const params = {
      marketId: this.state.selectedMarketId, //.join("|"),
      buildingId: this.state.selectedId,
    };
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.IndustrialBuilding.industrialGetReportPropertyAbsorptionAndVacancy,
        params,
        key: " getReportPropertyAbsorptionAndVacancy",
      },
    ])
      .then(({ getReportPropertyAbsorptionAndVacancy }) => {
        this._asyncRequest = null;
        if (getReportPropertyAbsorptionAndVacancy.success !== true) {
          ShowToast(
            commonLabels.somethingWrong,
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
        this.setState({
          marketAbsorptionVacancyReport: camelcaseKeys(
            getReportPropertyAbsorptionAndVacancy.data || []
          ),
          initialMarketAbsorptionReport:camelcaseKeys(
            getReportPropertyAbsorptionAndVacancy.data || []
          ),
          marketAbsorptionAndVacancyReportConfiguration: camelcaseKeys(
            getReportPropertyAbsorptionAndVacancy.configuration || []
          ),
          showMarketAbsorptionReport: true,
          reloadTable: !this.state.reloadTable,
          detailView: false,
          selectedEditRowId: undefined,
          isMarketAbsorptionChanged: false,          
          isMarketAbsorptionApiCalled: true,
        });
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

   getMajorTenantMoveInsReport = () => {
    this.setState({ majorTenantMoveInReport: [] });
    const params = {
      marketId: this.state.selectedMarketId, //.join("|"),
      buildingId: this.state.selectedId,
    };
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.Building.GetReportPropertyMajorMoveIns,
        params,
        key: "getmajorTenantMoveInReport",
      },
    ])
      .then(({ getmajorTenantMoveInReport }) => {
        this._asyncRequest = null;
        if (getmajorTenantMoveInReport.success !== true) {
          ShowToast(
            commonLabels.somethingWrong,
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
        this.setState({
          majorTenantMoveInReport: camelcaseKeys(
            getmajorTenantMoveInReport.data || []
          ),      
          initialMoveInReport:camelcaseKeys(
            getmajorTenantMoveInReport.data || []
          ),    
          MajorTenantMoveInsReportConfiguration: camelcaseKeys(
            getmajorTenantMoveInReport.configuration || []
          ),
          //showMarketAbsorptionReport: true,
          reloadTable: !this.state.reloadTable,
          detailView: false,
          selectedEditRowId: undefined,
          isTenantMoveInChanged: false,
          isTenantMoveInApiCalled: true,
        });
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  getMajorTenantMoveOutsReport = () => {
    this.setState({ majorTenantMoveOutReport: [] });
    const params = {
      marketId: this.state.selectedMarketId, //.join("|"),
      buildingId: this.state.selectedId,
    };
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.Building.GetReportPropertyMajorMoveOuts,
        params,
        key: "getmajorTenantMoveOutReport",
      },
    ])
      .then(({ getmajorTenantMoveOutReport }) => {
        this._asyncRequest = null;
        if (getmajorTenantMoveOutReport.success !== true) {
          ShowToast(
            commonLabels.somethingWrong,
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
        this.setState({
          majorTenantMoveOutReport: camelcaseKeys(
            getmajorTenantMoveOutReport.data || []
          ), 
          initialMoveOutReport:camelcaseKeys(
            getmajorTenantMoveOutReport.data || []
          ),           
          MajorTenantMoveOutsReportConfiguration: camelcaseKeys(
            getmajorTenantMoveOutReport.configuration || []
          ),
          //showMarketAbsorptionReport: true,
          reloadTable: !this.state.reloadTable,
          detailView: false,
          selectedEditRowId: undefined,
          isTenantMoveOutChanged: false,
          isTenantMoveOutApiCalled: true,
        });
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  getTenantData = (buildingId) => {
    buildingId =
      buildingId == null || buildingId == undefined
        ? this.state.selectedId
        : buildingId;
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.IndustrialTenants.tenantsSummaryData,
        key: "tenantSummaryData",
        params: {
          buildingId: buildingId,
          marketId: this.state.selectedMarketId,
        },
      },
    ])
      .then((tenantSummaryData) => {
        this._asyncRequest = null;
        if (tenantSummaryData.tenantSummaryData.success == true) {
          this.setState({
            tenantSummaryData:
              camelcaseKeys(tenantSummaryData.tenantSummaryData.data) || [],
            configuration:
              camelcaseKeys(
                tenantSummaryData.tenantSummaryData.configuration
              ) || [],
            reloadTable: !this.state.reloadTable,
            detailView: false,
            selectedEditRowId: undefined,
            isTenantsChanged: false,
            isTenantsApiCalled: true,
          });
          this.forceUpdate();
        } else {
          ShowToast(
            "Something went wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  goToAddBuildingOwner = (source, option, updateoption = true) => {
    this.setState({
      openaddform: true,
      addformtitle: source.AddNewOptiontitle,
      addNewformComponent: EditIndBuildingOwners,
      addnewClientSource: source,
    });
  };

  onPopupBackClick = () => {
    this.setState({ detailView: false, selectedEditRowId: -1 });
    if (this.state.openaddform == true) {
      this.setState({ openaddform: false }, () => {
        this.forceUpdate();
      });
    }
  };

  reloadPropertyOwnerData = (companyData, bit = 0) => {
    let companyName = null;
    let cId = [];
    if (companyData != undefined && companyData.length > 0) {
      companyName = companyData.find(
        (x) => x.dbColumn == "CompanyName"
      ).attributeValue;
    }

    let bData = this.state.getBuildingDetails;
    const params = {
      businessLineId: 2,
      searchText: companyName != undefined ? companyName : null,
      buildingId: this.state.selectedId,
    };
    if (companyName == null)
      return;
    apiRequest({
      url: API_URL.Master.buildingOwnerMasterDetails,
      params,
    })
      .then(({ data: companyMasterDetails }) => {
        let breakCondition = false;
        cId = companyMasterDetails.find((x) => {
          if (x.CompanyName === companyName && breakCondition == false) {
            breakCondition = true;
            return x;
          }
        });
        bData.length > 0
          ? (bData.find((x) => x.dbColumn == "CompanyName").attributeValue =
            companyName)
          : null;
        bData.length > 0 && cId != null && cId != undefined
          ? (bData.find((x) => x.dbColumn == "CompanyId").attributeValue =
            cId.CompanyId)
          : null;
        this.setState(
          {
            companyMasterDetails: camelcaseKeys(companyMasterDetails) || [],
            getBuildingDetails:
              bit == 1 ? this.state.getBuildingDetails : bData,
            reloadBuilding: !this.state.reloadBuilding,
          },
          () => {
            this.forceUpdate();
          }
        );
      })
      .catch((reason) => {
        this._asyncRequest = null;
        ShowToast(
          commonLabels.somethingWrong,
          toastType.ERROR,
          5000,
          500,
          "Server error"
        );
      });
  };

  getSprinklerData = () => {
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.Master.industrialBuildingSprinklerData,
        key: "getSprinklerData",
      },
    ])
      .then((getSprinklerData) => {
        this._asyncRequest = null;
        if (getSprinklerData.getSprinklerData.success == true) {
          this.setState(
            {
              getSprinklerData:
                camelcaseKeys(getSprinklerData.getSprinklerData.data) || [],
              reloadBuilding: !this.state.reloadBuilding,
            },
            () => {
              this.forceUpdate();
            }
          );
        } else {
          ShowToast(
            "Something went wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  getBuildingMasterData = () => {
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.Master.buildingMasterData,
        key: "buildingMasterData",
        params: { businessLineId: 2 },
        fromCache: true,
        duration: 60,
      },
    ])
      .then((buildingMasterData) => {
        this._asyncRequest = null;
        if (buildingMasterData.buildingMasterData.success == true) {
          this.setState(
            {
              buildingMasterData:
                camelcaseKeys(buildingMasterData.buildingMasterData.data) || [],
              reloadBuilding: !this.state.reloadBuilding,
            },
            () => {
              this.forceUpdate();
            }
          );
        } else {
          ShowToast(
            "Something went wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };
  onRowClick = (data) => {
    this.setState({
      openCompAddform: true,
      selectedTenantRowId: data.compId,
      ViewLeaseTitle: "Edit",
      actionLeaseType: "Edit",
      selectedBuildingId: data.buildingId,
      isLeaseInAddMode:false
      
    });
  };
  onSalesRowClick = (data) => {
    this.setState({
      openSalesCompAddform: true,
      isSaleInAddMode:false,
      selectedSaleRowId: data.compId,
      ViewSaleTitle: "Edit",
      actionSaleType: "Edit",
      selectedBuildingId: data.buildingId,            
    });
  };
  
  onAddNewClick = (data) => {
    this.setState({
      openCompAddform: true,
      selectedTenantRowId: -1,
      ViewLeaseTitle: "Add",
      actionLeaseType: "Add",
      selectedBuildingId: -1,
      isLeaseInAddMode:true
    });
  };
  onAddNewSalesCompClick= (data) => {
    this.setState({
      openSalesCompAddform: true,
      selectedSaleRowId: -1,
      ViewSaleTitle: "Add",
      actionSaleType: "Add",
      selectedBuildingId: -1,
      isSaleInAddMode:true
    });
  };

  validateExcelFile = (data, uploadedFileName) => {
    const uploadingKey = generateString(6);
    this.setState({ uploadingKey: uploadingKey });
    setDownloadingStatus(
      uploadingKey,
      uploadedFileName,
      "processing",
      "",
      "Processing import file"
    );
    this.setState({ upKey: uploadingKey, upfileName: uploadedFileName });
    this.setState({ breakCondition: false });
    let isSubletNull = false;
    let errorMessage = "";
    const workbook = XLSX.read(data, {
      type: "binary",
      cellDates: true,
      cellText: false,
    });
    const firstSheet = workbook.SheetNames[0];
    const excelRows = XLSX.utils.sheet_to_row_object_array(
      workbook.Sheets[firstSheet],
      { defval: null, raw: false, dateNF: "mm/dd/yyyy" }
    );
    let invalidCostar = false;
    const buildingData = this.state.getBuildingDetails;
    const CostarId = buildingData.filter(
      (x) => _.camelCase(x.dbColumn) === _.camelCase("CoStarPropID")
    );
   
    const excelData = excelRows.filter((m) => {     
      let t1 = parseInt(m.PropertyId);
      let t2 = parseInt(CostarId[0].attributeValue) 
      if (t1 != t2) {
        this.setState({ breakCondition: true });
        invalidCostar = true;
      }
      return m;
    });
    if (invalidCostar === true) {
      errorMessage =
        "Invalid CoStar ID found. Please re-import file containing only CoStar ID " + CostarId[0].attributeValue;
      setDownloadingStatus(
        uploadingKey,
        uploadedFileName,
        "failed",
        errorMessage,
        "Validation Failure"
      );
      return false;
    }
    let headers = [];

    if (excelData.length == 0) {
      this.setState({ breakCondition: true });
      //ShowToast("The import file does not contain a column with same market as selected for clear.Please re-import the file with correct value included", toastType.ERROR, 5000);
      errorMessage =
        "The import file is empty. Please upload file with valid data";
      setDownloadingStatus(
        uploadingKey,
        uploadedFileName,
        "failed",
        errorMessage,
        "Validation Failure"
      );
      return false;
    }
    
    excelData.forEach(function (e, i) {
      // Iterate over the keys of object
      Object.keys(e).forEach(function (key) {
        // Copy the value
        i == 0 ? headers.push(key) : null;       
      });
    });
    this.updateHeaderColumn(headers);
    let count = this.state.listOfColumns.length;
    let isColumnPresent = true;
    for (var i = 0; i < count; i++) {
      if (this.state.breakCondition != true)
        isColumnPresent = this.handleCheck(this.state.listOfColumns[i]);
      if (isColumnPresent == false) {
        this.setState({ breakCondition: true });
        //ShowToast("The import file does not contain a column specifiying the following data: [" + this.state.listOfColumns[i] + "].Please re-import the file with this column included", toastType.ERROR, 5000, 500);
        errorMessage =
          "The import file does not contain a column specifiying the following data: [" +
          this.state.listOfColumns[i] +
          "].Please re-import the file with this column included";

        setDownloadingStatus(
          uploadingKey,
          uploadedFileName,
          "failed",
          errorMessage,
          "Validation Failure"
        );
        return false;
      }
    }
    excelData.forEach((m) => {
      if (m.RBA == null && this.state.breakCondition == false) {
        this.setState({ breakCondition: true });
        //ShowToast("The import file does not contain rentable building areas for all imported buildings. Please re-import the file with RSF included for all buildings to be included in analysis.", toastType.ERROR, 5000, 500);

        errorMessage =
          "The import file does not contain rentable building areas for all imported buildings. Please re-import the file with RBA included for all buildings to be included in analysis.";
        setDownloadingStatus(
          uploadingKey,
          uploadedFileName,
          "failed",
          errorMessage,
          "Validation Failure"
        );
        return false;
      }
      if (m["Total Sublet Space (SF)"] == null) {
        m["Total Sublet Space (SF)"] = "0";
      }
      if (m["Total Vacant Available Relet Space (SF)"] == null) {
        m["Total Vacant Available Relet Space (SF)"] = "0";
      }
      if (m["Direct Available Space"] == null) {
        m["Direct Available Space"] = "0";
      }
      if (m["Direct Vacant Space"] == null) {
        m["Direct Vacant Space"] = "0";
      }
      if (m["Total Available Space (SF)"] == null) {
        m["Total Available Space (SF)"] = "0";
      }
    });

    if (this.state.breakCondition == false) {
     
      excelData.forEach((obj) => {
        if (this.state.breakCondition == false) {
          for (var i = 0; i < this.state.listOfDbColumn.length; i++) {
            let oldColName = this.state.listOfHeader[i];
            let newColName = this.getDbColumn(this.state.listOfHeader[i]);
            renameKey(obj, oldColName, newColName);

            if(this.state.getImportfileData[i].dataFormat=== "Date" || this.state.getImportfileData[i].dataFormat === "date")
            {
               let someDate = obj[this.state.listOfDbColumn[i]];
               var check = moment(someDate, "MM/DD/YYYY");
               var month = check.format("M");
               var finalImportDate = moment(someDate).startOf(month).format(DateFormatImport); //moment(someDate, "MM/01/YYYY");
               obj[this.state.listOfDbColumn[i]] = finalImportDate;            
            }

            let returnObj = checkFileValidation(
              obj[this.state.listOfDbColumn[i]],
              this.state.getImportfileData[i].dataFormat
            );
            if (returnObj === false) {
              //ValidationError=true;
              this.setState({ breakCondition: true });
              errorMessage =
                "Please fill valid data in field: [" +
                this.state.getImportfileData[i].uiColumn +
                "].Please re-import the file with valid data";
              setDownloadingStatus(
                uploadingKey,
                uploadedFileName,
                "failed",
                errorMessage,
                "Validation Failure"
              );
              return false;
            }
          }
          for (var i = 0; i < this.state.lstRequiredField.length; i++) {
            if (
              obj[this.state.lstRequiredField[i]] == null &&
              this.state.breakCondition == false
            ) {
              this.setState({ breakCondition: true });
              errorMessage =
                "The import file does not contain data for the following column: [" +
                this.getUIColumn(this.state.lstRequiredField[i]) +
                "].Please re-import the file with data for this column";
              setDownloadingStatus(
                uploadingKey,
                uploadedFileName,
                "failed",
                errorMessage,
                "Validation Failure"
              );
              return false;
            }
          }
        }
      });
     
      this.setState({
        formattedData: excelData,
      });
      if (this.state.isClearClicked != true) {
        //this.saveImport(excelData, uploadingKey, uploadedFileName);
      } else {
        this.setState({
          enableClear: true,
        });
      }
    }
    return true;
  };
  updateHeaderColumn = (headers) => {
    //const newList = headers.splice(headers.indexOf("ID"), 1);
    this.setState({
      listOfHeader: headers.sort(),
    });
  };

  handleCheck(val) {
    //return this.state.listOfHeader.some((item) => item === val);
    //return this.state.listOfHeader.includes(val);
    return this.state.listOfHeader.some(
      (item) => item.toLowerCase() == val.toLowerCase()
    );
  }
  getDbColumn(val) {
    let dbColumn = this.state.getImportfileData.find(
      (x) => x.uiColumn.toLowerCase() == val.toLowerCase()
    ).dbColumn;
    //return this.state.listOfHeader.some((item) => item === val);
    return dbColumn;
  }
  onFileUpload = (isClear = false) => {
    if (
      this.state.selectedFile == null ||
      this.state.selectedFile == undefined
    ) {
      ShowToast("Please select a file before upload.", toastType.ERROR, 5000);
    } else {
      // Create an object of formData
      const formData = new FormData();
      if (typeof FileReader !== "undefined") {
        const reader = new FileReader();
        if (reader.readAsBinaryString) {
          reader.onload = (e) => {
            if (
              this.validateExcelFile(
                reader.result,
                this.state.selectedFileName
              ) &&
              this.state.breakCondition == false
            ) {
              if (isClear == true) {
                this.ClearImportData();
              }
              this.getSpsData();
            }
          };
          reader.readAsBinaryString(this.state.selectedFile);
        }
      }
      this.setState({ isUpload: false, openDialog: false });
    }
  };

  getSpsData = () => {
    let env = getCurrentEnvironment().sharePointLibrary;
    if (getSPSTokenData()) {
      this.setState({ spsData: {} });
      getSpsAPiRequest(getSharePointUrl("streamrealty", "getFolder")).then(
        (res) => {
          const fileName = this.state.selectedFileName;
          const folderName = env;
          if (
            res.value !== null &&
            res.value.length > 0 &&
            res.value.find(
              (x) => x.displayName === folderName || x.name === folderName
            ) !== undefined
          ) {
            const folder = res.value.find(
              (x) => x.displayName === folderName || x.name === folderName
            );
            const driveId = folder.parentReference.driveId;
            const key = folder.id;
            this.setState({ spListkey: key });
            this.uploadFilesToSharePoint();
          } else {
            if (folderName.length > 0) {
              spsApiPost({
                url: getSharePointUrl("streamrealty", "createFolder"),
                postBody: {
                  displayName: folderName,
                  columns: [
                    {
                      name: "FileType",
                      text: {},
                    },
                    {
                      name: "UserEmail",
                      text: {},
                    },
                    {
                      name: "UserEmail",
                      text: {},
                    },
                  ],
                  list: {
                    template: "documentLibrary",
                  },
                },
              }).then((data) => {
                if (data) {
                  this.getSpsData();
                }
              });
            }
          }
        }
      );
    } else {
      apiRequest({ url: API_URL.powerBi.AcquireSPToken }).then(({ data }) => {
        if (data) {
          setSPSToken(data);
          this.getSpsData();
        }
      });
    }
  };

  
  uploadFilesToSharePoint() {
    //        this.state.filesToBeUploaded.forEach(x => {
    let d = new Date();
    let dformat = `${
      d.getMonth() + 1
    }${d.getDate()}${d.getFullYear()}_${d.getHours()}${d.getMinutes()}${d.getSeconds()}`;
    let fileName = this.state.selectedFileName.split(".")[0];
    let ext = this.state.selectedFileName.split(".")[1];
    fileName = fileName + "_" + dformat + "." + ext;
    spsApiPost({
      url: getSharePointUrl(
        "streamrealty",
        "uploadFileSession",
        this.state.spListkey,
        fileName,
        "MarketStats"
      ),
      postBody: {
        fileSize: this.state.selectedFileSize, //x.file.size,
        item: {
          "@microsoft.graph.conflictBehavior": "replace",
        },
      },
    }).then((data) => {
      if (data) {
        uploadSpsAPiRequest(
          data.data.uploadUrl,
          this.state.selectedFile,
          true
        ).then((data) => {
          if (data) {
            ShowToast(
              "File uploaded successfully on share point.",
              toastType.SUCCESS
            );
            this.setState({
              selectedFile: null,
              downloadFileUrl: data["@content.downloadUrl"],
            });
            // console.log(this.state.downloadFileUrl);
            if (
              this.state.breakCondition == false &&
              this.state.downloadFileUrl != null
            ) {
              this.saveImport(
                this.state.formattedData,
                this.state.uploadingKey,
                fileName
              );
            }
          } else {
            let errorMessage =
              "The import file could not be uploaded to the server.";
            setDownloadingStatus(
              this.state.uploadingKey,
              this.state.selectedFileName,
              "failed",
              errorMessage,
              "Processing Failure"
            );
          }
          //                this.props.onViewChange();
        });
      }
    });
    //        });
  }

  
  saveImport = (formattedData, uploadingKey, uploadedFileName) => {
    formattedData[formattedData.length] = this.state.downloadFileUrl;
    formattedData[formattedData.length] = uploadedFileName;
    apiPostNoLoader({
      url: API_URL.ImportExport.AddFileImportDetailIndividualIndustrialProperty,
      params: { marketId: this.state.selectedMarketId },
      postBody: formattedData,
    })
      .then(({ data: response }) => {
        if (response.success === true) {
          
          this.setState({
            importMonth: null,
            selectedFile: null,
            fileData: [],
            uploadingKey: null,
            uploadedFileName: null,
          });
          setDownloadingStatus(
            uploadingKey,
            uploadedFileName,
            "completed",
            "",
            "Data Import Complete"
          );
          this.getMarketAbsorptionReport()
          
        } else {
          setDownloadingStatus(
            uploadingKey,
            uploadedFileName,
            "failed",
            response.message,
            "Import Process Failed"
          );
        }
      })
      .catch(() => {
        setDownloadingStatus(
          uploadingKey,
          uploadedFileName,
          "failed",
          "",
          "Import Process Failed"
        );
      });
  };

  renderActionColumn = (cellInfo) => {
    return (
      <>
        <Tooltip title="View">
          <a
            className="default-text"
            aria-label={"refreshData"}
            size="small"
            onClick={() => {
              this.setState({
                isInAddMode: false,
              });
              this.onRowClick(cellInfo);
            }}
          >
            <span className="default-text span-link">
              {cellInfo.companyName ? cellInfo.companyName : "---"}
            </span>
          </a>
        </Tooltip>
      </>
    );
  };
  renderActionColumnSalesComp= (cellInfo) => {

    return (
      <>
        <Tooltip title="View">
          <a
            className="default-text"
            aria-label={"refreshData"}
            size="small"
            onClick={() => {
              this.setState({
                isInAddMode: false,
              });
              this.onSalesRowClick(cellInfo);
            }}
          >
            <span className="default-text span-link">
              {cellInfo.buyer ? cellInfo.buyer : "---"}
            </span>
          </a>
        </Tooltip>
      </>
    );
  };

  saveUpdatedRecord = () => {
    const newParam = this.state.updateMasterData;
    apiPost({
      url: API_URL.IndustrialBuilding.updateIndustrialReportPropertyAbsorptionAndVacancy,
      params: { businessLineId: 2 },
      postBody: newParam,
    }).then(({ data: response }) => {
      // debugger;
      if (response.success === true) {
        ShowToast(
          this.state.isInAddMode
            ? "Record added successfully."
            : "Record updated successfully.",
          toastType.SUCCESS,
          5000,
          500
        );

        this.setState({
          updateData: undefined,
          selectedEditRowId: undefined,
          reloadTable: !this.state.reloadTable,
          isInAddMode: false,
          showWarningMessage: false,
          confirmMessage: "",
        });
        this.getMarketAbsorptionReport();
        this.forceUpdate();
      } else {
        ShowToast(response.message, toastType.ERROR, 5000, 5000);
      }
    });
  };

  cancelEditMode = () => {
    this.setState({
      marketAbsorptionVacancyReport: JSON.parse(JSON.stringify([
        ...this.state.initialMarketAbsorptionReport])),
      selectedEditRowId: undefined,
      isInAddMode: false,
      updateMasterData: undefined,
      isEditable: false,
      reloadTable: !this.state.reloadTable
    });
    //this.getRequiredData();
  };

  cancelEditModeMoveIn = () => {
    this.setState({
      majorTenantMoveInReport:JSON.parse(JSON.stringify([
        ...this.state.initialMoveInReport])),
      selectedEditRowId: undefined,
      isInAddMode: false,
      updateMasterData: undefined,
    });
    //this.getRequiredData();
  };
  cancelEditModeMoveOut = () => {
    this.setState({
      majorTenantMoveOutReport:JSON.parse(JSON.stringify([
        ...this.state.initialMoveoutReport])),
      selectedEditRowId: undefined,
      isInAddMode: false,
      updateMasterData: undefined,
    });
    //this.getRequiredData();
  };


  enableDownload = () => {
    this.setState({
      openDialog: true,
    });
  };

  downloadTemplate = () => {
    //downloadExcelFile('src//components//Dashboard//PropertyOwnersExport.xlsx','MarketStats_ImportTemplate');
    downloadExcelFile(
      "public//ImportTemplate//IndPropertyMarketStats_ImportTemplate.xlsx",
      "IndPropertyMarketStats_ImportTemplate"
    );
  };
  importFile = () => {
    this.setState({ isUpload: true, openDialog: false });
  };
  onFileChange = (event) => {
    // Update the state

    this.setState({
      selectedFile: event.target.files[0],
      selectedFileName: event.target.files[0].name,
      selectedFileSize: event.target.files[0].size,
    });
    this.getImportFileDetails();
  };

  getImportFileDetails = () => {
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.ImportExport.getImportfileData,
        key: "getImportfileData",
        params: { sectionId: 248 },
        fromCache: true,
        duration: 60,
      },
    ])
      .then((getImportfileData) => {
        this._asyncRequest = null;
        if (getImportfileData.getImportfileData.success == true) {
          let temp = [];
          let dbColumn = [];
          let requiredColumn = [];
          getImportfileData.getImportfileData.data
            .sort((a, b) => (a.UIColumn > b.UIColumn ? 1 : -1))
            .filter((m) => {
              if (m.IsVisible == 1) {
                temp.push(m.UIColumn);
                dbColumn.push(m.DBColumn);
              }
              if (m.IsRequired == 1) {
                requiredColumn.push(m.DBColumn);
              }
            });

          this.setState({
            getImportfileData: camelcaseKeys(
              getImportfileData.getImportfileData.data
            ).sort((a, b) => (a.uiColumn > b.uiColumn ? 1 : -1)),
            listOfColumns: temp.sort(),
            listOfDbColumn: dbColumn,
            lstRequiredField: requiredColumn.sort(),
            
          });
          this.forceUpdate();
        } else {
          ShowToast(
            "Something Went Wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  renderActionColumnReports = (cellInfo) => {
    return (
      <>
        {this.state.selectedEditRowId !== undefined &&
          cellInfo.officeId === this.state.selectedEditRowId.value ? (
          <>
            <Tooltip title="Save Record">
              <IconButton
                aria-label={"refreshData"}
                size="small"
                onClick={() => { }}
              >
                <SaveIcon
                  fontSize="small"
                  size="small"
                  onClick={
                    //  this.state.isInAddMode
                    this.saveUpdatedRecord
                    //: this.updateRecord
                  }
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel">
              <IconButton
                aria-label={"refreshData"}
                size="small"
                onClick={this.cancelEditMode}
              >
                <CancelIcon fontSize="small" size="small" />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <>
            <Tooltip title="Edit Record">
              <IconButton
                aria-label={"refreshData"}
                size="small"
                onClick={() => {
                  this.setState({
                    selectedEditRowId: {
                      key: "officeId",
                      value: cellInfo.officeId,
                    },
                    isInAddMode: false,
                    updateMasterData: cellInfo,
                    isEditable: true,
                  });
                }}
              >
                <EditIcon fontSize="small" size="small" />
              </IconButton>
            </Tooltip>
          </>
        )}
      </>
    );
  };

  buildingOwnerChanges = () => { };

  setBuildingOwner = (value) => {
    let companyName = "";
    if (value.length > 0) {
      companyName = value.find(
        (x) => x.dbColumn == "CompanyName"
      ).attributeValue;
    }
    this.setState({
      selectedBuildingOwner: companyName,
      reloadBuilding: !this.state.reloadCompanyPopup,
    });
    this.state.getBuildingDetails.filter((x) => {
      if (x.dbColumn == "CompanyName") x.attributeValue = companyName;
    });
    this.forceUpdate();
  };
  onInputChange = (value) => {
    this.setState({ companyNameSelected: value });
    if (value.length > 2) {
      const params = {
        businessLineId: 2,
        searchText: value,
        //buildingId: this.state.selectedId,
      };
      //  apiRequestNoLoadder({
      //    url: API_URL.Master.buildingOwnerMasterDetails,
      //    params,
      //  })
      //    .then(({ data: companyMasterDetails }) => {
      //      this.setState(
      //        {
      //          companyMasterDetails: camelcaseKeys(companyMasterDetails) || [],
      //          reloadBuilding: !this.state.reloadBuilding,
      //        },
      //        () => {
      //          this.forceUpdate();
      //        }
      //      );
      //    })
      //    .catch((reason) => {
      //      this._asyncRequest = null;
      //      ShowToast(
      //        commonLabels.somethingWrong,
      //        toastType.ERROR,
      //        5000,
      //        500,
      //        "Server error"
      //      );
      //    });
      apiRequestNoLoadder({
        url: API_URL.Master.industrialBuildingMasterData,
        params,
      })
        .then(({ data: getBuildingMaster }) => {
          this.setState(
            {
              buildingSummaryData: camelcaseKeys(getBuildingMaster) || [],
            },
            () => {
              this.forceUpdate();
            }
          );
        })
        .catch((reason) => {
          this._asyncRequest = null;
          ShowToast(
            commonLabels.somethingWrong,
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        });
    }
  };

  handleTableEditChange = (value, fieldName, row, addMode) => {
    const param = this.state.updateMasterData;
    if (fieldName === "vacantSf") {
      param[fieldName] = parseInt(value);
    }
    if (fieldName === "directVacantSpace") {
      param[fieldName] = parseInt(value);
    }
    if (fieldName === "subletVacantSpace") {
      param[fieldName] = parseInt(value);
    }
    if (fieldName === "directAvailableSpace") {
      param[fieldName] = parseInt(value);
    }
    if (fieldName === "totalAvailableSpace") {
      param[fieldName] = parseInt(value);
    }
    if (fieldName === "subletSf") {
      param[fieldName] = parseInt(value);
    }
    if (fieldName === "note") {
      param[fieldName] = (value);
    }
    if (fieldName === "delivered") {
      param[fieldName] = (value);
    }
    this.setState({ updateMasterData: param });
    this.forceUpdate();
  };

  render() {
    const {
      //  getAllSourceSystem,
      getAllTenancy,
      tenantSummaryData,
      historicalSummaryData,
      getBuildingDetails,
      buildingSummaryData,
      isEditEnabled,
      companyMasterDetails,
      getMasterZip,
      getMarketMaster,
      getSubMarketMaster,
      getProductTypeMaster,
      getSprinklerData,
      //  getDistributionClassMaster,
      //  getBuildingParkMaster,
      //  getAgentMaster,
      getMaterialTypeMaster,
      buildingMasterData,
      // getBrokerMaster,
      getConstructionStatus,
      getHistory,
      getHistoryDataByUser,
      getHistoryDetailByField,
      isHistoryByField,
      reloadTable,
      marketAbsorptionVacancyReport,
      getSubmarketClusterMaster,
      vaccancyData,
      getBuildingSubtype,
      getState,
      getBuildingTier,
      propertyOwnerObjectDetails,
      compSetData,
      majorTenantMoveInReport,
      majorTenantMoveOutReport,
      getAllDockConfiguration
    } = this.state;

    const filterConfig = [
      {
        multiple: true,
        type: controlType.DropDown,
        minTag: 1,
        source: buildingSummaryData,
        key: "buildingName",
        placeholder: "",
        title: "Building",
      },
    ];
    const companySeparatorConfig = [
      {
        separatorBeforeKey: "BuildingName",
        order: 1,
        row: 1,
        sectionColumn: 3,
        label: "Key Property Details",
        sizeConfig: {
          labelSize: 7,
          controlSize: 5,
        },
      },
      // {
      // 	separatorBeforeKey: "IsStreamTracked",
      // 	order: 2,
      // 	row: 1,
      // 	sectionColumn: 12,
      // 	label: "",
      // 	sizeConfig: {
      // 		labelSize: 4,
      // 		controlSize: 3,
      // 	},
      // },
      //{
      //	separatorBeforeKey: "PropertyImage",
      //	order: 1,
      //	row: 2,
      //	sectionColumn: 1,
      //	label: "Stream Market Details",
      //},
      {
        separatorBeforeKey: "Add1",
        order: 1,
        row: 2,
        sectionColumn: 3,
        label: "Location Details",
      },
      {
        separatorBeforeKey: "PropertyOwnerUser",
        order: 1,
        row: 3,
        sectionColumn: 3,
        label: "Ownership & Management",
      },

      {
        separatorBeforeKey: "DockConfiguration",
        order: 1,
        row: 4,
        sectionColumn: 3,
        label: "Industrial Building Details",
      },
      {
        separatorBeforeKey: "YearBuilt",
        order: 1,
        row: 5,
        sectionColumn: 3,
        label: "Additional Asset Details",
      },
      {
        separatorBeforeKey: "AskingNetRentalRate",
        order: 1,
        row: 5,
        sectionColumn: 3,
        label: "Rent & Parking",
      },
      {
        separatorBeforeKey: "Leasing",
        order: 1,
        row: 5,
        sectionColumn: 3,
        label: "Stream Assignment Details",

      },
    ];

    const dropDownSource = [
      //  {
      //    key: "srcSysCode",
      //    option: "srcSysCode",
      //    idField: "sourceSystemId",
      //    source: getAllSourceSystem,
      //  },
      {
        key: "tenancy",
        option: "tenancy",
        idField: "tenancyTypeId",
        source: getAllTenancy,
      },
      {
        key: "companyName",
        option: "companyName",
        idField: "companyId",
        source: companyMasterDetails,
        AddNewOption: IsSectionVisible(
          SECTIONS.Company,
          SECTIONS.Company_Details,
          SECTIONS.ADD_EDIT
        ),
        AddNewOptiontitle: "New Company",
        AddOptionType: "Button",
        onAddClick: this.goToAddBuildingOwner,
        onChange: this.buildingOwnerChanges,
        onInputChange: this.onInputChange,

        value: this.state.selectedBuildingOwner,
      },
      {
        key: "zip",
        option: "zip",
        idField: "zipId",
        source: getMasterZip,
      },
      {
        key: "market",
        option: "market",
        idField: "marketId",
        source: getMarketMaster,
      },
      {
        key: "submarketCluster",
        option: "submarketCluster",
        idField: "submarketClusterId",
        source: getSubmarketClusterMaster,
      },
      {
        key: "subMarket",
        option: "subMarket",
        idField: "subMarketId",
        source: getSubMarketMaster,
        filterSourceOn: [{ key: "marketId", filterBy: "marketId" }],
        applyFilter: true,
      },
      {
        key: "productType",
        option: "productType",
        idField: "productTypeId",
        source: getProductTypeMaster,
      },
      {
        key: "buildingSubtype",
        option: "buildingSubtype",
        idField: "buildingSubtypeId",
        source: getBuildingSubtype,
        filterSourceOn: [{ key: "productTypeId", filterBy: "productTypeId" }],
        applyFilter: true,
      },
      {
        key: "state",
        option: "state",
        idField: "stateId",
        source: getState
      },
      {
        key: "buildingTier",
        option: "buildingTier",
        idField: "buildingTierId",
        source: getBuildingTier
      },

      {
        key: "buildingClass",
        option: "buildingClass",
        idField: "buildingClassId",
        source: buildingMasterData,
      },
      {
        key: "propertyOwnerUser",
        option: "propertyOwnerUser",
        idField: "propertyOwnerUserId",
        source: propertyOwnerObjectDetails,
        AddNewOption: IsSectionVisible(
          SECTIONS.Company,
          SECTIONS.Company_Details,
          SECTIONS.ADD_EDIT
        ),
        AddNewOptiontitle: "New Company",
        AddOptionType: "Button",
        onAddClick: this.goToAddBuildingOwner,
        onChange: this.buildingOwnerChanges,
        onInputChange: this.onInputChange,

        value: this.state.selectedPropertyOwner,
      },
      // {
      //   key: "propertyOwnerUser",
      //   option: "propertyOwnerUser",
      //   idField: "propertyOwnerUserId",
      //   source: getPropertyOwnerUserOptions,
      // },


      //  {
      //    key: "delegate1",
      //    option: "delegate1",
      //    idField: "delegateIdOne",
      //    source: getBrokerMaster,
      //  },
      //  {
      //    key: "delegate2",
      //    option: "delegate2",
      //    idField: "delegateIdTwo",
      //    source: getBrokerMaster,
      //  },
      {
        key: "existingUc",
        option: "existingUc",
        idField: "existingUcId",
        source: getConstructionStatus,
      },
      {
        key: "dockConfiguration",
        option: "dockConfiguration",
        idField: "dockConfigurationId",
        source: getAllDockConfiguration,
      },
      //   {
      //     key: 'serviceType',
      //     option: 'serviceType',
      //     idField: 'serviceTypeId',
      //     source: ServiceTypeMasterData
      //   }, {
      //     key: 'customerName',
      //     option: 'customerName',
      //     idField: 'customerId',
      //     source: customerMasterData
      //     // onChange: this.getSelectedTransitionDetails
      //   }
    ];

    const DetailsFormConfig = [
      {
        key: "srcSysCode",
        type: controlType.DropDown,
        multiple: false,
      },
      // {
      //   key: "companyName",
      //   type: controlType.TextBox,
      //   multiple: false,
      // },
      {
        key: "buildingName",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "add1",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "add2",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "zip",
        type: controlType.TextBox,
        multiple: false,
        //columnWidth:2
      },
      {
        key: "city",
        type: controlType.TextBox,
        multiple: false,
        //columnWidth:2
      },
      {
        key: "state",
        type: controlType.DropDown,
        multiple: false,
        //columnWidth:2
      },
      {
        key: "companyName",
        type: controlType.Autofill,
        multiple: false,
      },
      {
        key: "latitude",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "longitude",
        type: controlType.TextBox,
        multiple: true,
      },
      {
        key: "delegate1",
        type: controlType.DropDown,
        multiple: false,
      },
      {
        key: "delegate2",
        type: controlType.DropDown,
        multiple: false,
      },

      {
        key: "productType",
        type: controlType.DropDown,
        multiple: false,
      },
      {
        key: "buildingTier",
        type: controlType.DropDown,
        multiple: false,
      },

      {
        key: "buildingSubtype",
        type: controlType.DropDown,
        multiple: false,
      },
      {
        key: "buildingClass",
        type: controlType.DropDown,
        multiple: false,
      },
      {
        key: "market",
        type: controlType.DropDown,
        multiple: false,
      },
      {
        key: "submarketCluster",
        type: controlType.DropDown,
        multiple: false,
      },
      {
        key: "subMarket",
        type: controlType.DropDown,
        multiple: false,
      },
      {
        key: "PropertyOwnerUser",
        type: controlType.Autofill,
        multiple: false,
      },
      {
        key: "rsf",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "existingUc",
        type: controlType.DropDown,
        multiple: false,
      },
      {
        key: "monthDelivering",
        type: controlType.DatePicker,
        multiple: false,
      },
      {
        key: "since",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "yearBuilt",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "floorPlate",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "reno",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "ratio",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "walkscore",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "buildingNotes",
        type: controlType.TextArea,
        multiple: false,
      },
      {
        key: "leasing",
        type: controlType.Checkbox,
        multiple: false,
      },
      {
        key: "management",
        type: controlType.Checkbox,
        multiple: false,
      },
      {
        key: "owner",
        type: controlType.Checkbox,
        multiple: false,
      },
      {
        key: "onMarket",
        type: controlType.Checkbox,
        multiple: false,
      },
      {
        key: "isClient",
        type: controlType.Checkbox,
        multiple: false,
      },
      {
        key: "development",
        type: controlType.Checkbox,
        multiple: false,
      },
      {
        key: "keepMarketStats",
        type: controlType.Checkbox,
        multiple: false,
      },
      {
        key: "land",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "countyName",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "industrialType",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "tenancy",
        type: controlType.DropDown,
        multiple: false,
      },
      {
        key: "dockConfiguration",
        type: controlType.DropDown,
        multiple: false,
      },
      {
        key: "numberOfCranes",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "constructionBegin",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "parcelNumber1Min",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "parcelNumber2Max",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "propertyType",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "secondaryType",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "coStarSubmarket",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "coStarMarket",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "coStarBuildingClass",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "coStarBuildingStatus",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "numberOfStories",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "importFileName",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "trueOwnerExternalId",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "columnSpacing",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "driveIns",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "railLines",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "constructionMaterial",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "landAreaSF",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "documentName",
        type: controlType.FileInput,
        multiple: false,
      },
      {
        key: "terminationDocumentName",
        type: controlType.FileInput,
        multiple: false,
      },
      {
        key: "changeOfOwnershipDocumentName",
        type: controlType.FileInput,
        multiple: false,
      },
      {
        key: "isStreamTracked",
        type: controlType.Checkbox,
        multiple: false,
      },
      {
        key: "amenities",
        type: controlType.TextArea,
        multiple: false,
      },
      {
        key: "primaryLeasing",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "propertyManagement",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "rentalRateNote",
        type: controlType.TextArea,
        multiple: false,
      },
    ];
    var buildingNam = "";
    if (getBuildingDetails.length > 0) {
      buildingNam = getBuildingDetails.find(
        (x) => x.dbColumn == "BuildingName"
      ).attributeValue;
    }
    const buildingName =
      buildingNam != undefined ? buildingNam : this.props.ViewTitle;
    const defaultValue =
      getHistory.length > 0
        ? getHistory[0].createdBy +
        "," +
        moment(getHistory[0].createdDate).format("MM-DD-YYYY HH:mm")
        : null;
    return (
      <>
        {this.state.openHistoryform && (
          <Dialog open={this.state.openHistoryform} maxWidth="lg">
            <AppBar
              style={{ position: "relative", backgroundColor: "#064473" }}
            >
              <Toolbar className="custom-toolbar">
                <Typography
                  variant="subtitle1"
                  className="app-header-white"
                  style={{ marginTop: "15px", flex: "1", color: "#FFFFF" }}
                >
                  Update History
                </Typography>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {
                    this.setState({
                      openHistoryform: false,
                      isHistoryByField: false,
                    });
                  }}
                  aria-label="close"
                  style={{ padding: "0px" }}
                >
                  <IoMdCloseCircleOutline />
                </IconButton>
              </Toolbar>
            </AppBar>
            <div>
              <ViewHistoryData
                sourceData={getHistoryDataByUser}
                getHistoryDetailByField={getHistoryDetailByField}
                dropDownSource={dropDownSource}
                isHistoryByField={isHistoryByField}
              ></ViewHistoryData>
            </div>
          </Dialog>
        )}

        
{this.state.isUpload == true && (
          <Dialog open={this.state.isUpload} maxWidth="lg">
            <AppBar
              style={{
                position: "relative",
                backgroundColor: "#064473",
                height: "38px",
              }}
            >
              <div className="row">              
                <div
                  className="col-md-6 col-lg-6"
                  style={{ marginTop: "13px" }}
                >
                  <span> Upload File</span>
                </div>              
                <div className="col-md-6 col-lg-6">
                  <IconButton
                    style={{
                      float: "right",
                      marginTop: "-6px",
                      marginRight: "-8px",
                    }}
                    edge="start"
                    color="inherit"
                    onClick={() => {
                      this.setState({
                        isUpload: false,
                      });
                    }}
                    aria-label="close"
                  >
                    <IoMdCloseCircleOutline />
                  </IconButton>
                </div>
              </div>
            </AppBar>
            <div style={{ padding: "8px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingBottom: "5px !important",
                }}
                className="filter-label"
              >
                <label htmlFor="filled-hidden-label-small">Import File</label>
              </Box>
              <input
                type="file"
                style={{ padding: "24px", backgroundColor: "lightgray" }}
                onChange={this.onFileChange}
              />
            </div>
            <div style={{ marginRight: "10px" }}>
              <button className="import-button" onClick={this.onFileUpload}>
                Upload
              </button>
            </div>
          </Dialog>
        )}

{this.state.openDialog == true && (
                <Dialog open={this.state.openDialog} maxWidth="lg">
                  <AppBar
                    style={{ position: "relative", backgroundColor: "#064473" }}
                  >
                    <Toolbar>
                      <Typography
                        variant="subtitle1"
                        className="app-header-white"
                        style={{
                          marginLeft: "-11px",
                          flex: "1",
                          color: "#FFFFF",
                          marginTop: "-2px",
                        }}
                      >
                        Manage Data
                      </Typography>
                      <IconButton
                        edge="start"
                        color="inherit"
                        style={{ marginTop: "-18px", marginRight: "-24px" }}
                        onClick={() => {
                          this.setState({
                            openDialog: false,
                          });
                        }}
                        aria-label="close"
                      >
                        <IoMdCloseCircleOutline />
                      </IconButton>
                    </Toolbar>
                  </AppBar>
                  <div>
                    <DownloadTemplate
                       importData={true}
                      downloadTemplate={this.downloadTemplate}
                      importFile={this.importFile}
                      enableClear={this.enableClear}
                      onCancel={this.onCancel}
                      exportData={this.exportData}
                      hideExportClearButton={true}
                      showRefreshButton={false}
                      templateMessage={"Update Properties.Click"}
                    />
                  </div>
                </Dialog>
              )}


        {this.state.isDataSaved && this.state.isSearchedClicked && (
          <ConfirmDialog
            IsOpen={this.state.isSearchedClicked}
            Message={
              "There are unsaved changes .Do you still want to search another Company"
            }
            OnHandelClick={(isValid) => {
              if (isValid) {
                this.onClickHandel(isValid);
              } else {
                this.onClickHandel(isValid);
              }
            }}
            AgreeText="Yes"
            DisAgreeText="No"
          ></ConfirmDialog>
        )}

        {this.state.openCoStarConfirmDialog == true && (
          <ConfirmDialog
            IsOpen={this.state.openCoStarConfirmDialog}
            Message={
              "CoStar Id with following data [ Property :" +
              this.state.coStarData[0].property +
              ", Address : " +
              this.state.coStarData[0].address +
              "] already exist .Do you still want to map the comps with existing Property ?"
            }
            confirmData={this.state.coStarData}
            OnHandelClick={(isValid) => {
              if (isValid) {
                this.onClickHandelCostar(isValid);
              } else {
                this.onClickHandelCostar(isValid);
              }
            }}
            AgreeText="Accept"
            DisAgreeText="Cancel"
          ></ConfirmDialog>
        )}

        {this.state.openaddform && (
          <Dialog open={this.state.openaddform} maxWidth="lg">
            <AppBar
              style={{ position: "relative", backgroundColor: "#064473" }}
            >
              <Toolbar className="custom-toolbar">
                <Typography
                  variant="subtitle1"
                  className="app-header-white"
                  style={{ marginTop: "15px", flex: "1", color: "#FFFFF" }}
                >
                  {this.state.addformtitle}
                </Typography>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {
                    this.setState({ openaddform: false });
                    // this.reloadPropertyOwnerData();
                  }}
                  aria-label="close"
                  style={{ padding: "0px" }}
                >
                  <IoMdCloseCircleOutline />
                </IconButton>
              </Toolbar>
            </AppBar>
            <div>
              <EditIndBuildingOwners
                globalMarketId={this.props.globalMarketId}
                selectedBuildingId={this.state.selectedEditRowId}
                onBackClick={this.onPopupBackClick}
                ViewTitle={this.state.ViewTitle}
                actionType={this.state.actionType}
                isInAddMode={true}
                reloadData={this.reloadData}
                isRedirectedFromTenant={true}
                reloadPropertyOwnerData={this.reloadPropertyOwnerData}
                getSubMarketMaster={getSubMarketMaster}
                //	companyMasterDetails={companyMasterDetails}
                getMarketMaster={getMarketMaster}
                setBuildingOwner={this.setBuildingOwner}
              />
            </div>
          </Dialog>
        )}

        {isEditEnabled || this.props.isInAddMode ? (
          <div class="row header-container">
            <div className="col-md-4 col-lg-4">
              <PageHeader
                btnText={"Properties"}
                onBackClick={this.onBackClick}
                title={
                  this.props.isInAddMode
                    ? "/ " + this.props.ViewTitle
                    : "/ " + buildingName
                }
                style={{ color: "#00B0B9" }}
              ></PageHeader>
            </div>
            <div className="col-md-4 col-lg-4">
              {/* {this.state.showEdit == true && (
                  <AutoSearch
				  placeholder="Type at least 3 characters to Search another Property"
                    //placeholder="Search another Property"
                    isEditEnabled={this.state.isEditEnabled}
                    sourceData={this.state.buildingSummaryData}
                    filterConfig={filterConfig}
                    onSearch={this.onSearch}
					onInputChange={this.onInputChange}
					isRedirect={true}
                  ></AutoSearch>
                )} */}
              {/* <SearchBar></SearchBar> */}
            </div>
            <div className="col-md-4 col-lg-4 end-sm">
              {this.state.showEdit == true && (
                <>
                  <Button
                    size="small"
                    style={{ marginTop: "5px" }}
                    className="btn-clear"
                    onClick={() => {
                      this.setState({
                        getBuildingDetails: JSON.parse(
                          JSON.stringify([
                            ...this.state.getBuildingDetailsResponse,
                          ])
                        ),
                        reloadBuilding: !this.state.reloadBuilding,
                      });
                    }}
                  >
                    <span className="tool-icon-text">
                      {this.props.isInAddMode == true ? "Clear All" : "Undo"}
                    </span>
                  </Button>
                  <Button
                    size="small"
                    className="notop-action-button"
                    onClick={() => {
                      this.onBackClick();
                    }}
                  >
                    <span className="tool-icon-text">Cancel</span>
                  </Button>
                  {IsSectionVisible(
                    SECTIONS.Industrial_Property,
                    SECTIONS.Industrial_Property_Details,
                    SECTIONS.ADD_EDIT
                  ) && (
                      <Button
                        size="small"
                        className="notop-action-button"
                        onClick={() => {
                          this.saveBuildingDetails();
                        }}
                      >
                        <span className="tool-icon-text">Save</span>
                      </Button>
                    )}
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="row header-container">
            <div className="col-md-4 col-lg-4">
              <PageHeader
                btnText={"Properties"}
                onBackClick={this.onBackClick}
                title={"/ " + buildingName}
                style={{ color: "#00B0B9" }}
              ></PageHeader>
            </div>
            <div className="col-md-5 col-lg-5">
              {/* {this.state.showEdit == true && (
                  <AutoSearch
                    placeholder="Search another building"
                    isEditEnabled={this.state.isEditEnabled}
                    sourceData={this.state.buildingSummaryData}
                    filterConfig={filterConfig}
                    onSearch={this.onSearch}
					onInputChange={this.onInputChange}
					isRedirect={true}
                  ></AutoSearch>
                )} */}
              {/* <SearchBar></SearchBar> */}
            </div>

            <div className="col-md-3 col-lg-3 end-sm">
              {this.state.showEdit == true && (
                <>
                  <Button
                    size="small"
                    className="notop-action-button"
                    onClick={() => {
                      this.props.onBackClick();
                    }}
                  >
                    <span className="tool-icon-text">Cancel</span>
                  </Button>
                  {IsSectionVisible(
                    SECTIONS.Industrial_Property,
                    SECTIONS.Industrial_Property_Details,
                    SECTIONS.ADD_EDIT
                  ) && (
                      <Button
                        size="small"
                        className="notop-action-button"
                        onClick={() => {
                          this.enableEdit();
                        }}
                      >
                        <span className="tool-icon-text">Edit</span>
                      </Button>
                    )}
                </>
              )}
            </div>
          </div>
        )}

        <div className="row header-container">
          <div className="col-sm-10 col-lg-10 tabs-nomargin">
            {!this.props.isInAddMode && (
              <>
                <div>
                  <Tooltip title="Show Property">
                    {tenantSummaryData && (
                      <a
                        className={
                          this.state.showEdit
                            ? "tab-capsule selected"
                            : "tab-capsule"
                        } //"tab-capsule selected"
                        aria-label={"refreshData"}
                        size="small"
                        onClick={() => {
                          this.setState({
                            showTenant: false,
                            showEdit: true,
                            showHistoricalOwnership: false,
                            showVacancyReport: false,
                            showMarketAbsorptionReport: false,
                            showMajorTenantMoveIns: false,
                            showMajorTenantMoveOuts: false,
                            showPropertyOwner: false,
                            showCompSet: false
                          });
                        }}
                      >
                        Property
                      </a>
                    )}
                  </Tooltip>
                </div>
                <div>
                  <Tooltip title="Show Lease Comps">
                    {this.state.getBuildingDetails && (
                      <a
                        className={
                          this.state.showTenant
                            ? "tab-capsule selected"
                            : "tab-capsule"
                        } //"tab-capsule"
                        aria-label={"refreshData"}
                        size="small"
                        onClick={() => {
                          this.setState({
                            //tenantSummaryData: [],
                            showTenant: true,
                            showEdit: false,
                            showHistoricalOwnership: false,
                            showVacancyReport: false,
                            showMarketAbsorptionReport: false,
                            showMajorTenantMoveIns: false,
                            showMajorTenantMoveOuts: false,
                            showPropertyOwner: false,
                            showCompSet: false
                          });
                          (this.state.tenantSummaryData.length > 0 &&
                            this.state.isTenantsChanged == false) ||
                            this.state.isTenantsApiCalled == true
                            ? this.state.tenantSummaryData
                            : this.getTenantData(this.state.selectedId);
                        }}
                      >
                        Lease Comps
                      </a>
                    )}
                  </Tooltip>
                </div>
                <div>
                  <Tooltip title="Show Sales Comps">
                    {this.state.getBuildingDetails && (
                      <a
                        className={
                          this.state.showHistoricalOwnership
                            ? "tab-capsule selected"
                            : "tab-capsule"
                        } //"tab-capsule"
                        aria-label={"refreshData"}
                        size="small"
                        onClick={() => {
                          this.setState({
                            //historicalSummaryData: [],
                            showTenant: false,
                            showEdit: false,
                            showHistoricalOwnership: true,
                            showVacancyReport: false,
                            showMarketAbsorptionReport: false,
                            showMajorTenantMoveIns: false,
                            showMajorTenantMoveOuts: false,
                            showPropertyOwner: false,
                            showCompSet: false
                          });
                          (this.state.historicalSummaryData.length > 0 &&
                            this.state.isHistoricalOwnershipChanged == false) ||
                            this.state.isHistoricalOwnershipApiCalled == true
                            ? this.state.historicalSummaryData
                            : this.getHistoricalData(this.state.selectedId);
                        }}
                      >
                        Sales Comps
                      </a>
                    )}
                  </Tooltip>
                </div>
                {/* <div>
                  <Tooltip title="Show Comp Sets">
                    {this.state.getBuildingDetails && (
                      <a
                        className={
                          this.state.showCompSet
                            ? "tab-capsule selected"
                            : "tab-capsule"
                        } //"tab-capsule"
                        aria-label={"refreshData"}
                        size="small"
                        onClick={() => {
                          this.setState({
                            //historicalSummaryData: [],
                            showTenant: false,
                            showEdit: false,
                            showHistoricalOwnership: false,
                            showVacancyReport: false,
                            showMarketAbsorptionReport: false,
                            showMajorTenantMoveIns: false,
                            showMajorTenantMoveOuts: false,
                            showPropertyOwner: false,
                            showCompSet: true
                          });
                          (this.state.compSetData.length > 0 &&
                            this.state.isCompSetChanged == false) ||
                            this.state.isCompSetApiCalled == true
                            ? this.state.compSetData
                            : this.getCompSet(this.state.selectedId);
                        }}
                      >
                        Comp Sets
                      </a>
                    )}
                  </Tooltip>
                </div> */}
                <div>
                  <Tooltip title="Show Owner Details">
                    {this.state.getBuildingDetails && (
                      <a
                        className={
                          this.state.showPropertyOwner
                            ? "tab-capsule selected"
                            : "tab-capsule"
                        } //"tab-capsule"
                        aria-label={"refreshData"}
                        size="small"
                        onClick={() => {
                          this.setState({
                            //marketAbsorptionVacancyReport: [],
                            showTenant: false,
                            showEdit: false,
                            showHistoricalOwnership: false,
                            showVacancyReport: false,
                            showMarketAbsorptionReport: false,
                            showMajorTenantMoveIns: false,
                            showMajorTenantMoveOuts: false,
                            showPropertyOwner: true,
                            showCompSet: false
                          });
                        }}
                      >
                        Owner Details
                      </a>
                    )}
                  </Tooltip>
                </div>
                <div>
                  <Tooltip title="Show Market Absorption & Vacancy">
                    {this.state.getBuildingDetails && (
                      <a
                        className={
                          this.state.showMarketAbsorptionReport
                            ? "tab-capsule selected"
                            : "tab-capsule"
                        } //"tab-capsule"
                        aria-label={"refreshData"}
                        size="small"
                        onClick={() => {
                          this.setState({
                            //marketAbsorptionVacancyReport: [],
                            showTenant: false,
                            showEdit: false,
                            showHistoricalOwnership: false,
                            showVacancyReport: false,
                            showMarketAbsorptionReport: true,
                            showMajorTenantMoveIns: false,
                            showMajorTenantMoveOuts: false,
                            showPropertyOwner: false,
                            showCompSet: false
                          });
                          (this.state.marketAbsorptionVacancyReport.length >
                            0 &&
                            this.state.isMarketAbsorptionChanged == false) ||
                            this.state.isMarketAbsorptionApiCalled == true
                            ? this.state.marketAbsorptionVacancyReport
                            : this.getMarketAbsorptionReport();
                        }}
                      >
                        Market Absorption & Vacancy
                      </a>
                    )}
                  </Tooltip>
                </div>
                {/* <div>
                  <Tooltip title="Major Tenant Move-Ins">
                    {this.state.getBuildingDetails && (
                      <a
                        className={
                          this.state.showMajorTenantMoveIns
                            ? "tab-capsule selected"
                            : "tab-capsule"
                        } //"tab-capsule"
                        aria-label={"refreshData"}
                        size="small"
                        onClick={() => {
                          this.setState({
                            //marketAbsorptionVacancyReport: [],
                            showTenant: false,
                            showEdit: false,
                            showHistoricalOwnership: false,
                            showVacancyReport: false,
                            showMarketAbsorptionReport: false,
                            showMajorTenantMoveIns: true,
                            showMajorTenantMoveOuts: false,
                            showPropertyOwner: false,
                            showCompSet: false
                          });
                          (this.state.majorTenantMoveInReport.length >
                            0 &&
                            this.state.isTenantMoveInChanged == false) ||
                            this.state.isTenantMoveInApiCalled == true
                            ? this.state.majorTenantMoveInReport
                            : this.getMajorTenantMoveInsReport();
                        }}
                      >
                        Major Tenant Move-Ins
                      </a>
                    )}
                  </Tooltip>
                </div>
                <div>
                  <Tooltip title="Major Tenant Move-Outs">
                    {this.state.getBuildingDetails && (
                      <a
                        className={
                          this.state.showMajorTenantMoveOuts
                            ? "tab-capsule selected"
                            : "tab-capsule"
                        } //"tab-capsule"
                        aria-label={"refreshData"}
                        size="small"
                        onClick={() => {
                          this.setState({
                            //marketAbsorptionVacancyReport: [],
                            showTenant: false,
                            showEdit: false,
                            showHistoricalOwnership: false,
                            showVacancyReport: false,
                            showMarketAbsorptionReport: false,
                            showMajorTenantMoveIns: false,
                            showMajorTenantMoveOuts: true,
                            showPropertyOwner: false,
                            showCompSet: false
                          });
                          (this.state.majorTenantMoveOutReport.length >
                            0 &&
                            this.state.isTenantMoveOutChanged == false) ||
                            this.state.isTenantMoveOutApiCalled == true
                            ? this.state.majorTenantMoveOutReport
                            : this.getMajorTenantMoveOutsReport();
                        }}
                      >
                        Major Tenant Move-Outs
                      </a>
                    )}
                  </Tooltip>
                </div> */}

              </>
            )}
          </div>
          {getHistory.length > 0 &&
            isEditEnabled && ( //this.state.showEdit == true
              <>
                <div
                  className="col-md-2 col-lg-2 end-sm"
                  style={{ padding: "8px" }}
                >
                  <span>Last Modified:</span>
                </div>
                <div className="col-sm-3 col-lg-3 end-sm">
                  <Autocomplete
                    size="small"
                    key={`lastModifiedProperty:${getHistory[0].createdBy}`}
                    underlineStyle={{ display: "none" }}
                    //value={}
                    // defaultValue={"Select Record" }
                    defaultValue={{
                      createdBy: getHistory[0].createdBy,
                      createdDate: moment(getHistory[0].createdDate).format(
                        "MM-DD-YYYY HH:mm"
                      ),
                    }}
                    onClose={(e, val) => this.handleClose(e, val)}
                    onChange={(e, val) => this.handleChange(e, val)}
                    id="tags-standard"
                    options={getHistory}
                    getOptionLabel={(option) =>
                      option.createdBy +
                      "," +
                      moment(option.createdDate).format("MM-DD-YYYY HH:mm:ss")
                    }
                    renderInput={(params) => (
                      // <Box
                      //   sx={{ display: "flex", alignItems: "center" }}
                      //   className="filter-label"
                      // >
                      //   <label htmlFor="filled-hidden-label-small">
                      //
                      //   </label>
                      <TextField
                        key={`lastModifiedProp:${getHistory[0].createdBy}`}
                        {...params}
                        variant="outlined"
                        margin="normal"
                        defaultValue={{
                          createdBy: getHistory[0].createdBy,
                          createdDate: moment(getHistory[0].createdDate).format(
                            "MM-DD-YYYY HH:mm"
                          ),
                        }}
                        hiddenLabel
                      />
                      // </Box>
                    )}
                  />
                </div>
              </>
            )}
        </div>

        {getBuildingDetails && this.state.showEdit == true && (
          <div className="row table-list-container">
            <FormGenerator
              gridColumn={3}
              getHistoryDataByField={this.getHistoryDataByField}
              ReloadForm={this.state.reloadBuilding}
              formFields={UpdateFieldsWithConfiguration(
                getBuildingDetails,
                DetailsFormConfig
              )}
              dropDownSource={dropDownSource}
              validationMessages={[]}
              partialForm={true}
              saveChanges={this.getBuildingChanges}
              Editable={this.state.isEditEnabled}
              viewPDF={[]}
              formType={"Section"}
              separatorConfig={companySeparatorConfig}
              //otherChildsection={[
              //	{
              //	 	label: "",
              //	 	element: getBuildingDetails.length > 0 && (
              //			<div className="col-xs-12 col-sm-5">
              //				<ImageSlider></ImageSlider>
              //			</div>
              //	 	),
              //		order:1,
              //	 	row: 2,
              //		sectionColumn:1,
              //	}
              //]}
              sectionRowConfig={[
                //{ row: 1, column: 7 },
                { row: 1, column: 12 },
                //{ row: 2, column: 5 },
                { row: 2, column: 12 },
                { row: 3, column: 12 },
                { row: 4, column: 12 },
                { row: 5, column: 12 },
                { row: 6, column: 4 },
                //  {row:3,column:4,labelAlign:'alignText-Right'},
                //  {row:2,column:4,labelAlign:'alignText-Right'},
              ]}
            ></FormGenerator>
          </div>
        )}

        {this.state.openCompAddform && (
          <Dialog open={this.state.openCompAddform} maxWidth="lg">
            <AppBar
              style={{ position: "relative", backgroundColor: "#064473" }}
            >
              <Toolbar className="custom-toolbar">
                <Typography
                  variant="subtitle1"
                  className="app-header-white"
                  style={{ marginTop: "15px", flex: "1", color: "#FFFFF" }}
                >
                  Edit Lease Comp
                </Typography>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {
                    this.setState({
                      openCompAddform: false,
                    });
                    this.forceUpdate();
                  }}
                  aria-label="close"
                  style={{ padding: "0px" }}
                >
                  <IoMdCloseCircleOutline />
                </IconButton>
              </Toolbar>
            </AppBar>
            <div>
              <EditIndLeaseComps
                globalMarketId={this.props.globalMarketId}
                selectedCompId={this.state.selectedTenantRowId}
                onBackClick={this.onTenantBackClick}
                ViewTitle={this.state.ViewLeaseTitle}
                actionType={this.state.actionLeaseType}
                isInAddMode={this.state.isLeaseInAddMode}
                //reloadData={this.reloadData}
                isRedirectedFromTenantTab={true}
                getSubMarketMaster={getSubMarketMaster}
                getMarketMaster={getMarketMaster}
                reloadData={this.getTenantData}
              //getBuildingMaster={getBuildingMaster}
              // getALLNNNFS={getALLNNNFS}
              //getAllLeaseType={getAllLeaseType}
              //getAllCompType={getAllCompType}
              //leaseCompsSummaryData={leaseCompsSummaryData}
              //reloadPropertyData={this.reloadPropertyData}              
              getBuildingDetails={getBuildingDetails}
              />
            </div>
          </Dialog>
        )}
        
{this.state.openSalesCompAddform && (
          <Dialog open={this.state.openSalesCompAddform} maxWidth="lg">
            <AppBar
              style={{ position: "relative", backgroundColor: "#064473" }}
            >
              <Toolbar className="custom-toolbar">
                <Typography
                  variant="subtitle1"
                  className="app-header-white"
                  style={{ marginTop: "15px", flex: "1", color: "#FFFFF" }}
                >
                  Sales Comps
                </Typography>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {
                    this.setState({
                      openSalesCompAddform: false,
                    });
                    this.forceUpdate();
                  }}
                  aria-label="close"
                  style={{ padding: "0px" }}
                >
                  <IoMdCloseCircleOutline />
                </IconButton>
              </Toolbar>
            </AppBar>
            <div>
            <EditIndSalesComp
              globalMarketId={this.props.globalMarketId}
              selectedCompanyId={this.state.selectedSaleRowId}
              onBackClick={this.onSalesBackClick}
              getProductTypeMaster={getProductTypeMaster}
              ViewTitle={this.state.ViewSaleTitle}
              actionType={this.state.actionSaleType}
              isInAddMode={this.state.isSaleInAddMode}
              reloadData={this.reloadData}
              isRedirectedFromProperty={true}
              //brokerMasterDetails={brokerMasterDetails}
              // getAllBuyerDetails={brokerMasterDetails}
              // getAllSellerDetails={brokerSellerDetails}
              // getAllMSalesStatus={salesStatusData}
              //buildingMasterDetails={getBuildingMaster}
              //salesCompsSummaryData={this.state.initialSummaryData}
              spListkey={this.state.spListkey}
              onCancelClickAfterPopupAdd={this.onCancelClickAfterPopupAdd}
              getBuildingDetails={getBuildingDetails}
            />
            </div>
          </Dialog>
        )}
        {tenantSummaryData && this.state.showTenant == true && (
          <div className="row" style={{ marginTop: "0px" }}>
            <div className="col-sm-12 col-lg-12">
              <GridTable
                validationMessage={[]}
                columns={UpdateColumnWithConfiguration(
                  tenantColumnForDetailScreen,
                  this.state.configuration,
                  []
                )}
                renderActionColumn={this.renderActionColumn}
                reload={this.state.reloadTable}
                data={tenantSummaryData}
                AddNewRecord={{
                  action: this.onAddNewClick,
                  title: "Add Lease Comps",
                  visible: IsSectionVisible(
                    SECTIONS.Lease_Comps,
                    SECTIONS.Lease_Comp_Listing,
                    SECTIONS.ADD_EDIT
                  ),
                }}
              ></GridTable>
            </div>
          </div>
        )}

        {historicalSummaryData && this.state.showHistoricalOwnership == true && (
          <div className="row" style={{ marginTop: "0px" }}>
            <div className="col-sm-12 col-lg-12">
              <GridTable
                validationMessage={[]}
                columns={UpdateColumnWithConfiguration(
                  HistoricalOwnerColumns,
                  this.state.historicalConfiguration,
                  []
                )}
                renderActionColumn={this.renderActionColumnSalesComp}
                reload={this.state.reloadTable}
                data={historicalSummaryData}
                AddNewRecord={{
                  action: this.onAddNewSalesCompClick,
                  title: "Add Sales Comps",
                  visible: IsSectionVisible(
                    SECTIONS.Lease_Comps,
                    SECTIONS.Lease_Comp_Listing,
                    SECTIONS.ADD_EDIT
                  ),
                }}
              ></GridTable>
            </div>
          </div>
        )}

{marketAbsorptionVacancyReport &&
          this.state.showMarketAbsorptionReport == true && (
            <div className="row" style={{ marginTop: "0px" }}>
              <div className="col-sm-12 col-lg-12">
              <GridTable
                gridEditClass='editReportDense'
                validationMessage={[]}
                columns={UpdateColumnWithConfiguration(
                  marketAbsorptionReportColumns,
                  //[],
                  this.state.marketAbsorptionAndVacancyReportConfiguration,
                  []
                )}
                data={marketAbsorptionVacancyReport}
                renderActionColumn={this.renderActionColumnReports}
                selectedEditRowId={this.state.selectedEditRowId}
                isInAddMode={this.state.isInAddMode}
                EditSource={[]}
                handleTableEditChange={this.handleTableEditChange}
                updateData={this.state.updateMasterData}
                reload={this.state.reloadTable}
                isProperty={true}
                isEditable={this.state.isEditable}
                columnGrouping={propertyStatsColumnGrouping}
                additionalAction={{
                  action: this.enableDownload,
                  title: "Manage Data",
                  visible: IsSectionVisible(
                    SECTIONS.Property,
                    SECTIONS.Property_Listing,
                    SECTIONS.ADD_EDIT
                  ),
                }}
              ></GridTable>
              </div>
            </div>
          )}

        {compSetData && this.state.showCompSet == true && (
          <div className="row" style={{ marginTop: "0px" }}>
            <div className="col-sm-12 col-lg-12">
              <GridTable
                validationMessage={[]}
                columns={UpdateColumnWithConfiguration(
                  compSetColumns,
                  this.state.compSetConfiguration,
                  []
                )}
                reload={this.state.reloadTable}
                data={compSetData}
              ></GridTable>
            </div>
          </div>
        )}

        {majorTenantMoveInReport &&
          this.state.showMajorTenantMoveIns == true && (
            <div className="row" style={{ marginTop: "0px" }}>
              <div className="col-sm-12 col-lg-12">
                <GridTable
                  gridEditClass='editReportSpreadOut'
                  validationMessage={[]}
                  columns={UpdateColumnWithConfiguration(
                    MajorTenantMoveInsReportColumns,
                    //[],
                    this.state.MajorTenantMoveInsReportConfiguration,
                    []
                  )}
                  data={majorTenantMoveInReport}
                  renderActionColumn={this.renderActionColumnReportsMoveIn}
                  selectedEditRowId={this.state.selectedEditRowId}
                  isInAddMode={this.state.isInAddMode}
                  EditSource={[]}
                  handleTableEditChange={this.handleTableEditChangeMoveIn}
                  updateData={this.state.updateMasterDataMoveIn}
                  reload={this.state.reloadTable}
                  isProperty={true}
                  isEditable={this.state.isEditable}
                // additionalAction={{
                //   action: this.enableDownload,
                //   title: "Manage Data",
                //   visible: IsSectionVisible(
                //     SECTIONS.Property,
                //     SECTIONS.Property_Listing,
                //     SECTIONS.ADD_EDIT
                //   ),
                // }}
                ></GridTable>
              </div>
            </div>
          )}

        {majorTenantMoveOutReport &&
          this.state.showMajorTenantMoveOuts == true && (
            <div className="row" style={{ marginTop: "0px" }}>
              <div className="col-sm-12 col-lg-12">
                <GridTable
                  gridEditClass='editReportSpreadOut'
                  validationMessage={[]}
                  columns={UpdateColumnWithConfiguration(
                    MajorTenantMoveOutsReportColumns,
                    //[],
                    this.state.MajorTenantMoveOutsReportConfiguration,
                    []
                  )}
                  data={majorTenantMoveOutReport}
                  renderActionColumn={this.renderActionColumnReportsMoveOut}
                  selectedEditRowId={this.state.selectedEditRowId}
                  isInAddMode={this.state.isInAddMode}
                  EditSource={[]}
                  handleTableEditChange={this.handleTableEditChangeMoveOut}
                  updateData={this.state.updateMasterDataMoveOut}
                  reload={this.state.reloadTable}
                  isProperty={true}
                  isEditable={this.state.isEditable}
                // additionalAction={{
                //   action: this.enableDownload,
                //   title: "Manage Data",
                //   visible: IsSectionVisible(
                //     SECTIONS.Property,
                //     SECTIONS.Property_Listing,
                //     SECTIONS.ADD_EDIT
                //   ),
                // }}
                ></GridTable>
              </div>
            </div>
          )}

        {
          this.state.showPropertyOwner == true && (
            <div className="row" style={{ marginTop: "0px" }}>
              <div className="col-sm-12 col-lg-12">
                <PropertyOwnerObject buildingId={this.state.selectedId} marketId={this.props.globalMarketId}></PropertyOwnerObject>
              </div>
            </div>
          )}
        <div></div>
      </>
    );
  }
}
