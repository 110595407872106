import React from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core';
import { API_URL } from '../../../constants/api';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import { apiBatchRequest, apiPost, apiRequest } from '../../../utils/api_service';
import SaveIcon from '@material-ui/icons/Save';
import { ShowToast, toastType } from '../../../utils/toast-service';
import camelcaseKeys from "camelcase-keys";
import {
  isGridFormValid,
  IsSectionVisible,
  UpdateColumnWithConfiguration
} from '../../../utils/helper';
import GridTable from '../../common/Table/Table';
import { SECTIONS } from '../../../constants/appConstants';
import { userMarketMappingColumnData,propertyOwnerObjectColumn } from '../../../constants/MockData';
import _ from 'lodash';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmDialog from '../../common/Dailogbox/confirmDailog';
import SearchBar from '../../common/AutoSearch/SearchBar';
import PageHeader from '../../common/PageHeader';

class PropertyOwnerObject extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      propertyOwnerObjectData: [],
      isInAddMode: false,
      propertyOwnershipStatusData: [],
      configuration: [],
      companyMasterData: [],
      responseuserMarketData: [],
      getBuildingDetails:[],
      selectedEditRowId: undefined,
      updateData: {
        buildingId: null,
        companyId: null,
        notes: null,
        ownershipPercent: null,
        primaryOwner: false,
        propertyOwnerId: 0,
        propertyOwnershipStatusId: null
      },
      userMarketMappingColumn: _.clone(propertyOwnerObjectColumn),
      isDelete: false,
      showWarningMessage: false,
      confirmMessage: '',
      searchInput: "",
      buildingName:""
    };
  }

  componentDidMount () {
    
    this.getMasterData();
  }

  handleChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();

    });
  };

  globalSearch = () => {
    let { searchInput, propertyOwnershipStatusData } = this.state;  
    let searchData = this.state.responseuserMarketData;
    let filteredData = searchData.filter((value) => {     
      return (        
        (value.userEmail
        ? value.userEmail
            .toString()
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        : false)
      );
      // }
    });
    this.setState({ propertyOwnershipStatusData: filteredData });  
  };

  getMasterData = () => {
    this._asyncRequest = apiBatchRequest([
      {       
        url: API_URL.IndustrialBuilding.GetPropertyOwnerObject,
        key: "propertyOwnerObjectData",
        params: { buildingId: this.props.buildingId },
      },
      {
        url: API_URL.BuildingOwners.buildingOwnersSummaryData,        
        key: 'companyMasterData',
        params: { marketId: this.props.marketId ,companyRoleId:"1",defaultMode:0},        
        
      },
      {
        url: API_URL.Master.PropertyOwnershipStatus,
        key: 'propertyOwnershipStatusData',
        params: { businessLineId: 1 },
      },
      {
        url: API_URL.IndustrialBuilding.industrialBuildingDetail,
        key: "getBuildingDetails",
        params: {
          buildingID: this.props.buildingId,
          mode:
            this.props.isRedirectedFromReport != undefined ||
            this.props.isRedirectedFromReport != null
              ? 1
              : 0,
        },
      }
    ])
      .then(({ propertyOwnerObjectData, companyMasterData, propertyOwnershipStatusData,getBuildingDetails }) => {
        
        this._asyncRequest = null;
        if (propertyOwnerObjectData.success !== true) {
          ShowToast('Something went wrong', toastType.ERROR, 5000, 500, 'Server error');
        }      
        if (getBuildingDetails.success == true) {
          let bName = getBuildingDetails.data.find(
            (x) => x.dbColumn == "BuildingName"
          ).attributeValue;
          this.setState({buildingName:bName});
        }
        
        this.setState({
          propertyOwnerObjectData: camelcaseKeys(propertyOwnerObjectData.data) || [],
          propertyOwnershipStatusData: camelcaseKeys(propertyOwnershipStatusData.data) || [],
          configuration: camelcaseKeys(propertyOwnerObjectData.configuration) || [],
          companyMasterData: camelcaseKeys(companyMasterData.data) || [],
          responseuserMarketData: _.cloneDeep(camelcaseKeys(propertyOwnershipStatusData.data) || []),
        });
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  GetPropertyOwnerObjectData = () => {
    apiRequest({
      //url: API_URL.Building.GetPropertyOwnerObject,        
      url: API_URL.IndustrialBuilding.GetPropertyOwnerObject,
        params: { buildingId: this.props.buildingId },
    }).then(({ data: response }) => {
      
      this.setState({
        propertyOwnerObjectData: camelcaseKeys(response) || [],        
      });      
    });
  };

  cancelMode = () => {
    this.setState({ selectedEditRowId: undefined, isInAddMode: false, updateData: undefined });
  };

  isValidForm = () => {    
    let IsValid = true;
    const { updateData, userMarketMappingColumn } = this.state;    
    userMarketMappingColumn.forEach((ele) => {      
      isGridFormValid(updateData[ele.key], ele, updateData);
      if (ele.isInErrorState === true) {
        IsValid = false;
      }         
    });
    this.setState({ userMarketMappingColumn }, this.forceUpdate());
    console.log("IsValid", IsValid);
    return IsValid;
  };

  saveUpdatedRecord = () => {    
    
    if (!this.isValidForm()) { return false; } else {
      
      const newParam = this.state.updateData;
      const params = {
        buildingId: newParam.buildingId,
        companyId: newParam.companyId,
        notes: newParam.notes,
        ownershipPercent: newParam.ownershipPercent,
        primaryOwner: newParam.primaryOwner,
        propertyOwnerId: newParam.propertyOwnerId,
        propertyOwnershipStatusId: newParam.propertyOwnershipStatusId        
      };
      console.log(params);
      this.UpdatePropertyOwnerObject(params);
    };
  }

  UpdatePropertyOwnerObject = (params) => {    
    apiPost({
      url: API_URL.IndustrialBuilding.UpdatePropertyOwnerObject,
      params: {},
      postBody: params
    }).then(({ data: response }) => {
      if (response.success === true) {
        ShowToast(this.state.isInAddMode ? 'Record added successfully.' : 'Record updated successfully.', toastType.SUCCESS, 5000, 500);
        this.setState({
          updateData: undefined,
          selectedEditRowId: undefined,
          isInAddMode: false,
          showWarningMessage: false,
          confirmMessage: ''
        }, () => this.GetPropertyOwnerObjectData());
      } else {        
        ShowToast(response.message.replace("Error in SP-api.UpdatePropertyObjectOwner",""), toastType.ERROR, 5000, 5000);                                            

      }
    });
  };

  renderActionColumn = (cellInfo) => {
    return (
      <>
        {this.state.selectedEditRowId !== undefined && cellInfo.propertyOwnerId === this.state.selectedEditRowId.value
          ? (
            <>
              <Tooltip title="Save Record">
                <IconButton aria-label={'refreshData'} size="small">
                  <SaveIcon fontSize="small" size="small" onMouseDown={this.saveUpdatedRecord} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Cancel">
                <IconButton aria-label={'refreshData'} size="small" onClick={this.cancelMode}>
                  <CancelIcon fontSize="small" size="small" />
                </IconButton>
              </Tooltip>
            </>
            )
          : <>{
            // (IsSectionVisible(SECTIONS.CONFIGURATION, SECTIONS.CONFIGURE_USER_MARKET_PERMISSION, SECTIONS.ADD_EDIT)) && (
            <Tooltip title="Edit Record">
              <IconButton
                aria-label={'refreshData'}
                size="small"
                onClick={() => {
                  this.setState({ selectedEditRowId: { key: 'propertyOwnerId', value: cellInfo.propertyOwnerId }, isInAddMode: false, updateData: { ...cellInfo } });
                }}
              >
                <EditIcon fontSize="small" size="small" />
              </IconButton>
            </Tooltip>
          // )
          }
            {
            // (IsSectionVisible(SECTIONS.CONFIGURATION, SECTIONS.CONFIGURE_USER_MARKET_PERMISSION, SECTIONS.ADD_EDIT)) && (
              <Tooltip title="Edit Record">
                <IconButton
                  aria-label={'refreshData'}
                  size="small"
                  onClick={() => {
                    this.deleteRecord(cellInfo);
                  }}
                >
                  <DeleteIcon fontSize="small" size="small" />
                </IconButton>
              </Tooltip>
            // )
            }
          </>
        }
      </>
    );
  };

  /**
 * delete record
 */
  deleteRecord (cellInfo) {    
    this.setState({
      showWarningMessage: true,
      confirmMessage: 'Do you want to delete this record ?',
      updateData: cellInfo
    });
  };

  /**
 * handle dialog box click event
 * @param {*} e
 */
  onClickHandle (e) {
    alert(e);
    if (e) {
      const newParam = this.state.updateData;
      const params = {
        buildingId: -1,
        companyId: -1,
        notes: null,
        ownershipPercent: 0,
        primaryOwner: false,
        propertyOwnershipStatusId: -1,
        propertyOwnerId: newParam.propertyOwnerId
      };
      this.UpdatePropertyOwnerObject(params);
    } else {
      this.setState({
        showWarningMessage: false,
        confirmMessage: ''
      });
    }
  }



  handleTableEditChange = (value, fieldName, row, addMode) => {
    const { propertyOwnershipStatusData, propertyOwnerObjectData, userMarketMappingColumn, companyMasterData } = this.state;
    const param = { ...this.state.updateData };
    
    if (fieldName === 'companyName') {
      const company = companyMasterData.find((x) => x.companyName === value);
      param.companyId = (company || {}).companyId;      
      param[fieldName] = value.trim();

    } else if (fieldName === 'propertyOwnershipStatus' && value !== null) {
      const OwnershipStatus = propertyOwnershipStatusData.find((x) => x.propertyOwnershipStatus === value);
      param.propertyOwnershipStatusId = (OwnershipStatus || {}).propertyOwnershipStatusId;      
      param[fieldName] = value.trim();
    } else {
      param[fieldName] = value;
    }
    isGridFormValid(value, userMarketMappingColumn.find(x => x.key === fieldName), param);

    this.setState({ updateData: param });
  };

  AddNewRecord = () => {
    if (this.state.isInAddMode === true) {
      ShowToast('You can add one row at a time!', toastType.WARNING, 5000, 500);
      return false;
    }

    const rowNew = {
        buildingName:this.state.buildingName,
        buildingId: this.props.buildingId,
        companyId: -1,
        notes: null,
        ownershipPercent: 0,
        primaryOwner: false,
        propertyOwnerId: -1,
        propertyOwnershipStatusId: -1
    };
    this.setState({ isInAddMode: true, selectedEditRowId: { key: 'propertyOwnerId', value: -1 }, updateData: rowNew, userMarketMappingColumn: _.cloneDeep(propertyOwnerObjectColumn) });
  };

  render () {
    const { propertyOwnerObjectData, companyMasterData, configuration, propertyOwnershipStatusData, isInAddMode, selectedEditRowId, updateData, userMarketMappingColumn } = this.state;
    const EditSource = [];
    EditSource.push(
      { key: 'companyMasterData', data: (companyMasterData || []) },
      { key: 'propertyOwnerObjectData', data: propertyOwnerObjectData },
      {key:'propertyOwnershipStatusData', data: propertyOwnershipStatusData}
    );
    return (
      <>
        {this.state.showWarningMessage && (
          <ConfirmDialog
            IsOpen={this.state.showWarningMessage}
            Message={this.state.confirmMessage}
            OnHandelClick={(isValid) => { this.onClickHandle(isValid); }}
            AgreeText="Yes"
            DisAgreeText="No"
          ></ConfirmDialog>
        )}
        
        <Table
          aria-labelledby="tableMenuTitle"
          size={'small'}
          aria-label="configuration table"
        >
        
          <TableBody>
            <TableRow>
              <TableCell>
                <GridTable
                  columns={UpdateColumnWithConfiguration(userMarketMappingColumn, configuration, [])}
                  data={propertyOwnerObjectData}
                  config={userMarketMappingColumn}
                  renderActionColumn={this.renderActionColumn}
                  selectedEditRowId={selectedEditRowId}
                  isInAddMode={isInAddMode}
                  EditSource={EditSource}
                  handleTableEditChange={this.handleTableEditChange}
                  updateData={updateData}
                  isInline={true}
                  AddNewRecord={{ action: this.AddNewRecord, title: 'Add Owner', 
                  visible:true,
                  // visible: IsSectionVisible(SECTIONS.CONFIGURATION, SECTIONS.CONFIGURE_USER_MARKET_PERMISSION, SECTIONS.ADD_EDIT) 
                }}
                ></GridTable>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </>
    );
  };
}

export default PropertyOwnerObject;
