import React from "react";
import { SECTIONS } from "./appConstants";
import { getFormattedCellData } from "../utils/currency";
import { DarkTooltip } from "../components/common/material-tooltip";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { DATA_FORMAT, deviceDetection } from "./constants";

import { IsSectionVisible } from "../utils/helper";
import { keyColumn } from "react-datasheet-grid";
const { isMobile } = deviceDetection;

export const companyColumns = [
  // {
  //   name: '#',
  //   key: 'serial',
  //   type: 'Child',
  //   isRequired: true,
  //   isVisible: true,

  // },
  {
    name: "Company Name",
    key: "companyName",
    type: "Child",
    isRequired: true,
    isVisible: true,
    renderComponent: "renderActionColumn",
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Address",
    key: "address",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Market",
    key: "market",
    isRequired: true,
    isVisible: true,
    minTag: 1,
    editConfig: {
      type: "DropDown",
      isEditField: false,
      isAddField: false,
      multiple: true,
      delimiter: "|",
    },
  },
  {
    name: "Sub Market",
    key: "subMarket",
    isRequired: true,
    isVisible: true,
    minTag: 1,
    editConfig: {
      type: "DropDown",
      isEditField: true,
      isAddField: true,
      multiple: true,
      delimiter: "|",
    },
  },
  {
    name: "Owner Type",
    key: "companyRole",
    idField: "companyRoleId",
    type: "Child",
    isRequired: true,
    isVisible: true,
    minTag: 1,
    tagCount: true,
    editConfig: {
      type: "DropDown",
      isEditField: false,
      isAddField: false,
      multiple: true,
      delimiter: "|",
    },
  },
  {
    name: "Product Type",
    key: "productType",
    idField: "productTypeId",

    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "DropDown",
      isEditField: false,
      isAddField: false,
      multiple: true,
      delimiter: "|",
    },
  },
  {
    name: "Industry",
    key: "industryName",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "DropDown",
      isEditField: false,
      isAddField: false,
    },
  },
  // {
  //   name: 'IndustrySelector',
  //   key: 'industrySelector',
  //   type: 'Child',
  //   isRequired: true,
  //   isVisible: true,
  //   editConfig: {
  //     type: 'TextField',
  //     isEditField: false,
  //     isAddField: false,
  //   }
  // },
  // {
  //   name: 'Details',
  //   key: 'CompanyId',
  //   type: 'Child',
  //   renderComponent: 'renderActionColumn'
  // },
];
export const buildingColumnsBulkUpload = [
  {
    name: "",
    key: "selectAll",
    type: "Child",
    renderComponent: "renderActionColumn",
    renderControls: "renderControlsColumn",
    minWidth: 80,
    editConfig: {
      type: "Checkbox",
      isEditField: true,
      isAddField: true,
      toolTip1: "Select All",
      toolTip2: "Deselect All",
      name: "Building",
    },
  },
  {
    name: "POI",
    key: "IsStreamTracked",
    isVisible: true,
  },
  {
    name: "Keep Market Stats",
    key: "KeepMarketStats",
    type: "Child",
    isRequired: true,
    isVisible: true,
  },
  {
    name: "Stream Building Class",
    key: "StreamBuildingClass",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },

  {
    name: "Stream Submarket",
    key: "SubMarket",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "CoStar ID",
    key: "CoStarPropID",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "RSF",
    key: "RSF",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat: "Number",
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
];

export const buildingColumnsConfig = {
  showPager: false,
};
export const buildingOwnerColumns = [
  // {
  //   name: '#',
  //   key: 'serial',
  //   type: 'Child',
  //   isRequired: true,
  //   isVisible: true,

  // },
  {
    name: "Company Name",
    key: "companyName",
    type: "Child",
    isRequired: true,
    isVisible: true,
    renderComponent: "renderActionColumn",
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Address",
    key: "address",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Market",
    key: "market",
    isRequired: true,
    isVisible: true,
    minTag: 1,
    editConfig: {
      type: "DropDown",
      isEditField: false,
      isAddField: false,
      multiple: true,
      delimiter: "|",
    },
  },
  {
    name: "Sub Market",
    key: "subMarket",
    isRequired: true,
    isVisible: true,
    minTag: 1,
    editConfig: {
      type: "DropDown",
      isEditField: true,
      isAddField: true,
      multiple: true,
      delimiter: "|",
    },
  },
  {
    name: "Owner Type",
    key: "companyRole",
    idField: "companyRoleId",
    type: "Child",
    isRequired: true,
    isVisible: true,
    minTag: 1,
    tagCount: true,
    editConfig: {
      type: "DropDown",
      isEditField: false,
      isAddField: false,
      multiple: true,
      delimiter: "|",
    },
  },
  {
    name: "Product Type",
    key: "productType",
    idField: "productTypeId",

    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "DropDown",
      isEditField: false,
      isAddField: false,
      multiple: true,
      delimiter: "|",
    },
  },
  // {
  //   name: 'Industry',
  //   key: 'IndustryName',
  //   type: 'Child',
  //   isRequired: true,
  //   isVisible: true,
  //   editConfig: {
  //     type: 'DropDown',
  //     isEditField: false,
  //     isAddField: false,
  //   }
  // },
  // {
  //   name: 'IndustrySelector',
  //   key: 'industrySelector',
  //   type: 'Child',
  //   isRequired: true,
  //   isVisible: true,
  //   editConfig: {
  //     type: 'TextField',
  //     isEditField: false,
  //     isAddField: false,
  //   }
  // },
  // {
  //   name: 'Details',
  //   key: 'CompanyId',
  //   type: 'Child',
  //   renderComponent: 'renderActionColumn'
  // },
];
export const buildingSalesCompsColumns = [
  // {
  //   name: '#',
  //   key: 'serial',
  //   type: 'Child',
  //   isRequired: true,
  //   isVisible: true,

  // },
  // {
  //   name: "",
  //   key: "selectAll",
  //   type: "Child",
  //   renderComponent: "renderActionColumn",
  //   renderControls: "renderControlsColumn",
  //   minWidth: 80,
  //   editConfig: {
  //     type: "Checkbox",
  //     isEditField: true,
  //     isAddField: true,
  //     toolTip1: "Select All",
  //     toolTip2: "Deselect All",
  //     name: "Building",
  //   },
  // },
  {
    name: "Buyer Company",
    key: "buyer",
    // type: 'Child',
    // isRequired: true,
    isVisible: true,
    renderComponent: 'renderActionColumn',
    // editConfig: {
    //   type: 'TextField',
    //   isEditField: true,
    //   isAddField: true,
    // }
  },
  {
    name: "Property Name",
    key: "buildingName",
    type: "Child",
    isRequired: true,
    isVisible: true,
    // editConfig: {
    //   type: 'TextField',
    //   isEditField: false,
    //   isAddField: false,
    // }
  },

  {
    name: "Seller Company",
    key: "seller",
    // type: 'Child',
    // isRequired: true,
    isVisible: true,
    // renderComponent: 'renderActionColumn',
    // editConfig: {
    //   type: 'TextField',
    //   isEditField: true,
    //   isAddField: true,
    // }
  },
  {
    name: "Size(RSF)",
    key: "rsf",
    type: "Child",
    isRequired: true,
    isVisible: true,
    // editConfig: {
    //   type: "TextField",
    //   isEditField: false,
    //   isAddField: false,
    // },
  },
  {
    name: "Rate At LCD",
    key: "rateAtLcd",
    type: "Child",
    isRequired: true,
    isVisible: true,
    // editConfig: {
    //   type: "TextField",
    //   isEditField: true,
    //   isAddField: true,
    // },
  },
  {
    name: "Date Sold",
    key: "dateSold",
    type: "Child",
    isRequired: true,
    isVisible: true,
    // editConfig: {
    //   type: "TextField",
    //   isEditField: false,
    //   isAddField: false,
    // },
  },
  {
    name: "Notes",
    key: "notes",
    type: "Child",
    isRequired: true,
    isVisible: true,
    // editConfig: {
    //   type: "TextField",
    //   isEditField: true,
    //   isAddField: true,
    // },
  }
];

export const compSetColumns = [
  // {
  //   name: '#',
  //   key: 'serial',
  //   type: 'Child',
  //   isRequired: true,
  //   isVisible: true,

  // },
  // {
  //   name: "",
  //   key: "selectAll",
  //   type: "Child",
  //   renderComponent: "renderActionColumn",
  //   renderControls: "renderControlsColumn",
  //   minWidth: 80,
  //   editConfig: {
  //     type: "Checkbox",
  //     isEditField: true,
  //     isAddField: true,
  //     toolTip1: "Select All",
  //     toolTip2: "Deselect All",
  //     name: "Building",
  //   },
  // },
  {
    name: "Comp Set Name",
    key: "compSetName",
    // type: 'Child',
    // isRequired: true,
    isVisible: true,
    // renderComponent: 'renderActionColumn',
    // editConfig: {
    //   type: 'TextField',
    //   isEditField: true,
    //   isAddField: true,
    // }
  },
  {
    name: "Comp Set Description",
    key: "description",
    type: "Child",
    isRequired: true,
    isVisible: true,
    // editConfig: {
    //   type: 'TextField',
    //   isEditField: false,
    //   isAddField: false,
    // }
  },
  {
    name: "Created By",
    key: "compSetCreatedBy",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Created Date",
    key: "compSetCreatedDate",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Modified By",
    key: "compSetModifiedBy",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Modified Date",
    key: "compSetModifiedDate",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  }
];

export const buildingColumns = [
  // {
  //   name: '#',
  //   key: 'serial',
  //   type: 'Child',
  //   isRequired: true,
  //   isVisible: true,

  // },
  {
    name: "",
    key: "selectAll",
    type: "Child",
    renderComponent: "renderActionColumn",
    renderControls: "renderControlsColumn",
    minWidth: 80,
    editConfig: {
      type: "Checkbox",
      isEditField: true,
      isAddField: true,
      toolTip1: "Select All",
      toolTip2: "Deselect All",
      name: "Building",
    },
  },
  {
    name: "Property",
    key: "buildingName",
    // type: 'Child',
    // isRequired: true,
    isVisible: true,
    // renderComponent: 'renderActionColumn',
    // editConfig: {
    //   type: 'TextField',
    //   isEditField: true,
    //   isAddField: true,
    // }
  },
  {
    name: "Address",
    key: "address",
    type: "Child",
    isRequired: true,
    isVisible: true,
    // editConfig: {
    //   type: 'TextField',
    //   isEditField: false,
    //   isAddField: false,
    // }
  },
  {
    name: "CoStar Class",
    key: "coStarBuildingClass",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "CoStar Market",
    key: "coStarMarket",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "CoStar Sub Market",
    key: "coStarSubmarket",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "CoStar ID",
    key: "coStarPropId",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "RSF",
    key: "rsf",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat: "Number",
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Vacancy(%)",
    key: "vacancy",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Owner / Client",
    key: "companyName",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Zip",
    key: "zip",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  // {
  //   name: 'Details',
  //   key: 'BuildingId',
  //   type: 'Child',
  //   renderComponent: 'renderActionColumn'
  // },
];

export const buildingColumnsForDetail = [
  // {
  //   name: '#',
  //   key: 'serial',
  //   type: 'Child',
  //   isRequired: true,
  //   isVisible: true,

  // },
  {
    name: "Property",
    key: "buildingName",
    type: "Child",
    isRequired: true,
    isVisible: true,
    renderComponent: "renderActionColumn",
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Street Address",
    key: "address",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Stream Building Class",
    key: "buildingClass",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Stream Market",
    key: "market",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Stream Submarket",
    key: "subMarket",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "CoStar ID",
    key: "coStarPropId",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "RSF",
    key: "rsf",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat: "Number",
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Vacancy(%)",
    key: "vacancy",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Owner / Client",
    key: "companyName",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Properties Of Interest",
    key: "isStreamTracked",
    type: "Child",
    dataFormat: "Boolean",
    isRequired: true,
    isVisible: true,    
    editConfig: {
      type: "CheckBox",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Keep Market Stats",
    key: "keepMarketStats",
    type: "Child",
    dataFormat: "Boolean",
    isRequired: true,
    isVisible: true,    
    editConfig: {
      type: "CheckBox",
      isEditField: true,
      isAddField: true,
    },
  },
  // {
  //   name: 'Details',
  //   key: 'BuildingId',
  //   type: 'Child',
  //   renderComponent: 'renderActionColumn'
  // },
];

export const tenantColumns = [
  // {
  //   name: '#',
  //   key: 'serial',
  //   type: 'Child',
  //   isRequired: true,
  //   isVisible: true,

  // },
  {
    name: "Tenant Name",
    key: "companyName",
    type: "Child",
    isRequired: true,
    isVisible: true,
    renderComponent: "renderActionColumn",
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Property Name",
    key: "buildingName",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },

  {
    name: "RSF",
    key: "rsf",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Market",
    key: "market",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Sub Market",
    key: "subMarket",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "LCD",
    key: "lcd",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat: "Date",
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Type",
    key: "productType",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "LX Date",
    key: "lxDte",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat: "Date",
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Rate At LCD",
    key: "rateAtLcd",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
];

export const tenantColumnForDetailScreen = [
  // {
  //   name: '#',
  //   key: 'serial',
  //   type: 'Child',
  //   isRequired: true,
  //   isVisible: true,

  // },
  {
    name: "Tenant Name",
    key: "companyName",
    type: "Child",
    isRequired: true,
    isVisible: true,
    renderComponent: "renderActionColumn",
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Property Name",
    key: "buildingName",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },

  {
    name: "RSF",
    key: "rsf",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Stream Market",
    key: "market",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },

  {
    name: "Stream Submarket",
    key: "subMarket",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Lease Commencement Date",
    key: "lcd",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat: "Date",
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Lease Expiration Date",
    key: "lxDte",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat: "Date",
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
];

export const industrialTenantColumns = [
  // {
  //   name: '#',
  //   key: 'serial',
  //   type: 'Child',
  //   isRequired: true,
  //   isVisible: true,

  // },
  {
    name: "Tanant Name",
    key: "companyName",
    type: "Child",
    isRequired: true,
    isVisible: true,
    renderComponent: "renderActionColumn",
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Property Name",
    key: "buildingName",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },

  {
    name: "RSF",
    key: "rsf",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Market",
    key: "market",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Sub Market",
    key: "subMarket",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "LCD",
    key: "lcd",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat: "Date",
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Type",
    key: "productType",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "LX Date",
    key: "lxDte",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat: "Date",
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Rate At LCD",
    key: "rateAtLcd",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
];

export const leaseCompsColumns = [
  // {
  //   name: '#',
  //   key: 'serial',
  //   type: 'Child',
  //   isRequired: true,
  //   isVisible: true,

  // },
  {
    name: "Tanant Name",
    key: "companyName",
    type: "Child",
    isRequired: true,
    isVisible: true,
    renderComponent: "renderActionColumn",
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Property Name",
    key: "buildingName",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "RSF",
    key: "rsf",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Market",
    key: "market",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Sub Market",
    key: "subMarket",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "LCD",
    key: "lcd",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat: "Date",
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Industry",
    key: "industryName",
    type: "Child",
    isRequired: true,
    isVisible: true,
    minTag: 1,
    editConfig: {
      type: "DropDown",
      isEditField: false,
      isAddField: false,
      multiple: true,
      delimiter: "|",
    },
  },
  {
    name: "Comp Type",
    key: "compType",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Rate",
    key: "rate",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat: "Number",
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Class",
    key: "buildingClass",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Lease Type",
    key: "leaseType",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
];

export const leaseCompsColumnsForDetails = [
  // {
  //   name: '#',
  //   key: 'serial',
  //   type: 'Child',
  //   isRequired: true,
  //   isVisible: true,

  // },
  {
    name: "Tenant Name",
    key: "companyName",
    type: "Child",
    isRequired: true,
    isVisible: true,
    // renderComponent: 'renderActionColumn',
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Property",
    key: "buildingName",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "SF Leased",
    key: "rsf",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Stream Market",
    key: "market",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Stream Submarket",
    key: "subMarket",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Lease Commencement Date",
    key: "lcd",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat: "Date",
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Industry",
    key: "industryName",
    type: "Child",
    isRequired: true,
    isVisible: true,
    minTag: 1,
    editConfig: {
      type: "DropDown",
      isEditField: false,
      isAddField: false,
      multiple: true,
      delimiter: "|",
    },
  },
  {
    name: "Comp Type",
    key: "compType",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Rate($/SF/Yr)",
    key: "rate",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat: "Number",
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Stream Building Class",
    key: "buildingClass",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Lease Type",
    key: "leaseType",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
];

export const industrialLeaseCompsColumns = [
  // {
  //   name: '#',
  //   key: 'serial',
  //   type: 'Child',
  //   isRequired: true,
  //   isVisible: true,

  // },
  {
    name: "Tanant Name",
    key: "companyName",
    type: "Child",
    isRequired: true,
    isVisible: true,
    renderComponent: "renderActionColumn",
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Property Name",
    key: "buildingName",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "RSF",
    key: "rsf",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Market",
    key: "market",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Sub Market",
    key: "subMarket",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "LCD",
    key: "lcd",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat: "Date",
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Industry",
    key: "industryName",
    type: "Child",
    isRequired: true,
    isVisible: true,
    minTag: 1,
    editConfig: {
      type: "DropDown",
      isEditField: false,
      isAddField: false,
      multiple: true,
      delimiter: "|",
    },
  },
  {
    name: "Comp Type",
    key: "compType",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Rate",
    key: "rate",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat: "Number",
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Class",
    key: "buildingClass",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Lease Type",
    key: "leaseType",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
];

export const salesCompsColumns = [
  // {
  //   name: '#',
  //   key: 'serial',
  //   type: 'Child',
  //   isRequired: true,
  //   isVisible: true,

  // },
  {
    name: "Buyer",
    key: "buyer",
    type: "Child",
    isRequired: true,
    isVisible: true,
    renderComponent: "renderActionColumn",
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Property Name",
    key: "buildingName",
    type: "Child",
    isRequired: true,
    isVisible: true,   
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "RSF",
    key: "rsf",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "Number",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Market",
    key: "market",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Sub Market",
    key: "subMarket",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },

  {
    name: "Seller",
    key: "seller",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Sales Status",
    key: "salesStatus",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Cap Rate",
    key: "capRate",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat: "Decimal",
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Price(RSF)",
    key: "salesPsf",
    type: "Child",
    isRequired: true,
    isVisible: true,
    minTag: 1,
    dataFormat: "MoneyNumber",
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Comp Type",
    key: "compType",
    type: "Child",
    isRequired: true,
    isVisible: true,
    minTag: 1,
    editConfig: {
      type: "DropDown",
      isEditField: false,
      isAddField: false,
      multiple: true,
      delimiter: "|",
    },
  },
];

export const HistoricalOwnerColumns = [
  // {
  //   name: '#',
  //   key: 'serial',
  //   type: 'Child',
  //   isRequired: true,
  //   isVisible: true,

  // },
  {
    name: "Buyer",
    key: "buyer",
    type: "Child",
    isRequired: true,
    isVisible: true,
    renderComponent: 'renderActionColumn',
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Property Name",
    key: "buildingName",
    type: "Child",
    isRequired: true,
    isVisible: true,
   
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "RSF",
    key: "rsf",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "Number",
      isEditField: false,
      isAddField: false,
    },
  },

 
  {
    name: "Seller",
    key: "seller",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Date Sold",
    key: "dateSold",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat: "Date",
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
];

export const industrialBuildingColumns = [
  // {
  //   name: '#',
  //   key: 'serial',
  //   type: 'Child',
  //   isRequired: true,
  //   isVisible: true,

  // },
  {
    name: "",
    key: "selectAll",
    type: "Child",
    renderComponent: "renderActionColumn",
    renderControls: "renderControlsColumn",
    minWidth: 80,
    editConfig: {
      type: "Checkbox",
      isEditField: true,
      isAddField: true,
      toolTip1: "Select All",
      toolTip2: "Deselect All",
      name: "Building",
    },
  },
  {
    name: "Property",
    key: "buildingName",
    // type: 'Child',
    // isRequired: true,
    isVisible: true,
    // renderComponent: 'renderActionColumn',
    // editConfig: {
    //   type: 'TextField',
    //   isEditField: true,
    //   isAddField: true,
    // }
  },
  {
    name: "Address",
    key: "address",
    type: "Child",
    isRequired: true,
    isVisible: true,
    // editConfig: {
    //   type: 'TextField',
    //   isEditField: false,
    //   isAddField: false,
    // }
  },
  {
    name: "CoStar Class",
    key: "coStarBuildingClass",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "CoStar Market",
    key: "coStarMarket",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "CoStar Sub Market",
    key: "coStarSubmarket",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "CoStar ID",
    key: "coStarPropId",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "RSF",
    key: "rsf",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat: "Number",
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Vacancy(%)",
    key: "vacancy",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Owner / Client",
    key: "companyName",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Zip",
    key: "zip",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  // {
  //   name: 'Details',
  //   key: 'BuildingId',
  //   type: 'Child',
  //   renderComponent: 'renderActionColumn'
  // },
];

export const industrialBuildingOwnerColumns = [
  // {
  //   name: '#',
  //   key: 'serial',
  //   type: 'Child',
  //   isRequired: true,
  //   isVisible: true,

  // },
  {
    name: "Company Name",
    key: "companyName",
    type: "Child",
    isRequired: true,
    isVisible: true,
    renderComponent: "renderActionColumn",
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Owner Type",
    key: "companyRole",
    idField: "companyRoleId",
    type: "Child",
    isRequired: true,
    isVisible: true,
    minTag: 1,
    tagCount: true,
    editConfig: {
      type: "DropDown",
      isEditField: false,
      isAddField: false,
      multiple: true,
      delimiter: "|",
    },
  },

  {
    name: "Address",
    key: "address",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Market",
    key: "market",
    isRequired: true,
    isVisible: true,
    minTag: 1,
    editConfig: {
      type: "DropDown",
      isEditField: false,
      isAddField: false,
      multiple: true,
      delimiter: "|",
    },
  },
  {
    name: "Sub Market",
    key: "subMarket",
    isRequired: true,
    isVisible: true,
    minTag: 1,
    editConfig: {
      type: "DropDown",
      isEditField: true,
      isAddField: true,
      multiple: true,
      delimiter: "|",
    },
  },

  {
    name: "Product Type",
    key: "productType",
    idField: "productTypeId",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "DropDown",
      isEditField: false,
      isAddField: false,
      multiple: true,
      delimiter: "|",
    },
  },
  {
    name: "Industry",
    key: "industryName",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "DropDown",
      isEditField: false,
      isAddField: false,
    },
  },
  // {
  //   name: 'IndustrySelector',
  //   key: 'industrySelector',
  //   type: 'Child',
  //   isRequired: true,
  //   isVisible: true,
  //   editConfig: {
  //     type: 'TextField',
  //     isEditField: false,
  //     isAddField: false,
  //   }
  // },
  // {
  //   name: 'Details',
  //   key: 'CompanyId',
  //   type: 'Child',
  //   renderComponent: 'renderActionColumn'
  // },
];

export const industrialSalesCompsColumns = [
  {
    name: "Property Name",
    key: "buildingName",
    type: "Child",
    isRequired: true,
    isVisible: true,
    renderComponent: "renderActionColumn",
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "RSF",
    key: "rsf",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "Number",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Market",
    key: "market",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Sub Market",
    key: "subMarket",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Buyer",
    key: "buyer",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Seller",
    key: "seller",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Sales Status",
    key: "salesStatus",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Cap Rate",
    key: "capRate",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat: "Decimal",
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Price(RSF)",
    key: "salesPsf",
    type: "Child",
    isRequired: true,
    isVisible: true,
    minTag: 1,
    dataFormat: "MoneyNumber",
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Comp Type",
    key: "compType",
    type: "Child",
    isRequired: true,
    isVisible: true,
    minTag: 1,
    editConfig: {
      type: "DropDown",
      isEditField: false,
      isAddField: false,
      multiple: true,
      delimiter: "|",
    },
  },
];

export const userMarketMappingColumnData = [
  {
    name: "User Email",
    key: "userEmail",
    type: "Parent",
    fieldId: "employeeId",
    isRequired: true,
    isVisible: true,
    editSortOrder: 2,
    editConfig: {
      type: "DropDown",
      sourceKey: "employeeMasterData",
      updateKey: "employeeId",
      sourceOption: "email",
      title: "Employee Name",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Market",
    key: "market",
    minTag: 2,
    viewTagData: "viewTagData",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "DropDown",
      sourceKey: "marketMasterData",
      updateKey: "marketId",
      sourceOption: "market",
      title: "Market",
      isEditField: true,
      isAddField: true,
      multiple: true,
      delimiter: "|",
    },
  },
  {
    name: "Action",
    minWidth: 80,
    key: "marketAccessPermissionId",
    renderComponent: "renderActionColumn",
  },
];


export const propertyOwnerObjectColumn = [
  {
    name: "Property",
    key: "buildingName",
    type: "Parent",
    fieldId: "buildingId",
    isRequired: true,
    isVisible: true,
    editSortOrder: 2,
    // editConfig: {
    //   type: "DropDown",
    //   sourceKey: "employeeMasterData",
    //   updateKey: "employeeId",
    //   sourceOption: "email",
    //   title: "Employee Name",
    //   isEditField: true,
    //   isAddField: true,
    // },
  },
  {
    name: "Status",
    key: "propertyOwnershipStatus",   
    fieldId: "propertyOwnershipStatusId",
    viewTagData: "viewTagData",
    isRequired: true,
    isVisible: true,    
    editConfig: {
      type: "DropDown",
      sourceKey: "propertyOwnershipStatusData",
      updateKey: "propertyOwnershipStatusId",
      sourceOption: "propertyOwnershipStatus",
      title: "Employee Name",
      isEditField: true,
      isAddField: true,
    },
  },  
  {
    name: "Company",
    key: "companyName",   
    fieldId: "companyId",
    viewTagData: "viewTagData",
    isRequired: true,
    isVisible: true,    
    editConfig: {
      type: "DropDown",
      sourceKey: "companyMasterData",
      updateKey: "companyId",
      sourceOption: "companyName",
      title: "Employee Name",
      isEditField: true,
      isAddField: true,
    },
  },  
  {
    name: "Ownership Percent",
    key: "ownershipPercent",
    type: "Child",
    isRequired: false,
    isVisible: true,	
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Primary Owner",
    key: "primaryOwner",
    type: "Child",
    dataFormat: "Boolean",
    isRequired: true,
    isVisible: true,    
    editConfig: {
      type: "CheckBox",
      isEditField: true,
      isAddField: true,
    },
  }, 
  {
    name: "Note",
    key: "notes",
    type: "Child",
    isRequired: false,
    isVisible: true,
    dataFormat: "textarea",    
    editConfig: {
      type: "TextArea",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Action",
    minWidth: 80,
    key: "propertyOwnerId",
    renderComponent: "renderActionColumn",
  },
];

export const marketMappingColumnData = [
  {
    name: "Market",
    key: "market",
    minTag: 2,
    viewTagData: "viewTagData",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "DropDown",
      sourceKey: "marketMasterData",
      updateKey: "marketId",
      sourceOption: "market",
      title: "Market",
      isEditField: true,
      isAddField: true,
      multiple: true,
      delimiter: "|",
    },
  },
  {
    name: "Action",
    minWidth: 80,
    key: "marketAccessPermissionId",
    renderComponent: "renderActionColumn",
  },
];

export const leaseCompConfig = {
  collapsible: true,
  // showCollapsible: true,
  ignoreLength: true,
  groupBy: "property",
  startFromBegining: true,
  showPager: false,
  orderBy: "buildingId",
  showEqualSpace: true,
  addExtraSpace: { key: "buildingId", whenGreaterThan: 0 },
};

export const leaseCompColumns = [
  // {
  //   name: '#',
  //   key: 'serial',
  //   type: 'Child',
  //   isRequired: true,
  //   isVisible: true,

  // },
  {
    name: "",
    key: "buildingId",
    type: "Child",
    isRequired: true,
    isVisible: true,
    // renderComponent: 'renderActionColumn',
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Property",
    key: "property",
    type: "Child",
    isRequired: true,
    isVisible: true,
    renderComponent: "renderActionColumn",
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Market",
    key: "streamMarket",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Sub Market",
    key: "streamSubmarket",
    isRequired: true,
    isVisible: true,
    minTag: 1,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Company Name",
    key: "tenantName",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "SF Leased",
    key: "sfLeased",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Term (Months)",
    key: "term(months)",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Close Date",
    key: "closeDate",
    isRequired: true,
    isVisible: true,
    dataFormat: "Date",
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Expiration Date",
    key: "leaseExpirationDate",
    isRequired: true,
    isVisible: true,
    dataFormat: "Date",
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
];

export const otherCompanyAddressColumns = [
  {
    name: "Street Address",
    key: "add1",
    type: "Parent",
    isRequired: true,
    isVisible: true,
    minWidth: 100,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "City",
    key: "city",
    // type: 'Parent',
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Zip",
    key: "zip",
    // type: 'Parent',
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "State",
    key: "state",
    // type: 'Parent',
    minWidth: 80,
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Action",
    minWidth: 80,
    key: "companyaddressmappingId",
    renderComponent: "renderActionColumn",
  },
];

export const otherCompanyContactColumns = [
  {
    name: "Phone",
    key: "phone1",
    type: "Parent",
    isRequired: true,
    isVisible: true,
    outerWidth: 80,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Contact Person",
    key: "contactPerson",
    // type: 'Parent',
    isRequired: true,
    isVisible: true,
    innerWidth: 80,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Action",
    minWidth: 80,
    key: "companyContactmappingId",
    renderComponent: "renderActionColumn",
  },
];

const {
  Text,
  FormatSF,
  NumberFormat,
  DateFormat,
  CurrencyDecimalPSF,
  BooleanFormat,
} = DATA_FORMAT;

export const AssetTableColumns = [
  {
    Header: () => <span>Property Name</span>,
    accessor: "propertyName",
    filterName: "propertyName",
    isHideOnSortMenu: true,
    width: 180,
    ...(!isMobile && { sticky: "left" }),
    avoidSorting: true,
    Cell: (row) => <span>{getFormattedCellData(row.value, Text)}</span>,
    Footer: "",
  },
  {
    Header: () => <span>Property RSF</span>,
    accessor: "buildingRSF",
    filterName: "buildingRSF",
    isHideOnSortMenu: true,
    width: 100,
    ...(!isMobile && { sticky: "left" }),
    headerClassName: "headerRightAlign",
    className: "valueRightAlign",
    avoidSorting: true,
    Aggregated: (info) => {
      const filterbyByProperty = info.data.filter(
        (x) => x.propertyName + "" === info.row.values.propertyName
      );
      return filterbyByProperty && filterbyByProperty.length
        ? getFormattedCellData(filterbyByProperty[0].buildingRSF, FormatSF)
        : "-";
    },
    // Cell: row => <span>{getFormattedCellData(row.value, FormatSF)}</span>,
    Cell: (row) => "",
    Footer: "",
  },
  {
    Header: () => <span>Class</span>,
    accessor: "class",
    filterName: "class",
    isHideOnSortMenu: true,
    ...(!isMobile && { sticky: "left" }),
    headerClassName: "headerCenterAlign",
    className: "valueCenterAlign",
    width: 80,
    avoidSorting: true,
    Aggregated: (info) => {
      const filterbyByProperty = info.data.filter(
        (x) => x.propertyName + "" === info.row.values.propertyName
      );
      return filterbyByProperty && filterbyByProperty.length
        ? getFormattedCellData(filterbyByProperty[0].class, Text)
        : "-";
    },
    Cell: (row) => "",
    Footer: "",
  },
  {
    Header: () => <span>Comp Type</span>,
    accessor: "compTypeDisplayValue",
    filterName: "Comp Type",
    ...(!isMobile && { sticky: "left" }),
    headerClassName: "headerCenterAlign",
    className: "valueCenterAlign",
    width: 140,
    avoidSorting: true,
    Aggregated: () => "",
    Cell: (row) => <span>{getFormattedCellData(row.value, Text)}</span>,
    Footer: "",
  },
  {
    Header: () => (
      <span>
        {IsSectionVisible(
          SECTIONS.Spec_Suite,
          SECTIONS.Spec_Suite_Add_Edit,
          SECTIONS.ADD_EDIT
        )
          ? "Actions"
          : null}
      </span>
    ),
    accessor: "actionBlock",
    filterName: "actionBlock",
    width: 100,
    avoidSorting: true,
    ...(!isMobile && { sticky: "left" }),
    headerClassName: "headerCenterAlign",
    className: "valueCenterAlign",
    isShowInfoAfterAction: true,
    Aggregated: () => "",
    Cell: (info) => (
      <>
        {(!info.row.original.isNewValue_RelocationReason ||
          !info.row.original.isNewValue_RelocationReason ||
          !info.row.original.isNewValue_PercentageOfficevsOpen ||
          !info.row.original.isNewValue_RentType) && (
          <DarkTooltip title="The row contains one or more fields with older data, please click edit to update the data to new.">
            <span className="info-tooltip">
              <IoMdInformationCircleOutline size="1rem" />
            </span>
          </DarkTooltip>
        )}
      </>
    ),
    canGroupBy: false,
  },
  {
    Header: () => <span>COST PSF</span>,
    accessor: "costPsf",
    filterName: "COST PSF",
    width: 120,
    headerClassName: "headerRightAlign",
    className: "valueRightAlign",
    Aggregated: () => "",
    Cell: (row) => (
      <span>{getFormattedCellData(row.value, CurrencyDecimalPSF)}</span>
    ),
    canGroupBy: false,
  },
  {
    Header: () => <span>Construction Months</span>,
    accessor: "constructionMonths",
    filterName: "Construction Months",
    width: 130,
    headerClassName: "headerRightAlign",
    Aggregated: () => "",
    className: "valueRightAlign",
    Cell: (row) => <span>{getFormattedCellData(row.value, Text)}</span>,
    canGroupBy: false,
  },
  {
    Header: () => <span>Downtime</span>,
    accessor: "completionToLCD_Value",
    filterName: "Downtime",
    width: 160,
    headerClassName: "headerRightAlign",
    Aggregated: () => "",
    className: "valueRightAlign",
    Cell: (row) => <span>{getFormattedCellData(row.value, Text)}</span>,
    canGroupBy: false,
  },
  {
    Header: () => <span>Downtime Value</span>,
    accessor: "downtimeValue",
    filterName: "Downtime Value",
    width: 150,
    headerClassName: "headerRightAlign",
    Aggregated: () => "",
    className: "valueRightAlign",
    Cell: (row) => <span>{getFormattedCellData(row.value, NumberFormat)}</span>,
    canGroupBy: false,
  },
  {
    Header: () => <span>Months Vacant Prior</span>,
    accessor: "monthsVacantPrior",
    filterName: "Months Vacant Prior",
    minWidth: 130,
    headerClassName: "headerRightAlign",
    Aggregated: () => "",
    className: "valueRightAlign",
    Cell: (row) => <span>{getFormattedCellData(row.value, Text)}</span>,
    canGroupBy: false,
  },
  {
    Header: () => <span>Elevator ID(y/n)</span>,
    accessor: "elevatorLobbyExposurer",
    filterName: "Elevator ID(y/n)",
    minWidth: 110,
    headerClassName: "headerCenterAlign",
    Aggregated: () => "",
    className: "valueCenterAlign",
    Cell: (row) => (
      <span>{getFormattedCellData(row.value, BooleanFormat, true)}</span>
    ),
    canGroupBy: false,
  },
  {
    Header: () => <span>Construction Type</span>,
    accessor: "constructionType",
    filterName: "Construction Type",
    minWidth: 190,
    Aggregated: () => "",
    Cell: (row) => <span>{getFormattedCellData(row.value, Text)}</span>,
    canGroupBy: false,
  },
  {
    Header: () => <span>Prior Premises Condition</span>,
    accessor: "priorPremisesCondition",
    filterName: "Prior Premises Condition",
    Aggregated: () => "",
    minWidth: 200,
    Cell: (row) => <span>{getFormattedCellData(row.value, Text)}</span>,
    canGroupBy: false,
  },
  {
    Header: () => <span>Relocation Reason</span>,
    accessor: "relocationReasonDisplayValue",
    filterName: "Relocation Reason",
    minWidth: 180,
    Aggregated: () => "",
    Cell: (info) => (
      <>
        <span>{getFormattedCellData(info.value, Text)}</span>
        {!info.row.original.isNewValue_RelocationReason && (
          <DarkTooltip title="The value displayed is older, please click edit to update the value to new.">
            <span className="info-tooltip">
              <IoMdInformationCircleOutline size="1rem" />
            </span>
          </DarkTooltip>
        )}
      </>
    ),
    canGroupBy: false,
  },
  {
    Header: () => <span>Market</span>,
    accessor: "marketName",
    filterName: "Market",
    minWidth: 110,
    Aggregated: () => "",
    Cell: (row) => <span>{getFormattedCellData(row.value, Text)}</span>,
    canGroupBy: false,
  },
  {
    Header: () => <span>SubMarket</span>,
    accessor: "subMarket",
    filterName: "SubMarket",
    minWidth: 110,
    Aggregated: () => "",
    Cell: (row) => <span>{getFormattedCellData(row.value, Text)}</span>,
    canGroupBy: false,
  },
  {
    Header: () => <span>PreWired</span>,
    accessor: "preWired",
    filterName: "PreWired",
    minWidth: 110,
    Aggregated: () => "",
    Cell: (row) => (
      <span>{getFormattedCellData(row.value, BooleanFormat, true)}</span>
    ),
    canGroupBy: false,
  },
  {
    Header: () => <span>Furnished</span>,
    accessor: "furnished",
    filterName: "Furnished",
    minWidth: 110,
    Aggregated: () => "",
    Cell: (row) => (
      <span>{getFormattedCellData(row.value, BooleanFormat, true)}</span>
    ),
    canGroupBy: false,
  },
  {
    Header: () => <span>Layout Configuration (%)</span>,
    accessor: "percentageOfficevsOpenDisplayValue",
    filterName: "Layout Configuration",
    minWidth: 110,
    Aggregated: () => "",
    Cell: (info) => (
      <>
        <span>{getFormattedCellData(info.value, Text)}</span>
        {!info.row.original.isNewValue_PercentageOfficevsOpen && (
          <DarkTooltip title="The value displayed is older, please click edit to update the value to new.">
            <span className="info-tooltip">
              <IoMdInformationCircleOutline size="1rem" />
            </span>
          </DarkTooltip>
        )}
      </>
    ),
    canGroupBy: false,
  },
  {
    Header: () => <span>Rapid Utilized?</span>,
    accessor: "rapidUtilized",
    filterName: "Rapid Utilized?",
    minWidth: 110,
    Aggregated: () => "",
    Cell: (row) => (
      <span>{getFormattedCellData(row.value, BooleanFormat, true)}</span>
    ),
    canGroupBy: false,
  },
  {
    Header: () => <span>LED (Lease Execution Date)</span>,
    accessor: "led",
    filterName: "LED",
    isHideOnSortMenu: true,
    Aggregated: () => "",
    width: 130,
    avoidSorting: true,
    Cell: (row) => (
      <span>{getFormattedCellData(row.value, DateFormat, true)}</span>
    ),
    Footer: "",
  },
  {
    Header: () => <span>LCD (Lease Commencement Date)</span>,
    accessor: "lcd",
    filterName: "LCD",
    isHideOnSortMenu: true,
    Aggregated: () => "",
    width: 130,
    avoidSorting: true,
    Cell: (row) => (
      <span>{getFormattedCellData(row.value, DateFormat, true)}</span>
    ),
    Footer: "",
  },
  {
    Header: () => <span>Tenant Industry</span>,
    accessor: "type",
    filterName: "Tenant Industry",
    minWidth: 130,
    headerClassName: "headerCenterAlign",
    Aggregated: () => "",
    className: "valueCenterAlign",
    avoidSorting: true,
    Cell: (row) => <span>{getFormattedCellData(row.value, Text)}</span>,
    canGroupBy: false,
  },
  {
    Header: () => <span>Tenant RSF</span>,
    accessor: "tenantRSF",
    filterName: "Tenant RSF",
    minWidth: 130,
    avoidSorting: true,
    Aggregated: () => "",
    headerClassName: "headerRightAlign",
    className: "valueRightAlign",
    Cell: (row) => <span>{getFormattedCellData(row.value, FormatSF)}</span>,

    canGroupBy: false,
  },
  {
    Header: () => <span>Term (Months)</span>,
    accessor: "termsInMonth",
    filterName: "Term (Months)",
    headerClassName: "headerRightAlign",
    className: "valueRightAlign",
    minWidth: 80,
    Aggregated: () => "",
    Cell: (row) => (
      <span>
        {row.value ? getFormattedCellData(row.value, NumberFormat) : "-"}
      </span>
    ),
    Footer: "",
    canGroupBy: false,
  },
  {
    Header: () => <span>Starting Rate (Annual) $</span>,
    accessor: "startingRate",
    filterName: "Starting Rate (Annual)",
    minWidth: 130,
    headerClassName: "headerRightAlign",
    className: "valueRightAlign",
    Aggregated: () => "",
    Cell: (row) => (
      <span>{getFormattedCellData(row.value, CurrencyDecimalPSF)}</span>
    ),
    canGroupBy: false,
  },
  {
    Header: () => <span>Quoted Rate ($)</span>,
    accessor: "quotedRate",
    filterName: "QUOTED RATE",
    minWidth: 80,
    headerClassName: "headerRightAlign",
    className: "valueRightAlign",
    Aggregated: () => "",
    Cell: (row) => (
      <span>{getFormattedCellData(row.value, CurrencyDecimalPSF)}</span>
    ),
    canGroupBy: false,
  },
  {
    Header: () => <span>Net Rent</span>,
    accessor: "nnnPerFS",
    filterName: "Rent Type",
    minWidth: 110,
    headerClassName: "headerCenterAlign",
    className: "valueCenterAlign",
    Aggregated: () => "",
    Cell: (info) => (
      <>
        <span>{getFormattedCellData(info.value, Text)}</span>
        {!info.row.original.isNewValue_RentType && (
          <DarkTooltip title="The value displayed is older, please click edit to update the value to new.">
            <span className="info-tooltip">
              <IoMdInformationCircleOutline size="1rem" />
            </span>
          </DarkTooltip>
        )}
      </>
    ),
    canGroupBy: false,
  },
  {
    Header: () => <span>Annual Rent Escalation</span>,
    accessor: "annualEscalation",
    filterName: "Annual Escalation",
    minWidth: 130,
    headerClassName: "headerRightAlign",
    className: "valueRightAlign",
    Aggregated: () => "",
    avoidSorting: true,
    Cell: (row) => <span>{getFormattedCellData(row.value, NumberFormat)}</span>,

    canGroupBy: false,
  },
  {
    Header: () => <span>Annual Rent Escalation Type</span>,
    accessor: "annualEscalationType",
    filterName: "Annual Escalation Type",
    minWidth: 130,
    headerClassName: "headerCenterAlign",
    className: "valueCenterAlign",
    Aggregated: () => "",
    avoidSorting: true,
    Cell: (row) => <span>{getFormattedCellData(row.value, Text)}</span>,

    canGroupBy: false,
  },
  {
    Header: () => <span>Free Rent (Months)</span>,
    accessor: "freeRentInMonths",
    filterName: "Free Rent (Months)",
    minWidth: 100,
    headerClassName: "headerRightAlign",
    className: "valueRightAlign",
    Aggregated: () => "",
    Cell: (row) => <span>{getFormattedCellData(row.value, NumberFormat)}</span>,
    canGroupBy: false,
  },
  {
    Header: () => <span>Additional TI ($)</span>,
    accessor: "additionalTI",
    filterName: "Additional TI",
    minWidth: 100,
    headerClassName: "headerRightAlign",
    className: "valueRightAlign",
    Aggregated: () => "",
    Cell: (row) => (
      <span>{getFormattedCellData(row.value, CurrencyDecimalPSF, true)}</span>
    ),
    canGroupBy: false,
  },
];

export const marketAbsorptionReportColumns = [
  // {
  //   name: "Property",
  //   key: "buildingName",
  //   type: "Child",
  //   isRequired: true,
  //   isVisible: true,
  // },
  {
    name: "Month Recorded",
    key: "monthRecorded",
    type: "Child",
    dataFormat: "ShortDate",
    isRequired: true,
    isVisible: true,
    //renderComponent: "renderActionColumnReports",
    //editConfig: {
    //	type: "Date",
    //	isEditField: true,
    //	isAddField: true,
    //},
  },
  // {
  //   name: "Delivered",
  //   key: "delivered",
  //   type: "Child",
  //   dataFormat: "Boolean",
  //   isRequired: true,
  //   isVisible: true,    
  //   editConfig: {
  //     type: "CheckBox",
  //     isEditField: true,
  //     isAddField: true,
  //   },
  // },
  {
    name: "Direct Vacant Available SF",
    key: "vacantSf",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat: "Number",
    headcellClass: "headcell-number-text",
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
   {
  	name: "Direct Vacant SF",
  	key: "directVacantSpace",
  	type: "Child",
  	isRequired: false,
  	isVisible: true,
  	dataFormat: "Number",
  	headcellClass: "headcell-number-text",
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },  
  {
    name: "Sublet Vacant Space SF",
    key: "subletVacantSpace",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat: "Number",
    headcellClass: "headcell-number-text",
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Direct Available SF",
    key: "directAvailableSpace",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat: "Number",
    headcellClass: "headcell-number-text",
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  
  {
    name: "Sublease Available SF",
    key: "subletSf",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat: "Number",
    headcellClass: "headcell-number-text",
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  
  {
  	name: "Total Available SF",
  	key: "totalAvailableCalcSf",
  	type: "Child",
  	isRequired: false,
  	isVisible: true,
  	dataFormat: "Number",
  	headcellClass: "headcell-number-text"
   
  },
//  {
//    name: "Floorplate",
//    key: "floorplate",
//    type: "Child",
//    isRequired: true,
//    isVisible: true,
//    dataFormat: "Number",
//    headcellClass: "headcell-number-text",
//  },
  // {
  //   name: "TotalRSF",
  //   key: "totalRsf",
  //   type: "Child",
  //   isRequired: true,
  //   isVisible: true,
  //   dataFormat: "Number",
  //   headcellClass: "headcell-number-text",
  // },
  // {
  //   name: "LeasedRSF",
  //   key: "leasedRsf",
  //   type: "Child",
  //   isRequired: true,
  //   isVisible: true,
  //   dataFormat: "Number",
  //   headcellClass: "headcell-number-text",
  // },
  // {
  //   name: "Absorption",
  //   key: "absorption",
  //   type: "Child",
  //   isRequired: true,
  //   isVisible: true,
  //   dataFormat: "Decimal",
  //   headcellClass: "headcell-number-text",
  // },
  // {
  //   name: "Vacancy",
  //   key: "vacancyPercent",
  //   type: "Child",
  //   isRequired: true,
  //   isVisible: true,
  //   dataFormat: "DecimalPercent",
  //   headcellClass: "headcell-number-text",
  // },
  
  
  {
    name: "Note",
    key: "note",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat: "textarea",    
    editConfig: {
      type: "TextArea",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Action",
    key: "officeId",
    renderComponent: "renderActionColumn",
  },
 
 
  
  //{
  //	name: "Building Status",
  //	key: "buildingStatus",
  //	type: "Child",
  //	isRequired: false,
  //	isVisible: true,
  //	dataFormat: "Text",
  //	headcellClass: "headcell-number-text",
  //},
  //{
  //	name: "Leasing Company Name",
  //	key: "leasingCompanyName",
  //	type: "Child",
  //	isRequired: false,
  //	isVisible: true,
  //	dataFormat: "Text",
  //	headcellClass: "headcell-number-text",
  //},
  //{
  //	name: "Leasing Company Contact",
  //	key: "leasingCompanyContact",
  //	type: "Child",
  //	isRequired: false,
  //	isVisible: true,
  //	dataFormat: "Text",
  //	headcellClass: "headcell-number-text",
  //},
  //{
  //	name: "True Owner Name",
  //	key: "trueOwnerName",
  //	type: "Child",
  //	isRequired: false,
  //	isVisible: true,
  //	dataFormat: "Text",
  //	headcellClass: "headcell-number-text",
  //},
  //{
  //	name: "True Owner Contact",
  //	key: "trueOwnerContact",
  //	type: "Child",
  //	isRequired: false,
  //	isVisible: true,
  //	dataFormat: "Text",
  //	headcellClass: "headcell-number-text",
  //},
  //{
  //	name: "For Sale Status",
  //	key: "forSaleStatus",
  //	type: "Child",
  //	isRequired: false,
  //	isVisible: true,
  //	dataFormat: "Text",
  //	headcellClass: "headcell-number-text",
  //},
];

export const MajorTenantMoveInsReportColumns = [ 
  {
    name: "Month Recorded",
    key: "monthRecorded",
    type: "Child",
    dataFormat: "Date",
    isRequired: true,
    isVisible: true,
    
  },
  {
    name: "Tenant Company",
    key: "tenantMoveInCompanyName",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat: "Text",
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Type (Direct, Sublease)",
    key: "tenenatMoveInType",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat: "Text",
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "SF Leased",
    key: "tenantMoveInSf",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat: "Number",
    headcellClass: "headcell-number-text",
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Note",
    key: "tenantMoveInNotes",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat: "textarea",    
    editConfig: {
      type: "TextArea",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Action",
    key: "officeId",
    renderComponent: "renderActionColumn",
  }  
];


export const MajorTenantMoveOutsReportColumns = [
  {
    name: "Month Recorded",
    key: "monthRecorded",
    type: "Child",
    dataFormat: "Date",
    isRequired: true,
    isVisible: true
    
  },
  {
    name: "Tenant Company",
    key: "tenantMoveOutCompanyName",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat: "Text",
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "SF Leased",
    key: "tenantMoveOutSf",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat: "Number",
    headcellClass: "headcell-number-text",
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Type (Direct, Sublease)",
    key: "tenenatMoveOutType",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat: "Text",
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },

  {
    name: "Note",
    key: "tenantMoveOutNotes",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat: "textarea",    
    editConfig: {
      type: "TextArea",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Action",
    key: "officeId",
    renderComponent: "renderActionColumn",
  }  
];

export const indMarketAbsorptionReportColumns = [
  {
    name: "Property",
    key: "buildingName",
    type: "Child",
    isRequired: true,
    isVisible: true,
  },
  {
    name: "Month Recorded",
    key: "monthRecorded",
    type: "Child",
    dataFormat: "Date",
    isRequired: true,
    isVisible: true,
    //renderComponent: "renderActionColumnReports",
    //editConfig: {
    //	type: "Date",
    //	isEditField: true,
    //	isAddField: true,
    //},
  },
//  {
//    name: "Floorplate",
//    key: "floorplate",
//    type: "Child",
//    isRequired: true,
//    isVisible: true,
//    dataFormat: "Number",
//    headcellClass: "headcell-number-text",
//  },
  {
    name: "TotalRSF",
    key: "totalRsf",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat: "Number",
    headcellClass: "headcell-number-text",
  },
  {
    name: "LeasedRSF",
    key: "leasedRsf",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat: "Number",
    headcellClass: "headcell-number-text",
  },
  {
    name: "Absorption",
    key: "absorption",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat: "Decimal",
    headcellClass: "headcell-number-text",
  },
  {
    name: "Vacancy",
    key: "vacancyPercent",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat: "DecimalPercent",
    headcellClass: "headcell-number-text",
  },
  {
    name: "Total Vacant Avail Relet Space (SF)",
    key: "vacantSf",
    type: "Child",
    isRequired: true,
    isVisible: true,
	dataFormat: "Number",
	headcellClass: "headcell-number-text",
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Total Sublet Space (SF)",
    key: "subletSf",
    type: "Child",
    isRequired: true,
    isVisible: true,
	dataFormat: "Number",
	headcellClass: "headcell-number-text",
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Action",
    key: "industrialId",
    renderComponent: "renderActionColumn",
  },
  //{
  //	name: "Direct Available Space",
  //	key: "directAvailableSpace",
  //	type: "Child",
  //	isRequired: false,
  //	isVisible: true,
  //	dataFormat: "Number",
  //	headcellClass: "headcell-number-text",
  //},
  //{
  //	name: "Direct Vacant Space",
  //	key: "directVacantSpace",
  //	type: "Child",
  //	isRequired: false,
  //	isVisible: true,
  //	dataFormat: "Number",
  //	headcellClass: "headcell-number-text",
  //},
  //{
  //	name: "Total Available Space",
  //	key: "totalAvailableSpace",
  //	type: "Child",
  //	isRequired: false,
  //	isVisible: true,
  //	dataFormat: "Number",
  //	headcellClass: "headcell-number-text",
  //},
  //{
  //	name: "Building Status",
  //	key: "buildingStatus",
  //	type: "Child",
  //	isRequired: false,
  //	isVisible: true,
  //	dataFormat: "Text",
  //	headcellClass: "headcell-number-text",
  //},
  //{
  //	name: "Leasing Company Name",
  //	key: "leasingCompanyName",
  //	type: "Child",
  //	isRequired: false,
  //	isVisible: true,
  //	dataFormat: "Text",
  //	headcellClass: "headcell-number-text",
  //},
  //{
  //	name: "Leasing Company Contact",
  //	key: "leasingCompanyContact",
  //	type: "Child",
  //	isRequired: false,
  //	isVisible: true,
  //	dataFormat: "Text",
  //	headcellClass: "headcell-number-text",
  //},
  //{
  //	name: "True Owner Name",
  //	key: "trueOwnerName",
  //	type: "Child",
  //	isRequired: false,
  //	isVisible: true,
  //	dataFormat: "Text",
  //	headcellClass: "headcell-number-text",
  //},
  //{
  //	name: "True Owner Contact",
  //	key: "trueOwnerContact",
  //	type: "Child",
  //	isRequired: false,
  //	isVisible: true,
  //	dataFormat: "Text",
  //	headcellClass: "headcell-number-text",
  //},
  //{
  //	name: "For Sale Status",
  //	key: "forSaleStatus",
  //	type: "Child",
  //	isRequired: false,
  //	isVisible: true,
  //	dataFormat: "Text",
  //	headcellClass: "headcell-number-text",
  //},
];


export const chartPocColumns = [
  
  {
    name: "Building Class",
    key: "buildingClass",
    type: "Child",
    isRequired: true,
    isVisible: true,
    //isEditable:true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Year Recorded",
    key: "yearRecorded",
    type: "Child",
    isRequired: true,
    isVisible: true,
    dataFormat:'Year',
   // isEditable:true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Yoy change",
    key: "yoyChange",
    type: "Child",
    isRequired: true,
    isVisible: true,
    //isEditable:true,
    dataFormat:'Decimal',    
    headcellClass: "headcell-number-text",
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Vacant SF",
    key: "vacantSf",
    type: "Child",
    isRequired: true,
    isVisible: true,
    isEditable:true,
    dataFormat: "Decimal",    
    headcellClass: "headcell-number-text",
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  
  {
    name: "Action",
    key: "rptVacantsfImportId",
    renderComponent: "renderActionColumn",
  },
];


export const propertyStatsColumnGrouping = [
  
  {
    name: "",
    key: "",
    colSpan: 1,
  },
  {
    name: "TRANSACTION-BASED ABS",
    key: "vacantSf",
    colSpan: 1,
  },
  {
    name: "OCCUPANCY-BASED ABS",
    key: "directVacantSpace",
    colSpan: 1,
  }, 
  {
    name: "",
    key: "",
    colSpan: 6,
  }
];